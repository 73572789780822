import React, {useState,useEffect} from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { Link } from 'react-router-dom';
import AuthUser from '../../../helpers/AuthUser';
import Loading from '../../../Components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faPencil,
	faCircleXmark,
	faClock,
	faCalendar,
	faUser,
	faUsers,
	faPaperPlane,
	faEllipsisVertical
} 
from '@fortawesome/free-solid-svg-icons';

function DashboardReport(){

	const {http}=AuthUser();
	const [isActive, setActive] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const [value, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
	const [dashboardData, setDashboardData] = useState({
		active_users_count: 0,
		active_admin_users_count: 0,
		failed_sms_count: 0,
		failed_appointments_count:0,
		total_paid_appointments: 0,
		balance_paid_on_site:0,
		paid_sessions:0,
		student_history_count: 0,
		client_history_count: 0,
		ClinincPrepaid:0,
		ClinincPostpaid:0,
	  });
	 
	const fetchDataByDate = async (SelectedDate) => {
		setLoading(true)
		try {
		const response = await http.get('/dashboard/reports-summary', {
			params: {
			  selected_date: SelectedDate
			},
		});
		setLoading(false)
		setDashboardData(response.data);
		} catch (error) {
		  console.error('Error fetching dashboard data:', error);
		}
	};

	const handleFilterChange = (SelectedDate) => {
		setValue(SelectedDate);
		fetchDataByDate(SelectedDate.startDate);
	};

	useEffect(() => {
		const currentDate = new Date().toISOString().slice(0, 10);
		setValue(currentDate);
		fetchDataByDate(currentDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

	const toggleClass = (dropdownId) => {
	    if (isActive === dropdownId) {
	      setActive(null);
	    } else {
	      setActive(dropdownId);
	    }
	};

	return(
		<>
		 { isLoading && <Loading /> }
			<div className="report-summary">
				<div className="row report-head">
					<h2 className="col">Reports Summary</h2>
					<div className="col col-6 align-right">
						<div className="calender-filter">
							<Datepicker 
								value={value} 
								onChange={handleFilterChange} 
								displayFormat={"DD/MM/YYYY"}
		                        placeholder={"Date"} 
		                        useRange={false} 
		                        asSingle={true} 
							/> 
						</div>
					</div>
				</div>

				<div className="flex flex-row space-x-6">
						
					<div className="basis-1/2 white-box">
						<div className="row row-top relative">
							<div className="col">
								<div className="icon-wrap">
									<FontAwesomeIcon icon={faClock} />
									<h6>Clinic Day View Earnings</h6>
								</div>
							</div>
							<div className="col">
								<div className="colon">
									<div id="toggle1" className={`${isActive === 1 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(1)}>
										<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
										<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
									</div>
									<ul className={`${isActive === 1 ? 'active': ''} submenu`}>
										<li><Link href="">View Full Report</Link></li>
										<li><Link href="">Quick Export via CSV/PDF</Link></li>
									</ul>
									
								</div>
							</div>
						</div>
						<div className="row row-bottom r-d-100">
							<div className="col">
								<div className="text-wrap">
									<div className="text">
										<h3>${parseFloat(dashboardData.ClinincPrepaid).toFixed(2)}</h3>
										<h4 className="text-greycolor8">Pre-paid</h4>
									</div>
									<div className="text">
										<h3>${parseFloat(dashboardData.ClinincPostpaid).toFixed(2)}</h3>
										<h4 className="text-greycolor8">Post-paid</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="info">
									{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
									<div className="info-box">
										<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="hidden basis-1/3 white-box">
						<div className="row row-top relative">
							<div className="col">
								<div className="icon-wrap">
									<FontAwesomeIcon icon={faClock} />
									<h6>Month To Date Earnings</h6>
								</div>
							</div>
							<div className="col">
								<div className="colon">
									<div id="toggle1" className={`${isActive === 2 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(2)}>
										<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
										<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
									</div>
									<ul className={`${isActive === 2 ? 'active': ''} submenu`}>
										<li><Link href="">View Full Report</Link></li>
										<li><Link href="">Quick Export via CSV/PDF</Link></li>
									</ul>
									
								</div>
							</div>
						</div>
						<div className="row row-bottom r-d-100">
							<div className="col">
								<div className="text-wrap">
									<div className="text">
										<h3>${parseFloat(dashboardData.Monthprepaid).toFixed(2) }</h3>
										<h4 className="text-greycolor8">Pre-paid</h4>
									</div>
									<div className="text">
										<h3>${parseFloat(dashboardData.Monthpostpaid).toFixed(2)}</h3>
										<h4 className="text-greycolor8">Post-paid</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="info">
									{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
									<div className="info-box">
										<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="basis-1/2 white-box">
						<div className="row row-top relative">
							<div className="col">
								<div className="icon-wrap">
									<FontAwesomeIcon icon={faClock} />
									<h6>Cancellations</h6>
								</div>
							</div>
							<div className="col">
								<div className="colon">
									<div id="toggle1" className={`${isActive === 3 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(3)}>
										<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
										<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
									</div>
									<ul className={`${isActive === 3 ? 'active': ''} submenu`}>
										<li><Link href="">View Full Report</Link></li>
										<li><Link href="">Quick Export via CSV/PDF</Link></li>
									</ul>
									
								</div>
							</div>
						</div>
						<div className="row row-bottom r-d-100">
							<div className="col">
								<div className="flex flex-row space-x-6 text-wrap">
									<div className="text basis-1/2">
										<h3>{dashboardData.cancelledByAcfbCount}</h3>
										<h4 className="text-greycolor8">Cancelled by ACFB</h4>
									</div>
									<div className="text basis-1/2">
										<h3>{dashboardData.cancelledByStudentCount}</h3>
										<h4 className="text-greycolor8">Cancelled by Student</h4>
									</div>
								</div>
								<div className="flex flex-row space-x-6 text-wrap">
									<div className="text basis-1/2">
										<h3>{dashboardData.cancelledByClientCount}</h3>
										<h4 className="text-greycolor8">Cancelled by Client</h4>
									</div>
									<div className="text basis-1/3">
										<h3>{dashboardData.noShowCount}</h3>
										<h4 className="text-greycolor8">No Show</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="info">
									{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
									<div className="info-box">
										<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div className="hidden">
					<div className="col col-3">
						
					</div>
					<div className="col col-9">
						<div className="row row-inr">
							<div className="col col-12">
								<div className="white-box">
									<div className="row row-top relative">
										<div className="col">
											<div className="icon-wrap">
												<FontAwesomeIcon icon={faPencil} />
												<h6>Clinic History Report</h6>
											</div>
										</div>
										<div className="col">
											<div className="colon">
												<div className={`${isActive === 2 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(2)}>
													<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
													<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
												</div>
												<ul className={`${isActive === 2 ? 'active': ''} submenu`}>
													<li><Link href="">View Full Report</Link></li>
													<li><Link href="">Quick Export via CSV/PDF</Link></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="row row-bottom">
										<div className="col">
											<div className="text-wrap">
												<div className="text">
													<h3>20</h3>
													<h4 className="text-greycolor8">Client History</h4>
												</div>
												<div className="text">
													<h3>100</h3>
													<h4 className="text-greycolor8">Student History</h4>
												</div>
											</div>
										</div>
										<div className="col">
											<div className="info">
												{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
												<div className="info-box">
													<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-3">
								<div className="white-box">
									<div className="row row-top relative">
										<div className="col">
											<div className="icon-wrap">
												<div className="icon-payment">
													<span></span>
													<span></span>
													<span></span>
												</div>
												<h6>Payment Summary Report</h6>
											</div>
										</div>
										<div className="col">
											<div className="colon">
												<div className={`${isActive === 3 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(3)}>
													<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
													<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
												</div>
												<ul className={`${isActive === 3 ? 'active': ''} submenu`}>
													<li><Link href="">View Full Report</Link></li>
													<li><Link href="">Quick Export via CSV/PDF</Link></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="row row-bottom">
										<div className="col">
											<div className="text-wrap">
												<div className="text">
													<h3>${dashboardData.paid_sessions}</h3>
													<h4 className="text-greycolor8">Paid Sessions</h4>
												</div>
											</div>
										</div>
										<div className="col">
											<div className="info">
												{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
												<div className="info-box">
													<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-3">
								<div className="white-box">
									<div className="row row-top relative">
										<div className="col">
											<div className="icon-wrap">
												<FontAwesomeIcon icon={faCalendar} />
												<h6>Appointment Error Report</h6>
											</div>
										</div>
										<div className="col">
											<div className="colon">
												<div className={`${isActive === 4 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(4)}>
													<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
													<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
												</div>
												<ul className={`${isActive === 4 ? 'active': ''} submenu`}>
													<li><Link href="">View Full Report</Link></li>
													<li><Link href="">Quick Export via CSV/PDF</Link></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="row row-bottom">
										<div className="col">
											<div className="text-wrap">
												<div className="text">
													<h3>{dashboardData.failed_appointments_count}</h3>
													<h4 className="text-greycolor8">Failed Appointments</h4>
												</div>
											</div>
										</div>
										<div className="col">
											<div className="info">
												{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
												<div className="info-box">
													<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="col col-3">
						<div className="white-box">
							<div className="row row-top relative">
								<div className="col">
									<div className="icon-wrap">
										<FontAwesomeIcon icon={faUser} />
										<h6>Admin User Report</h6>
									</div>
								</div>
								<div className="col">
									<div className="colon">
										<div className={`${isActive === 5 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(5)}>
											<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
											<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
										</div>
										<ul className={`${isActive === 5 ? 'active': ''} submenu`}>
											<li><Link href="">View Full Report</Link></li>
											<li><Link href="">Quick Export via CSV/PDF</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="row row-bottom">
								<div className="col">
									<div className="text-wrap">
										<div className="text">
											<h3>{dashboardData.active_admin_users_count}</h3>
											<h4 className="text-greycolor8">Active Admin Users</h4>
										</div>
									</div>
								</div>
								<div className="col">
									<div className="info">
										{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
										<div className="info-box">
											<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col col-3">
						<div className="white-box">
							<div className="row row-top relative">
								<div className="col">
									<div className="icon-wrap">
										<FontAwesomeIcon icon={faUsers} />
										<h6>Users Report</h6>
									</div>
								</div>
								<div className="col">
									<div className="colon">
										<div className={`${isActive === 6 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(6)}>
											<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
											<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
										</div>
										<ul className={`${isActive === 6 ? 'active': ''} submenu`}>
											<li><Link href="">View Full Report</Link></li>
											<li><Link href="">Quick Export via CSV/PDF</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="row row-bottom">
								<div className="col">
									<div className="text-wrap">
										<div className="text">
											<h3>{dashboardData.active_users_count}</h3>
											<h4 className="text-greycolor8">Active Users</h4>
										</div>
									</div>
								</div>
								<div className="col">
									<div className="info">
										{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
										<div className="info-box">
											<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col col-3">
						<div className="white-box">
							<div className="row row-top relative">
								<div className="col">
									<div className="icon-wrap">									
										<FontAwesomeIcon icon={faPaperPlane} />
										<h6>SMS Failed Report</h6>
									</div>
								</div>
								<div className="col">
									<div className="colon">
										<div className={`${isActive === 7 ? 'active': 'close'} dots cursor-pointer`} onClick={() => toggleClass(7)}>
											<FontAwesomeIcon className="dots-icon" icon={faEllipsisVertical} />
											<FontAwesomeIcon className="close-icon" icon={faCircleXmark} />
										</div>
										<ul className={`${isActive === 7 ? 'active': ''} submenu`}>
											<li><Link href="">View Full Report</Link></li>
											<li><Link href="">Quick Export via CSV/PDF</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="row row-bottom">
								<div className="col">
									<div className="text-wrap">
										<div className="text">
											<h3>{dashboardData.failed_sms_count}</h3>
											<h4 className="text-greycolor8">Failed SMS</h4>
										</div>
									</div>
								</div>
								<div className="col">
									<div className="info">
										{/* <div className="border-rounded info-icon"><FontAwesomeIcon icon={faInfo} /></div> */}
										<div className="info-box">
											<p>SMS’s that failed to fire at 6:00pm the night before. Sent at 7:00pm the night before.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DashboardReport;