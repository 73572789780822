import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className, isSearchable, isDisabled, placeholder, isClearable }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value) : "";
    };

    return (
        <div className={className}>
            <Select
              isDisabled={isDisabled}
              placeholder={placeholder}
              isSearchable={isSearchable}
              isClearable={isClearable}
              value={value !== null ? defaultValue(options, value) : null}
              className="select-inner-border"
              onChange={value => {
                onChange(value)
              }}
              options={options}
            />
        </div>
    )
}