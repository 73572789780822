import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Menu, MenuItem } from 'react-pro-sidebar';

const MobileNavbar = ({ menuData, onMenuItemClick }) => {
  return (
    <Menu className="px-4 main-menu">
      {menuData.map((menuItem) => (
        <MenuItem key={menuItem.name} onClick={() => onMenuItemClick(menuItem.url)}>
          <Link to={menuItem.url}>
            {menuItem.name}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MobileNavbar;
