import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import AuthUser from '../../helpers/AuthUser';
import * as Yup from 'yup';
import Moment from 'moment';
import {
    Checkbox,
    Input,
    Button,
    Typography,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    IconButton,
} from "@material-tailwind/react";
import Datepicker from "react-tailwindcss-datepicker"
import Loading from '../../Components/Loading';

import {getStudentUrl} from "../../helpers/Utils";
import TopNav from '../../Components/TopNav/TopNav';
import StudentTopNav from '../../Components/StudentTopNav';

const topNav = [
    {
        name: "Enter Availability",
        url: getStudentUrl('enter-availability')
    }
]

export default function EnterAvailability(){

    const {http, Services, StudentRecords} = AuthUser();
    const navigate = useNavigate();
    const User = JSON.parse(localStorage.getItem('user'));
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);
    const [services, setServices] = useState([]);
    const [records, SetStudentRecords] = useState([]);
    const [checkedItems, setAllocation] = useState([]);
    const [selectedItems, setselectedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        GetServices()
        GetStudentRecords()
    }, [])
 
    const GetServices = () => {
        Services().then((resp)=>{
            setServices(resp.data.data);
            setIsLoading(false);
        });  
    }

    const GetStudentRecords = () => {
        StudentRecords().then((resp)=>{
            SetStudentRecords(resp.data);
            setIsLoading(false);
        });  
    }

    const GetAllocation = (params) => {
        http.post('/search-allocation',params).then((resp)=>{
           if (resp && resp.data.data) {
               setAllocation(resp.data);
            } else {
              setAllocation('');
              document.getElementById("error").innerText = resp.data.message;
              console.log('resp.data.message',resp.data.message)
          }
        }).catch(err => {
            if (err.response) {
                console.log('Server responded with an error:', err.response.data);
                setAllocation('');
                document.getElementById("error").innerText = err.response.data.message;
            } else {
                console.log('Error:', err.message);
            }
        });
    }
     
    const [selecteddate, setDate] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    
    const handleValueChange = (newValue) => {
        setselectedItems([]);
        setFieldValue('times',[]);
        setDate(newValue);
        setAllocation('')
        setErrorMsg('Please ensure you click on the search button to display the results.');
    }

    const handleSearch = (event) => {
         setselectedItems([]);
         setFieldValue('times',[]);
        let parms = {
            start_date: selecteddate.startDate,
            end_date: selecteddate.endDate,
            available_services: User.massage_type,
            user_id:User.id
          };
          GetAllocation(parms);
    }

    const clearSearch = (event) => {
        setAllocation('')
        setDate('')
    }

    const handleCheckboxChange = (event, itemId) => {

        if(selectedItems.includes(itemId)){
            setselectedItems([]);
            setFieldValue('times',[]);
        }else{
            setselectedItems(itemId);
            if(itemId == 'morning_slots'){
                const morningTimes = checkedItems.morning_slots; 
                setFieldValue('times', morningTimes);
            }else if(itemId == 'afternoon_slots'){
                const AfternoonTimes = checkedItems.afternoon_slots; 
                setFieldValue('times', AfternoonTimes);
            }else if(itemId == 'evening_slots'){
                const EveningTimes = checkedItems.evening_slots; 
                setFieldValue('times', EveningTimes);
            }else{
                setFieldValue('times', []);
            }
        }
      };


    const { handleSubmit,errors, touched, getFieldProps, setFieldValue } = useFormik({
        initialValues: {
            user_id:User.id,
            title: '',
            date:'',
            massage_type: User.massage_type,
            times: [],
        },
        validationSchema: Yup.object({
            times: Yup.array().min(1, 'At least one item is required')
        }),
        onSubmit: (values) => {
            values = { ...values, date: selecteddate.startDate,title:selecteddate.startDate}
             http.post("auth/availability", JSON.stringify(values, null, 2))
            .then(res => {
            let response = res.data;
              http.post("appointment", JSON.stringify(values, null, 2)).then(res => {
                navigate("/student/upcoming-bookings");
              });
            }).catch(e => {
             navigate("/student/upcoming-bookings");
          })
      },
    });
    const { value: TimeSlot } = getFieldProps('times');
    return (
        <>
        { isLoading && <Loading /> }

            <div className="client-nav">
                <TopNav data={topNav} />
            </div>

               <div className="white-box">
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-rows-2 md:grid-rows-1 grid-flow-col gap-4 mt-6 mb-8 w-full">
                            <div className="form-input">
                            {services.map((item, index) => (
                               <> {User.massage_type === item.id ?  ( 
                                <Input
                                    autoComplete="fname"
                                    name="first_name"
                                    variant="static"
                                    fullWidth
                                    id="firstName"
                                    label="Massage Type"
                                    className="border-black placeholder-shown:border-black"
                                    value= {item.title}
                                    onChange={(e) => setFieldValue(item.title)}
                                    />   ) : (' ')} </>
                                ))}  
                                  {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                            </div>
                        
                            <div className="form-input relative datepicker-students mt-4 md:mt-0">
                                <label>Date(s)</label>
                                <Datepicker 
                                    useRange={false} 
                                    asSingle={true} 
                                    showShortcuts={false} 
                                    {...getFieldProps('date')}
                                    showFooter={false}
                                    value={selecteddate} 
                                    onChange={handleValueChange} 
                                    displayFormat={"DD/MM/YYYY"}
                                />
                            </div>
                            <div className="">
                                <Button onClick={handleSearch} className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none text-white capitalize text-sm px-8 py-3">Search</Button>    
                            </div>
                        </div> 

                        <div className="grid grid-rows-2 md:grid-rows-1 grid-flow-col mb-7">
                            <div> 
                                <Typography variant="h4" className="font-normal text-lg md:text-2xl"> Search results: {checkedItems.length !==0 ? Object.keys(checkedItems.data).length : 0} available clinics </Typography>
                            </div>
                            <div className="md:ml-auto">
                                <a onClick={clearSearch} className="text-sm underline underline-offset-2">Clear all search results</a>
                            </div>
                        </div>
                   
                        <div className="table-with-checkbox clinics-table mb-10">
                            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                                <table className="table-simple-custom w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="pl-4 pr-2 checkbox">
                                                <Checkbox />
                                            </th>
                                            <th scope="col">
                                                Massage Type
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Date
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Time
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="ml-1 w-3 h-3" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/></svg></a>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Status
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="ml-1 w-3 h-3" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/></svg></a>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                
                                            </th>
                                            <th scope="col">
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {checkedItems.records >0 && checkedItems.data ? ( <> 
                                        {Object.keys(checkedItems.data).map((option,index) => (
                                        <tr>
                                            <td scope="col" className="pl-4 pr-2 checkbox">
                                           
                                             {checkedItems.data[option]['max_limit'] >= 1 && checkedItems.data[option]['booking_status'] == true ?  ( 
                                                  
                                                  <Checkbox key={index} disabled={true} id={`time_${index}`} {...getFieldProps('times')} name="times" value="Booked"/> 
                                                
                                                  ) : checkedItems.data[option]['max_limit'] >= 1  && checkedItems.data[option]['noBlockrestriction'] == true ?( 
                                                    
                                                    <Checkbox key={index} id={`time_${index}`}   {...getFieldProps('times')}  name={'times'} value={checkedItems.data[option]['allocation_time']}/>
                                                    ) : checkedItems.data[option]['max_limit'] >= 1  && checkedItems.data[option]['noBlockrestriction'] != true ?( 
                                                        <Checkbox
                                                            key={index} 
                                                            id={`time_${index}`}
                                                            {...getFieldProps('times')}  
                                                            onChange={(event) => { handleCheckboxChange(event, checkedItems.data[option]['checkboxName'])}
                                                            }  
                                                            checked={selectedItems.includes(checkedItems.data[option]['checkboxName'])} 
                                                            name="times"
                                                            value={checkedItems.data[option]['allocation_time']}
                                                       />
                                                  ) : ( 
                                                 <Checkbox key={index} disabled={true} id={`time_${index}`} {...getFieldProps('times')} name="times" value="N/A"/>
                                            )} 
                                            </td>

                                            {services.map((item, index) => (
                                             <>
                                              {User.massage_type === item.id ?  ( 
                                                   <td> { item.title}</td>  
                                                ) : (' ')}
                                              </>
                                            ))}
                                           <td>
                                               {(Moment(selecteddate.startDate).format('DD/MM/YYYY'))}
                                            </td>
                                            <td>
                                            {checkedItems.data[option]['allocation_time']}
                                            </td>

                                            <td className="font-normal text-darkgrey1">
                                              {checkedItems.data[option]['Slot']}
                                           </td>
                                    
                                            {checkedItems.data[option]['max_limit'] > 0 && checkedItems.data[option]['booking_status'] == true ?  ( 
                                                   <td>Booked</td>  
                                                ) : checkedItems.data[option]['max_limit'] > 0 ? ( 
                                                    <td>Available</td> ) : ( 
                                                <td>N/A</td>
                                            )}
                                            <td className="view-edit-btn"> </td>
                                        </tr>
                                        ))}</>) : (
                                            <tr>
                                                <td colspan="6" id="error" className="text-center custom-message">{errorMsg ? errorMsg : "Sorry, there are no available appointments on this day."}</td>  
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { checkedItems.records >0 &&
                            <div className="flex justify-end">
                                <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none  text-white capitalize text-sm px-8 py-3" onClick={handleOpen}>Book Selected Times</Button> 
                            </div>
                        }

                        <Dialog className="max-w-screen-sm w-11/12" open={open} handler={handleOpen}>
                            <DialogHeader className="justify-end">
                              <IconButton
                                color="blue-gray"
                                size="sm"
                                variant="text"
                                onClick={handleOpen}
                              >
                                <i className="fas fa-times" />
                              </IconButton>
                            </DialogHeader>
                            <DialogBody className="px-8 py-8">

                                {TimeSlot.length !== 0 ? ( <> 
                                    <Typography variant="h5" className="font-normal text-center mb-10 text-black">
                                        Are you sure you want to book the following clinic(s)?
                                    </Typography>

                                    <div className="hidden md:block">
                                        <div className="flex flex-wrap px-2 md:px-10 mb-6">
                                            <div className="w-6/12">
                                                <Typography variant="small" className="font-normal text-black">
                                                    CLINIC(S)
                                                </Typography>
                                            </div>
                                            <div className="w-6/12 text-right">
                                                <Typography variant="small" className="font-normal text-black">
                                                    DATE(S) & TIME SLOT(S)
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-16">    

                                     {TimeSlot.map((option, index) => (
                                            <div className="flex flex-wrap px-2 md:px-10 mb-5" key={index}>
                                                {services.map((item) => (
                                                    User.massage_type === item.id ? (<>
                                                        <div className="md:hidden">
                                                            <Typography variant="small" className="font-normal text-black">
                                                                CLINIC(S)
                                                            </Typography>
                                                        </div>
                                                        <div className="w-full md:w-6/12 mb-2 font-normal text-darkgrey1" key={item.id}>
                                                        {item.title}
                                                        </div>
                                                    </>) : null
                                                ))}
                                                <div className="md:hidden">
                                                    <Typography variant="small" className="font-normal text-black">
                                                        DATE(S) & TIME SLOT(S)
                                                    </Typography>
                                                </div>
                                                <div className="w-full md:w-6/12 md:text-right font-normal text-darkgrey1">
                                                   {(Moment(selecteddate.startDate).format('DD/MM/YYYY'))} • {option}
                                                </div>
                                            </div>
                                        ))}
                                        
                                    </div>
                                </>) : (
                                    <Typography variant="h5" className="text-center text-black font-normal mb-10">Please select at least one time slot to book appointment.</Typography>  
                                )}                        

                                <div className="flex flex-wrap justify-center gap-5">
                                    <div className="col">
                                        <Button onClick={handleOpen} className="outline-btn-black" variant="outlined">Go Back</Button>
                                    </div>

                                    { TimeSlot.length !== 0  ? ( <>
                                        <div className="col">
                                            <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none text-white capitalize text-sm px-8 py-3" onClick={handleSubmit}>Confirm</Button>
                                        </div>
                                    </>) : ( null )}  
                                </div>

                             </DialogBody>
                          </Dialog>
                    </form>
                </div>


             {TimeSlot.length !== 0 ? ( <> 

                <div className="white-box mt-10">

                        <Typography variant="h4" className="font-normal text-lg md:text-2xl text-left mb-6 text-black">
                            Your Nominated Availability
                        </Typography>

                        <div className="table-with-checkbox clinics-table mb-5">
                            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                                <table className="table-simple-custom w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col">
                                                Massage Type
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Date
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Time
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="ml-1 w-3 h-3" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/></svg></a>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="flex items-center">
                                                    Status
                                                    <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="ml-1 w-3 h-3" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/></svg></a>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                
                                            </th>
                                            <th scope="col">
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                    {TimeSlot.map((option, index) => (
                                        <tr className="tr" key={index}>
                                            {services.map((item, index) => (
                                                User.massage_type === item.id ? (
                                                    <td className="font-normal text-darkgrey1" key={item.id}>
                                                    {item.title}
                                                    </td>
                                                ) : null
                                            ))}
                                            <td className="font-normal text-darkgrey1">
                                               {(Moment(selecteddate.startDate).format('DD/MM/YYYY'))} 
                                            </td>
                                            {option && option.length > 0 ? (
                                                <td className="font-normal text-darkgrey1">
                                                    {option}
                                                </td>
                                            ) : null}
                                            <td>Available</td>
                                        </tr>
                                        ))}
                                     </tbody>
                                </table>
                            </div>
                        </div>
                </div>
                </>) : (
                    <div></div>
                )}
        </>
    );
}