import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import GiftCardSection from '../Client/GiftCardSection';

const stripePromise = loadStripe("pk_live_51IpNMjBQYf8AEnjL2CLLLw77I9mLKvT5XrT44wOnI3K6ubQp3YnEgzun3d692U5svXwtcXWe9pv1GiYv9WqMJUiJ00JdV7aP3S");

function RelaxationProduct() {
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  return (
    <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
        <div className="max-w-screen-lg mx-auto">
            <Elements stripe={stripePromise}>
                <GiftCardSection setPaymentCompleted={setPaymentCompleted} />
            </Elements>
        </div>
    </main>

  );
};
export default RelaxationProduct;