import React, { useState, useEffect } from 'react';
import {
  Input,
  Checkbox,
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';

import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Datepicker from "react-tailwindcss-datepicker"; 
import CustomSelect from '../../../helpers/CustomSelect';
import {optionstime} from '../../../helpers/appointmentOptions';


function EditAvailability(props) {

    const {getStudents}=AuthUser();
    const url = props.env;
    const navigate = useNavigate();

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        initialValues: {
            title: '',
            student_name: '',
        },
        validationSchema: Yup.object({
           //  title: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit : event => {
            var data = {
              'id': id,
              'title': titlen,
              'user_id': usern,
              'date': selectedDate,
              'times': checkedItems
            }
            fetch(`${url}auth/availability/${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then(res => res.json())
            .then(
              (result) => {
                if (result['status'] === true) {
                    console.log('resutls',result)
                    toast.success("Availability Updated!");
                    navigate("/admin/availability");
                }
              }
            )
        }
    });

    const {id} = useParams();
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getStudents().then((resp)=>{
            setUsers(resp.data.data);
        });

        fetch(`${url}auth/availability/${id}`)
          .then(res => res.json())
          .then(
            (result) =>  {
              console.log('resutls',result) 
              setTitle(result.data.title)
              setUserID(result.data.user_id)
              setDate(result.data.date)
              setTime(result.data.times)
            }
          )
    }, [id])

    const options = users.map(d => ({
      "value" : d.id,
      "label" : d.email + '(' + d.first_name + ' ' + d.last_name + ')'
    }))

    const [titlen, setTitle] = useState('');
    const [usern, setUserID] = useState('');
    const [checkedItems, setTime] = useState('');

    const [show, setShow] = useState(false)
    const [selectedDate, setDate] = useState(null)

    const handleChange = (selectedDate) => {
        setDate(selectedDate.startDate)
        console.log(selectedDate)
    }

    const handleClose = (state) => {
        setShow(state)
    }

    const handleCheck = (event) => {
        const selectedId = event.target.value;
        if (checkedItems.includes(selectedId)) {
            const newIds = checkedItems.filter((id) => id !== selectedId);
            setTime(newIds);
        } else {
            const newIds = [...checkedItems];
            newIds.push(selectedId);
            setTime(newIds);
        }
    };

    return(
        <div>
           <div className="white-box">
          
                <div className="heading mb-3">
                    <h2>Edit Availability  </h2>
                </div>

                <form onSubmit={handleSubmit}>

                    <div className="flex flex-wrap w-full">
                        <div className="form-input w-full mb-4">
                            <Input
                                autoComplete="title"
                                name="title"
                                variant="static"
                                fullWidth
                                id="title"
                                label="Title"
                                className="border-black placeholder-shown:border-black"
                                value={titlen}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                        </div>

                        <div className="form-select w-full mb-4 relative z-10 mt-6">
                            <label>Student</label>
                            <CustomSelect
                                className='input'
                                onChange={value=> setUserID(value.value)}
                                value={usern}
                                options={options}
                            />
                             {(touched.student_name && errors.student_name) && <span className="error">{errors.student_name}</span>}
                        </div>

                        <div className="form-input w-full mb-4 relative mt-6">

                            <label>Date</label>

                            {/*<Datepicker 
                                className="w-full"
                                dateFormat="dd.MM.yyyy"
                                placeholder={"Date"} 
                                useRange={false} 
                                asSingle={true} 
                                value={date}
                                {...getFieldProps('date')}
                                selected={(getFieldProps('date').value && new Date(getFieldProps('date').value)) || null}
                                onChange={handleValueChange}
                            />
                            {errors.date ? <div className='error'>{errors.date}</div> : null}
                            */}
                            <div class="date-custom-outer">
                                <Datepicker 
                                    className="w-full"
                                    dateFormat="dd.MM.yyyy"
                                    useRange={false} 
                                    asSingle={true}  
                                    options={options} 
                                    onChange={handleChange} 
                                    show={show} 
                                    setShow={handleClose}  
                                    selected={selectedDate}
                                    onFocus={() => setShow(true)} 
                                />
                                <div className="date-test">
                                    <input
                                        type="text"
                                        className="date-input-innner"
                                        placeholder="Select Date"
                                        value={selectedDate}
                                        onFocus={() => setShow(true)}
                                        readOnly
                                    />
                                </div>
                            </div>
                            
                        </div>

                        <div className="flex flex-col flex-wrap w-full w-max gap-0 mt-4">
                            <div className="form-input"><label>Times</label></div>
                            {optionstime.map( (item,index) =>(
                                <>  
                                <Checkbox {...getFieldProps('times')} id={`time_${item.value}`}
                                 onChange={handleCheck} key={`time_${item.value}`} name="times" checked={(checkedItems.includes(item.value))? true : false } defaultChecked={(checkedItems.includes(item.value))? true : false }  label={item.label} value={item.value}/>
                                </>
                            ))}
                        </div>

                    </div>

                    <div className="bottom-button-group flex flex-wrap justify-end">
                      <div className="col">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Update Availability</button>
                      </div>
                    </div>

                </form>

                <Toaster />

            </div>
        </div>
    )
}

export default EditAvailability;