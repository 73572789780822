import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';

function Remedial() {
    const {http,user}=AuthUser();
    const [loading, setLoading] = useState(false);
    const [dataRemedial, SetRemedial] = useState([]);
    const [status, SetStatus] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setCurrentPage] = useState(1);
    const AppointmentsGet = async() => {
        setLoading(true);
        await http.get('auth/appointment-bookings', {
            params: {
              page: page,
              per_page: rowsPerPage,
            }
        }).then((resp)=>{
            console.log(resp, 'resppp');
            const { data } = resp.data;
            SetRemedial(data['2'].data);
            SetStatus(resp.data.bookingstatus['2']);
            setTotalRows(data['2'].total);
            setLoading(false);
        }).catch(err => {
            SetRemedial({});
        });  
    }
    useEffect(() => {
        AppointmentsGet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage])

    const topNav = [
        {
            name: "View All",
            url: getAdminUrl('appointments')
        },
        {
            name: "Relaxation/Basic Treatment",
            url: getAdminUrl('appointments/relaxation')
        },
        {
            name: "Remedial/Deep Tissue",
            url: getAdminUrl('appointments/remedial')
        },
        {
            name: "Myotherapy/Advanced Treatment",
            url: getAdminUrl('appointments/myotherapy')
        },
        ...(user.user_type_primary === 'admin'
        ? [
        {
            name: "Book Appointment",
            url: getAdminUrl('add-appointment'),
            button: [
                {
                    name: "Book Appointment"
                }
            ]
        }
        ]
        : []),
    ]
        
    const columns = [
        {
            name: 'Date',
            selector: row => row.appointment_date,
        },
        {
            name: 'Time',
            selector: row => row.time,
        },
        {
            name: 'Massage Type',
            selector: row => <div class="text-wrap">{row.massage_type}</div>
        },
        {
            name: 'Client',
            selector: row =>  <> 
                <Link target="_blank" to={"/admin/user/edit/" + row.client_id }>{row.client}</Link>
            </>,
        },
        {
            name: 'Student',
            selector: row =>  <> 
                <Link target="_blank" to={"/admin/user/edit/" + row.student_id }>{row.student}</Link>
            </>,
        },
        {
            name: 'Web Paid',
            selector: row => row.paid,
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Balance',
            selector: row => row.voucher_balance,
        },
        {
            name: 'Notes',
            selector: row => row.notes,
        },
        ...(user.user_type_primary === 'admin'
        ? [
            {   
                name:"Action",
                cell: (row) => (
                    <>
                        <Link to={"/admin/edit-appointment/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
                    </>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ]
        : []),
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    return (
        <>

            <TopNav data={topNav} />
            
            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>Remedial/Deep Tissue</h2>
                            <div className="status-bar">
                                <span>Active: {status.active}</span>
                                <span>Completed: {status.completed}</span>
                                <span>Cancelled: {status.Cancelled}</span>
                            </div>
                        </div>
                    </div>

                    <div className="view-all-appointment">

                        <DataTable
                            columns={columns}
                            data={dataRemedial}
                            className="table-title-style"
                            selectableRows={false}
                            pagination
                            paginationServer
                            paginationDefaultPage={page}
                            paginationPerPage={rowsPerPage}
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                              }}
                            progressPending={loading}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}
export default Remedial;