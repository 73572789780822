
import React from 'react';
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Typography} from "@material-tailwind/react";
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const AccordionList = ({ data }) => {
    const [open, setOpen] = useState(0);
 
    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    return (
          <>
          {data.length > 1 ?( 
            <>
              {data.map((item, index) => (
                <Accordion open={open === index+1} icon={<Icon id={index+1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(index+1)} className="text-left">
                      {item.title}
                    </AccordionHeader>
                    <AccordionBody className="font-normal text-base">
                      {item.content}
                    </AccordionBody>
                </Accordion>
            ))}
          </>):( 
          <>
          <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
            <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
               {data.acfb_sttitle}
            </AccordionHeader>
            <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
               {data.acfb_statement}
            </AccordionBody>
          </Accordion>
          <Typography variant="h2" className="text-center text-2xl md:text-4xl mb-6 font-normal">  {data.acfb_welcometitle}</Typography>
          <div dangerouslySetInnerHTML={{ __html: data.acfb_intro }}></div>
          </>
          )}
      </>
    );
}
export default AccordionList;
