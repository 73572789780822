import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button
} from "@material-tailwind/react";

const TopNav = ({ data, filteredItems, downloadCSV, Export, showCSVButton }) => {

    const location = useLocation();
    const { pathname } = location;
    
    const renderMenuItems = data => {
        return data.map((item, index) =>
            (item?.button && item?.button.length) ? (
                <li className={`${pathname === (item.url) ? "active" : ""} ml-auto button-area`} style={{ padding: "15px 0px" }} key={index}><Link className="text-base px-0 py-0" to={item.url}><Button>{item.name}</Button></Link></li>
            ) : <li className={`${pathname === (item.url) ? "active" : ""} nav-link`} style={{ padding: "25px 0" }} key={index}>{ item.url ? <Link className="text-base font-medium px-0 py-0" to={item.url}>{item.name}</Link> : <span className="text-base px-0 py-0">{item.name}</span> }</li>
        )
    }
    return data && (
        <div className="navtab">
            <ul className="flex gap-8 tabs-header px-8">
                {renderMenuItems(data)}
                {showCSVButton &&
                    <Export onExport={() => downloadCSV(filteredItems)} />
                }
            </ul>
        </div>
    );
}
export default TopNav;
