export const statusOption = [
    {
      label: "Available",
      value: "Available"
    },
    {
        label: "Booked",
        value: "Booked"
    },
    {
      label: "Completed",
      value: "Completed"
    },
    {
        label: "Cancelled by ACFB",
        value: "Cancelled by ACFB"
    },
    {
        label: "Cancelled by Student",
        value: "Cancelled by Student"
    },
    {
        label: "No Show",
        value: "No Show"
    }
];

export const optionstime = [
    {
      label: "9:30am",
      value: "9:30am"
    },
    {
      label: "10:45am",
      value: "10:45am"
    },
    {
      label: "12:00pm",
      value: "12:00pm"
    },
    {
      label: "1:15pm",
      value: "1:15pm"
    },
    {
      label: "2:30pm",
      value: "2:30pm"
    },
    {
      label: "3:45pm",
      value: "3:45pm"
    },
    {
      label: "5:00pm",
      value: "5:00pm"
    },
    {
      label: "6:15pm",
      value: "6:15pm"
    },
    {
      label: "7:30pm",
      value: "7:30pm"
    }
];

export const sourceOption = [
    {
      label: "Phone Booking",
      value: "PB"
    },
    {
      label: "Digital Booking",
      value: "DB"
    },
    {
      label: "Cash Booking",
      value: "CB"
    }
];

export const paidstatusOption = [
    {
      label: "Free",
      value: "Free"
    },
    {
      label: "Paid Online (Credit Card / Gift Card)",
      value: "Credit Card"
    },
    {
        label: "Onsite (Cash)",
        value: "Cash"
    },
    {
        label: "Onsite (EFT/Credit Card)",
        value: "EFT"
    },
    {
        label: "Staff",
        value: "Staff"
    },
    {
        label: "Awaiting Payment",
        value: "Awaiting Payment"
    },
    {
        label: "Payment Required",
        value: "Payment Required"
    },
    {
      label: "Applied Credit",
      value: "Applied Credit"
    }
];

export const optionscovid = [
    { value: 'yes', label: 'Yes' },
    { value: "any",label: "No Preference"}
];



export const Morningoptions = [
    {
      label: "9:30am",
      value: "9:30am"
    },
    {
      label: "10:45am",
      value: "10:45am"
    },
    {
      label: "12:00pm",
      value: "12:00pm"
    }
];
export const AfternoonOptions = [
    {
      label: "1:15pm",
      value: "1:15pm"
    },
    {
      label: "2:30pm",
      value: "2:30pm"
    },
    {
      label: "3:45pm",
      value: "3:45pm"
    }
];
export const EveningOptions = [
    {
      label: "5:00pm",
      value: "5:00pm"
    },
   {
      label: "6:15pm",
      value: "6:15pm"
    },
    {
      label: "7:30pm",
      value: "7:30pm"
    }
];

export const dayselect = [
    { 
      value: 'monday',
      label: 'Monday' 
    },
    { 
      value: 'tuesday',
      label: 'Tuesday' 
    },
    {
      value: 'wednesday',
      label: 'Wednesday'
    },
    { 
      value: 'thursday',
      label: 'Thursday' 
    },
    { 
      value: 'friday', 
      label: 'Friday' 
    },
    { 
      value: 'saturday',
      label: 'Saturday' 
    },
    { 
      value: 'sunday', 
      label: 'Sunday' 
    },
];


export const usertype = [
  {
    label: "Client",
    value: "client"
  },
  {
    label: "Student",
    value: "student"
  },
  {
    value: 'editor',
    label: 'Editor'
  },
  {
    label: "Admin",
    value: "admin"
  }
];
export const usertype2 = [
    { 
      value: 'client', 
      label: 'Client'
    },
    {
      value: 'student',
      label: 'Student'
    },
    {
      value: 'editor',
      label: 'Editor'
    },
    { 
      value: 'none',
      label: 'None'
    },
];

export const uservaccinated = [
    { 
      value: 'yes',
      label: 'Yes' 
    },
    { 
    value: 'no', 
    label: 'No'
   },
];

export const genderpreference = [
    {
      value: 'none',
      label: 'None' 
    },
    { 
      value: 'male',
      label: 'Male'
    },
    { 
      value: 'female',
      label: 'Female' 
    }
];

export const userstatus = [
  {
    label: "Active",
    value: "active"
  },
  {
    label: "Banned",
    value: "banned"
  },
  {
    label: "Withdrawn",
    value: "withdrawn"
  },
  {
    label: "Suspended",
    value: "suspended"
  },
  {
    label: "Graduated",
    value: "completed"
  },
];

export const usergender = [
    { 
      value: 'male',
      label: 'Male'
     },
    { 
      value: 'female',
      label: 'Female' 
    },
];
export const usergenderuser = [
    { 
      value: 'male',
      label: 'Male'
     },
    { 
      value: 'female',
      label: 'Female' 
    },
    { 
      value: 'non-binary',
      label: 'Non-binary' 
    },
];

export const TherapistOption = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  },
  {
    label: "No Preference",
    value: "any"
  }
];
