import React from "react";
import {
  Button,
  Input,
  Textarea
} from "@material-tailwind/react";

import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast from 'react-hot-toast';


function CreateNewGift() {
    const {http}=AuthUser();
    const navigate = useNavigate();
    const handleChangeDelivered = (Selected) => {
      setFieldValue("delivered_at", Selected); 
    }
    const handleChangeExpires = (Selected) => {
      setFieldValue("expires_at", Selected); 
    }
    const {handleSubmit, errors, touched, getFieldProps,handleChange, setFieldValue } = useFormik({
        initialValues: {
            code: '',
            expires_at: '',
            delivered_at: '',
            recipient_email: '',
            amount:'',
            balance: '',
            sender: ''
        },
        validationSchema: Yup.object({
          sender: Yup.string().required('Required'),
          recipient_email: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            values = { ...values,delivered_at:values.delivered_at.startDate,expires_at:values.expires_at.startDate}
            http.post('voucher',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Added Gift Card!');
                navigate("/admin/gift");
            }).catch((error) => {
              toast.error('An error occurred.Please try again and fill correct details');
          });
        },
    });
  return (
        <>
           <div className="white-box">
        
              <div className="heading mb-3">
                <h2>Create New  Gift Card</h2>
                <p>‘if admin wishes to purchase a gift card they need to do it in incognito mode/window from the main website - its not available for purchase via admin backend</p>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="flex gap-6 mb-14 w-full">
                  <div className="form-input w-6/12">
                    <Input
                    autoComplete="code"
                    name="code"
                    variant="static"
                    fullWidth
                    id="code"
                    label="Code"
                    disabled
                    placeholder="03A9-DBE3-CAC3-8C8A"
                    className="border-black placeholder-shown:border-black"
                    {...getFieldProps('code')}
                    onChange={handleChange}
                    />
                    {(touched.code && errors.code) && <span className="error">{errors.code}</span>}
                  </div>

                  <div className="form-input w-3/12">
                    <Input
                    autoComplete="amount"
                    name="amount"
                    variant="static"
                    fullWidth
                    id="amount"
                    label="Purchased Amount"
                    placeholder="$25"
                    className="border-black placeholder-shown:border-black"
                    {...getFieldProps('amount')}
                    onChange={handleChange}
                    />
                    {(touched.amount && errors.amount) && <span className="error">{errors.amount}</span>}
                  </div>

                  <div className="form-input w-3/12">
                    <Input
                    autoComplete="balance"
                    name="balance"
                    variant="static"
                    fullWidth
                    id="balance"
                    label="Current Balance"
                    placeholder="$25"
                    className="border-black placeholder-shown:border-black"
                    {...getFieldProps('balance')}
                    onChange={handleChange}
                    />
                    {(touched.balance && errors.balance) && <span className="error">{errors.balance}</span>}
                  </div>
                </div>

                <div className="flex gap-6 mb-14 w-full">
                  <div className="form-input w-5/12">
                    <Input
                    autoComplete="sender"
                    name="sender"
                    variant="static"
                    fullWidth
                    id="sender"
                    label="Sender’s Name"
                    placeholder="KK"
                    className="border-black placeholder-shown:border-black"
                    {...getFieldProps('sender')}
                    onChange={handleChange}
                    />
                    {(touched.sender && errors.sender) && <span className="error">{errors.sender}</span>}
                  </div>

                  <div className="form-input w-4/12">
                    <Input
                    autoComplete="recipient"
                    name="recipient"
                    variant="static"
                    fullWidth
                    id="recipient"
                    label="Recipient’s Name"
                    placeholder="Emma"
                    className="border-black placeholder-shown:border-black"
                    {...getFieldProps('recipient')}
                    onChange={handleChange}
                    />
                    {(touched.recipient && errors.recipient) && <span className="error">{errors.recipient}</span>}
                  </div>

                  <div className="form-select days-input w-3/12 relative">
                      <label htmlFor="rol">Gift Card Created</label>

                      <Datepicker 
                          displayFormat={"DD/MM/YYYY"}
                          placeholder={"Date"} 
                          useRange={false} 
                          asSingle={true} 
                          {...getFieldProps('delivered_at')}
                          selected={(getFieldProps('delivered_at').value && new Date(getFieldProps('delivered_at').value)) || null}
                          onChange={handleChangeDelivered}
                      />
                      {errors.delivered_at ? <div className='error'>{errors.delivered_at}</div> : null}
                                                         
                  </div>

                </div>


                <div className="flex gap-6 w-full">
                  <div className="form-textarea with-label w-5/12">
                    <Textarea 
                    style={{ height: '227px' }} 
                    variant="static" 
                    className="border-black placeholder-shown:border-black"
                    label="Message" 
                    placeholder="Merry Christmas!" 
                    {...getFieldProps('message')}
                    onChange={handleChange}
                    />
                  </div>

                  <div className="w-7/12">
                    <div className="flex gap-8">
                      <div className="form-input w-7/12 relative-label">
                        <label>Recipient’s Email <span className="text-red-500">( Please double check your email is correct before submitting this form )</span></label>
                        <Input
                        autoComplete="recipient_email"
                        name="recipient_email"
                        variant="static"
                        fullWidth
                        id="recipient_email"
                        placeholder="emma.cc@hotmail.com"
                        className="border-black placeholder-shown:border-black"
                        {...getFieldProps('recipient_email')}
                        onChange={handleChange}
                        />
                        {(touched.recipient_email && errors.recipient_email) && <span className="error">{errors.recipient_email}</span>}
                      </div>

                      <div className="form-select days-input w-5/12 relative">
                        <label htmlFor="rol">Expiration Date</label>
                        <Datepicker 
                            displayFormat={"DD/MM/YYYY"}
                            placeholder={"Date"} 
                            useRange={false} 
                            asSingle={true} 
                            {...getFieldProps('expires_at')}
                            selected={(getFieldProps('expires_at').value && new Date(getFieldProps('expires_at').value)) || null}
                            onChange={handleChangeExpires}
                        />
                        {errors.expires_at ? <div className='error'>{errors.expires_at}</div> : null}                         
                      </div>


                    </div>

                    <hr className="mt-8" style={{ borderColor: '#707070' }} />

                    <div className="form-textarea mt-6 with-label w-full">
                      <Textarea 
                        style={{ height: '86px' }} 
                        variant="static" 
                        className="border-black placeholder-shown:border-black"
                        label="Internal Notes" 
                        placeholder="" 
                        {...getFieldProps('notes')}
                        onChange={handleChange}
                      />
                    </div>

                  </div>
                </div>

                <div className="bottom-button-group flex flex-wrap justify-end">
                  <div className="col">
                    <Button className="btn-black" type="submit">Save Gift Card Details</Button>
                  </div>
                </div>

              </form>

          </div>  
        </>
    )
}
export default CreateNewGift;