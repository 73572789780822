export const fncalculationswithgiftcard = (SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService) => {

    if(parseFloat(Cancellation_Fee) && parseFloat(AppliedCredit) && parseFloat(GiftCardBalance)){
        // console.log('ifcancel_Credit_withgft',GiftCardBalance);
        if(parseFloat(AppliedCredit) >= 0){
             TotalServicePrice  =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) - parseFloat(AppliedCredit);
             TotalAmountService =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
             Cancellation_Fee   = parseFloat(Cancellation_Fee);
            if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance)){
                 RemainingGiftamount = '0.00';
                 UsedGiftamount      = parseFloat(GiftCardBalance);
                 TotalServicePrice   =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
            }else if(parseFloat(GiftCardBalance) > parseFloat(TotalServicePrice)){
                 UsedGiftamount      = parseFloat(TotalServicePrice);
                 RemainingGiftamount = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
                 TotalServicePrice   = '0.00';
            }
          }else{
            TotalServicePrice =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) -+ parseFloat(AppliedCredit);
            TotalAmountService =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee)-+ parseFloat(AppliedCredit);
            Cancellation_Fee   = parseFloat(Cancellation_Fee);
            if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance)){
                 RemainingGiftamount   = '0.00';
                 UsedGiftamount        = parseFloat(GiftCardBalance);
                 TotalServicePrice     =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
            }else if(parseFloat(GiftCardBalance) > parseFloat(TotalServicePrice)){
                 UsedGiftamount      = parseFloat(TotalServicePrice);
                 RemainingGiftamount = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
                 TotalServicePrice   = '0.00';
            }
         }
       return { RemainingGiftamount, TotalServicePrice,AppliedCredit,Cancellation_Fee,GiftCardBalance,UsedGiftamount,TotalAmountService}; 
    }else if(parseFloat(Cancellation_Fee) && parseFloat(GiftCardBalance)){
        // console.log('ifcancelwithgft',GiftCardBalance);
        TotalServicePrice =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
        TotalAmountService = parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
        Cancellation_Fee   = parseFloat(Cancellation_Fee);
        if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance)){
            RemainingGiftamount = '0.00';
            UsedGiftamount      = parseFloat(GiftCardBalance);
            TotalServicePrice   =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
        }else if(parseFloat(GiftCardBalance) >= parseFloat(TotalServicePrice)){
           RemainingGiftamount = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
           UsedGiftamount      = parseFloat(TotalServicePrice);
           TotalServicePrice   = 0.00;
       }
    
      return { RemainingGiftamount, TotalServicePrice,Cancellation_Fee,GiftCardBalance,UsedGiftamount,TotalAmountService }; 

   }else if(parseFloat(AppliedCredit) && parseFloat(GiftCardBalance)){
       
    //    console.log('ifAppliedCreditwithgft',GiftCardBalance);
       if(parseFloat(AppliedCredit) >= 0 ){
           TotalServicePrice        =  parseFloat(SelectedServicePrice) - parseFloat(AppliedCredit);
           TotalAmountService       = parseFloat(SelectedServicePrice);
           Cancellation_Fee         = 0.00;
           if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance) ){
               TotalServicePrice    =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
               UsedGiftamount       = parseFloat(GiftCardBalance);
               RemainingGiftamount  = '0.00';
           }else{
               RemainingGiftamount  = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
               UsedGiftamount       = parseFloat(TotalServicePrice);
               TotalServicePrice    = '0.00';
           }
      }else{
        //    console.log('GiftAppliedCreditCardBalance',GiftCardBalance);
           TotalServicePrice        =  parseFloat(SelectedServicePrice) -+ parseFloat(AppliedCredit);
           TotalAmountService       = parseFloat(SelectedServicePrice) -+ parseFloat(AppliedCredit);
           Cancellation_Fee         = 0.00;
           if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance) ){
               UsedGiftamount       = parseFloat(GiftCardBalance);
               TotalServicePrice    =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
               RemainingGiftamount  = '0.00';
           }else{
               RemainingGiftamount  = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
               UsedGiftamount       = parseFloat(TotalServicePrice);
               TotalServicePrice    = '0.00';
           }
      }
      return { RemainingGiftamount, TotalServicePrice,AppliedCredit,GiftCardBalance,UsedGiftamount,TotalAmountService,Cancellation_Fee }; 
   }else if(parseFloat(GiftCardBalance)){
        //   console.log('GiftCardBalance',GiftCardBalance);
          TotalServicePrice =  parseInt(SelectedServicePrice);
          TotalAmountService = parseFloat(SelectedServicePrice);
          Cancellation_Fee         = 0.00;
          if(parseFloat(TotalServicePrice) >= parseFloat(GiftCardBalance) ){
            TotalServicePrice   =  parseFloat(TotalServicePrice) - parseFloat(GiftCardBalance);
            UsedGiftamount      = parseFloat(GiftCardBalance);
            RemainingGiftamount = '0.00';
        }else{
           RemainingGiftamount = parseFloat(GiftCardBalance) - parseFloat(TotalServicePrice);
           UsedGiftamount      = parseInt(TotalServicePrice);
           TotalServicePrice = '0.00';
       }
       return { RemainingGiftamount, TotalServicePrice,AppliedCredit,GiftCardBalance,UsedGiftamount,TotalAmountService,Cancellation_Fee }; 
   }else{
      return fncalculationswithoutgiftcard(SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService)
   }
}  

const fncalculationswithoutgiftcard = (SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService) => {
      
   if(parseFloat(Cancellation_Fee) > 0 && parseFloat(AppliedCredit)){

        if(parseFloat(AppliedCredit) >= 0){
           if(parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) >= parseFloat(AppliedCredit)){
              TotalServicePrice   =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) - parseFloat(AppliedCredit);
              TotalAmountService  = parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
              AppliedCredit       = parseFloat(AppliedCredit);
              UsedGiftamount      = 0.00
              Cancellation_Fee   = parseFloat(Cancellation_Fee);
            }else{
                TotalServicePrice   =  0;
                Cancellation_Fee    = parseFloat(Cancellation_Fee);
                AppliedCredit       = parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
                TotalAmountService  = 0;
                UsedGiftamount      = 0.00
            }
        }else{
            TotalServicePrice  =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) -+ parseFloat(AppliedCredit);
            TotalAmountService = parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee) -+ parseFloat(AppliedCredit);
            UsedGiftamount     = 0.00
            Cancellation_Fee   = parseFloat(Cancellation_Fee);
        }
     }
    else if(parseFloat(AppliedCredit)){
          if(parseFloat(AppliedCredit) >= 0){
            if(parseFloat(SelectedServicePrice) >= parseFloat(AppliedCredit)){
                //   console.log('ifAppliedCredit',AppliedCredit);
                TotalServicePrice   =  parseFloat(SelectedServicePrice) - parseFloat(AppliedCredit);
                TotalAmountService  = parseFloat(SelectedServicePrice);
                AppliedCredit       = parseFloat(AppliedCredit);
                UsedGiftamount      = 0.00;
                Cancellation_Fee   = 0.00;
            }else{
                // console.log('elseifAppliedCredit',AppliedCredit);
                RemainingApplCredit = parseFloat(AppliedCredit)-parseFloat(SelectedServicePrice);
                AppliedCredit       = parseFloat(SelectedServicePrice);
                TotalServicePrice =  '0.00';
                UsedGiftamount     = 0.00;
                Cancellation_Fee   = 0.00;
            }
        }else{
            // console.log('AppliedCredit',AppliedCredit);
            TotalServicePrice =  parseFloat(SelectedServicePrice) -+ parseFloat(AppliedCredit);
            TotalAmountService = parseFloat(SelectedServicePrice) -+ parseFloat(AppliedCredit);
            UsedGiftamount     = 0.00;
            Cancellation_Fee   = 0.00;
        }
    }else if(parseFloat(Cancellation_Fee) > 0 ){
        //   console.log('AppliedCredit',Cancellation_Fee);
          TotalServicePrice =  parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
          TotalAmountService = parseFloat(SelectedServicePrice) + parseFloat(Cancellation_Fee);
          UsedGiftamount     = 0.00
          Cancellation_Fee   = parseFloat(Cancellation_Fee);
    }else{
        TotalServicePrice =  parseFloat(SelectedServicePrice);
        TotalAmountService = parseFloat(SelectedServicePrice);
        UsedGiftamount     = 0.00;
        Cancellation_Fee   = 0.00;
    }
   return { SelectedServicePrice,TotalServicePrice,Cancellation_Fee,AppliedCredit,TotalServicePrice,RemainingApplCredit,TotalAmountService,UsedGiftamount } ; 
}
