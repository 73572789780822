import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

const MultilevelMenu = ({ data, userRole }) => {
  const location = useLocation();
  const { pathname, search } = location;
  const [open, setOpen] = useState('');

  const toggleDetails = (thingId, isSubMenu = false) => {
    setOpen((prevOpen) => (isSubMenu ? prevOpen : prevOpen === thingId ? '' : thingId));
  };

  const renderMenuItems = (data) => {
    return data.map((item, index) => {
      const isVisible = !item.roles || item.roles.includes(userRole);

      if (!isVisible) {
        return null;
      }

      if (item.children && item.children.length) {
        return (
          <SubMenu
            key={item.id}
            open={open === item.id}
            onClick={() => toggleDetails(item.id)}
            data-attr={open}
            defaultOpen={item.open}
            tes-id={item.id}
            component={
              <Link
                className="cursor-pointer items-center p-2 text-sm font-semibold hover:bg-greycolor4 px-5 py-5 rounded-md"
                to={item.url}
              />
            }
            icon={item.icon}
            label={item.name}
          >
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      } else {
        return (
          <MenuItem
            key={index}
            onClick={() => toggleDetails(item.id, true)}
            className={pathname === item.url || pathname + search === item.url ? 'active' : ''}
            icon={item.icon}
            component={
              <Link
                className="cursor-pointer items-center p-2 text-sm font-semibold   hover:bg-greycolor4 px-5 py-5 rounded-md"
                to={item.url}
              />
            }
          >
            {item.name}
          </MenuItem>
        );
      }
    });
  };

  return data && (
    <Menu className="px-4 main-menu">
      {renderMenuItems(data)}
    </Menu>
  );
};

export default MultilevelMenu;
