import React, { useState, useEffect } from 'react';
import {
  Button,
  Input
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function AddNewMassageType() {

    const {http} = AuthUser();
    const navigate = useNavigate();

    const handleChangeStartDate = (Selected) => {
      setFieldValue("promo_start_date", Selected); 
    }
    const handleChangeEndDate = (Selected) => {
      setFieldValue("promo_end_date", Selected); 
    }

    const { handleSubmit, setFieldValue, errors, touched, getFieldProps} = useFormik({
        initialValues: {
            title: '',
            price: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Required').min(2).max(50),
            price: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: values => {
          if(values.promo_start_date){
            values = {...values,promo_start_date:values.promo_start_date.startDate,promo_end_date:values.promo_end_date.startDate}
          }else{
            values = {...values}
          }
            console.log('values',values);
            http.post('auth/services',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully Created new booking type!');
                navigate("/admin/services");
            }).catch((err) => {
              toast.success('An error occur to create new booking type. Please try again!');
            });
        },
    });
	return(
        <div>
		   <div className="white-box p-0">
              	<div className="heading mb-3 px-8 pt-8">
                  	<h2>Add New Booking Type</h2>
              	</div>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  	<div className="grid grid-cols-4 gap-8 mb-14 w-full px-8">
                        <div className="form-input">
                            <Input
                                autoComplete="title"
                                name="title"
                                variant="static"
                                fullWidth
                                id="title"
                                label="Title"
                                {...getFieldProps('title')}
                                className="border-black placeholder-shown:border-black"
                              />
                          {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                        </div>

                        <div className="form-input">
                            <Input
                                autoComplete="price"
                                name="price"
                                variant="static"
                                fullWidth
                                id="price"
                                label="Price"
                                {...getFieldProps('price')}
                                className="border-black placeholder-shown:border-black"
                              />
                         {(touched.price && errors.price) && <span className="error">{errors.price}</span>}
                        </div>
                        <div className="form-input">
                            <Input
                                autoComplete="promo_price"
                                name="promo_price"
                                variant="static"
                                fullWidth
                                id="promo_price"
                                label="Promo Price"
                                {...getFieldProps('promo_price')}
                                className="border-black placeholder-shown:border-black"
                              />
                         {(touched.promo_price && errors.promo_price) && <span className="error">{errors.promo_price}</span>}
                        </div>

                        <div className="form-input mb-0">
                            <label className="">Image</label>
                            <input
                                type="file"
                                className="w-full px-4 py-2"
                                label="Image"
                                name="image"
                                onChange={(event) => {
                                  const formData = new FormData();
                                  const reader = new FileReader();
                                  if (event.target.files[0]) {
                                       reader.readAsDataURL(event.target.files[0]);
                                     }
                                  reader.onload = (readerEvent) => {
                                  formData.append("image", readerEvent.target.result);
                                  console.log('formData',formData);
                                  setFieldValue('image', readerEvent.target.result);
                                 };

                                 }}
                            />
                          {(touched.image && errors.image) && <span className="error">{errors.image}</span>}
                        </div>
                	</div>

                    <div className="grid grid-cols-4 gap-8 mb-8 gap-8 px-8 w-full">
                        <div className="form-select days-input relative">
                            <label htmlFor="rol">Start Date</label>
                            <Datepicker 
                                displayFormat={"DD/MM/YYYY"}
                                placeholder={"Date"} 
                                useRange={false} 
                                asSingle={true} 
                                {...getFieldProps('promo_start_date')}
                                selected={(getFieldProps('promo_start_date').value && new Date(getFieldProps('promo_start_date').value)) || null}
                                onChange={handleChangeStartDate}
                            />
                            {errors.promo_start_date ? <div className='error'>{errors.promo_start_date}</div> : null}                         
                        </div>

                        <div className="form-select days-input relative">
                            <label htmlFor="rol">End Date</label>
                            <Datepicker 
                                displayFormat={"DD/MM/YYYY"}
                                placeholder={"Date"} 
                                useRange={false} 
                                asSingle={true} 
                                {...getFieldProps('promo_end_date')}
                                selected={(getFieldProps('promo_end_date').value && new Date(getFieldProps('promo_end_date').value)) || null}
                                onChange={handleChangeEndDate}
                            />
                            {errors.promo_end_date ? <div className='error'>{errors.promo_end_date}</div> : null}                         
                        </div>
                    </div>

                 	<div className="bottom-button-group flex flex-wrap justify-end px-8 pb-8">
                      <div className="col">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Submit</button>
                      </div>
                    </div>
                </form>
                <Toaster />
           	</div>
        </div>
	)
}

export default AddNewMassageType;