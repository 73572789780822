import React, {useState,useEffect} from "react";
import Datepicker from "react-tailwindcss-datepicker";
import AuthUser from '../../../helpers/AuthUser';
import Loading from '../../../Components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faCircleCheck,
	faPencil,
	faCircleXmark,
	faDollarSign
} 
from '@fortawesome/free-solid-svg-icons';

function DashboardTop(){

	const {http}=AuthUser();
	const [data, setdata] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [error,setError] = useState(false);

	const [value, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    

	const handleValueChange = (selecteddate) => {
        setValue(selecteddate);
        let parms = {
            selected_date: selecteddate.startDate
          };
		GetDashboardList(parms);
    }



 	const GetDashboardList = async (params) => {
			setLoading(true);
            await http.post('/dashboard',params).then((resp)=>{
                setdata(resp.data);
				setLoading(false);
            }).catch(err => {
				setError(true);
				console.log(error.response.data.message);
            });
    }
	useEffect(() => {
		const currentDate = new Date().toISOString().slice(0, 10);
		setValue(currentDate);
        GetDashboardList(currentDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
	return(
		<> 
		{ isLoading && <Loading /> }
			<div className="row dash-head">
				<h1 className="col col-6 page-title">Dashboard</h1>
				<div className="col col-6 align-right">
					<div className="calender-filter">
						<Datepicker 
							value={value} 
							onChange={handleValueChange} 
							displayFormat={"DD/MM/YYYY"}
	                        placeholder={"Date"} 
	                        useRange={false} 
	                        asSingle={true} 
						/> 
					</div>
				</div>
			</div>

			<div className="row dash-index">
				<div className="col col-4">
					<div className="white-box complete">
						<div className="row">
							<div className="col">
								<div className="text">
									<h6>Total Completed</h6>
									<h3>{data.Completed}</h3>
								</div>
							</div>
							<div className="col">
								<div className="icon green-icon">
									<FontAwesomeIcon icon={faCircleCheck} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col col-4">
					<div className="white-box schedule">
						<div className="row">
							<div className="col">
								<div className="text">
									<h6>Total Scheduled</h6>
									<h3>{data.Scheduled}</h3>
								</div>
							</div>
							<div className="col">
								<div className="icon pen-icon orange-icon">
									<FontAwesomeIcon icon={faPencil} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col col-4">
					<div className="white-box cancel">
						<div className="row">
							<div className="col">
								<div className="text">
									<h6>Total Cancelled</h6>
									<h3>{data.Cancelled}</h3>
								</div>
							</div>
							<div className="col">
								<div className="icon red-icon">
									<FontAwesomeIcon icon={faCircleXmark} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col col-4">
					<div className="white-box sale">
						<div className="row">
							<div className="col">
								<div className="text">
									<h6>Total Sales</h6>
									<h3>${data.Sales}</h3>
								</div>
							</div>
							<div className="col">
								<div className="icon blue-color rounded-icon">
									<FontAwesomeIcon icon={faDollarSign} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


export default DashboardTop;