import React, {useState} from "react";
import { Chart } from 'primereact/chart';
import Datepicker from "react-tailwindcss-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faAngleLeft,
    faAngleRight
} 
from '@fortawesome/free-solid-svg-icons';
import {getAdminUrl} from '../../../helpers/Utils';
import TopNav from '../../../Components/TopNav/TopNav';

import {
  Input
} from "@material-tailwind/react";


const topNav = [
    // {
    //     name: "View Allocation Report",
    //     url: getAdminUrl('view-allocation-report')
    // },
    {
        name: "View Allocations",
        url: getAdminUrl('view-allocation')
    },
    {
        name: "View Custom Allocations",
        url: getAdminUrl('view-custom-allocation')
    },
    {
        name: "Add Custom Allocation",
        url: getAdminUrl('view-custom-allocation'),
        button: [
            {
                name: "Add Custom Allocation"
            }
        ]
    }
] 

function ViewAllocationReport() {

    const [chartData1] = useState({
        labels: [
            'Relaxation/Basic Treatment',
        ],
        datasets: [
            {
                data: [31, 50, 50],
                backgroundColor: [
                    "#FFB343",
                    "#002D5A",
                    "#B9B9B9"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });

    const [chartData2] = useState({
        labels: [
            'Remedial/Deep Tissue Treatment',
        ],
        datasets: [
            {
                data: [31, 50, 50],
                backgroundColor: [
                    "#FFB343",
                    "#002D5A",
                    "#B9B9B9"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });

    const [chartData3] = useState({
        labels: [
            'Myotherapy/Advanced Treatment',
        ],
        datasets: [
            {
                data: [31, 50, 50],
                backgroundColor: [
                    "#FFB343",
                    "#002D5A",
                    "#B9B9B9"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const [value, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    
    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setValue(newValue);
    }

    return (
        <>
            <TopNav data={topNav} />

            <div className="white-box">
                <div className="master-allocation-head">

                    <div className="flex mb-8">
                        <h2>Master Allocations</h2>

                        <div className="calender-filter">
                            <Datepicker 
                                showShortcuts={true} 
                                showFooter={true} 
                                value={value} 
                                onChange={handleValueChange} 
                            /> 
                            <div className="calender-links">
                                <button className="prev"><FontAwesomeIcon icon={faAngleLeft} /></button>
                                <button className="next"><FontAwesomeIcon icon={faAngleRight} /></button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="three-graph-grid pt-6">
                    <div className="row">
                        <div className="col-3">
                            <div className="message-type-graph">
                                <Chart type="doughnut" data={chartData1} style={{ position: 'relative', width: '100%' }} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="message-type-graph">
                                <Chart type="doughnut" data={chartData2} style={{ position: 'relative', width: '100%' }} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="message-type-graph">
                                <Chart type="doughnut" data={chartData3} style={{ position: 'relative', width: '100%' }} />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center text-center booking-main">
                        <span className="booking">5:00 pm</span>
                        <span className="booking">6:15 pm</span>
                        <span className="booking">7:30 pm</span>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ViewAllocationReport;