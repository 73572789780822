import { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../../helpers/AuthUser';
//import Pagination from '../../helpers/Pagination';
import { Link } from 'react-router-dom';

import {getAdminUrl} from "../../../../helpers/Utils";

import {
  Button,
  Input
} from "@material-tailwind/react";

import toast, { Toaster } from 'react-hot-toast';
import TopNav from '../../../../Components/TopNav/TopNav';
const topNav = [
    {
        name: "View All",
        url: getAdminUrl('services')
    },
    {
        name: "Client Notes",
        url: getAdminUrl('services/special-needs')
    },
    {
        name: "Add New",
        url: getAdminUrl('services/special-needs/add-new'),
        button: [
            {
                name: "Add New"
            }
        ]
    }
]


export default function SpecialNeeds(props){
    const {http}=AuthUser();

    const loading=false;

    const [sneeds, setNeeds] = useState([]);
    useEffect(() => {
        SpecialNeedGet()
    }, [])

    const SpecialNeedGet = () => {
     http.get('auth/needs').then((resp)=>{
        console.log('sdddf',resp)
        setNeeds(resp.data.data);
     });  
    }

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/service/special-needs/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    return (
        <>
            <TopNav data={topNav} />

            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>All</h2>
                            <div className="input-middle">
                            </div>
                            <div className="status-bar">
                            </div>
                        </div>
                    </div>

                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={sneeds}
                            className="table-title-style"
                            // pagination
                          //  paginationComponent={Pagination}
                            // selectableRows
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}