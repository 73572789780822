import React from "react";
import AuthUser from '../../helpers/AuthUser';
import ViewAll from '../Components/Appointments/ViewAll';

function Appointments() {
  
    return ( 
        <ViewAll />
    )
}
export default Appointments;
