import React, { useState, useEffect } from 'react';
import AuthUser from '../../helpers/AuthUser';

function StudentTopNav(props){
    const {http} = AuthUser();
    const User = JSON.parse(localStorage.getItem('user'));
    const {services, records} = props;

    return(
        <div className="user-single-head md:mt-8 mb-10">
            <div className="md:flex gap-4 mb-8 w-full">
                <div className="mb-6 md:m-0 client-info">
                    <h4>Welcome to ACFB Student Massage Clinic</h4>
                    <h2 className="text-title capitalize">{User.first_name} {User.last_name}</h2>
                    <div className="status-bar">
                     <span>Active Treatment Types:</span>
                        {services.map((item, index) => (
                                <>
                                {User.massage_type === item.id ?  ( 
                                        <span className="text-title">{ item.title}</span>   
                                    ) : (' ')}
                                </>
                          ))}
                      </div>
                </div>
                <div className="ml-auto">
                    <div className="sm:flex gap-4">
                        <div className="white-box card-box w-full mb-2 sm:m-0 sm:w-4/12">
                            <h4>Total Active Scheduled</h4>
                            <h2>{records.scheduledAppointments}</h2>
                        </div>
                        <div className="white-box card-box w-full mb-2 sm:m-0 sm:w-4/12">
                            <h4>Total Active Completed</h4>
                            <h2>{records.completedAppointments}</h2>
                        </div>
                        <div className="white-box card-box w-full sm:w-4/12">
                            <h4>Total Active Cancelled</h4>
                            <h2>{records.cancelledAppointments}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentTopNav;