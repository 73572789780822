import React, { useState } from 'react';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';


function FromHelper(props) {
  const { frominput, formikData } = props;
  const { inputType, inputId, inputPlaceHolder, classes, parentclasses } = frominput;

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={`form-input ${parentclasses}`}>
      <input
        type={showPassword ? 'text' : inputType}
        className={classes}
        id={inputId}
        name={inputId}
        placeholder={inputPlaceHolder}
        onChange={formikData.handleChange}
        value={formikData.values[inputId]}
      />
      {inputType === 'password' && (
        <span
          className="password-toggle"
          onClick={handleTogglePassword}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          <img
            title={showPassword ? "Hide password" : "Show password"}
            className = {showPassword ? "Hide password" : "Show password"}
            src={showPassword ? hidePwdImg : showPwdImg}
            alt={showPassword ? "Hide password" : "Show password"}
          />

        </span>
      )}
      {frominput.inputLavel && <label htmlFor={inputId}>{frominput.inputLavel}</label>}
      {formikData.touched[inputId] && formikData.errors[inputId] ? (
        <div style={{ textAlign: 'left', color: 'red' }}>{formikData.errors[inputId]}</div>
      ) : null}
    </div>
  );
}

export default FromHelper;
