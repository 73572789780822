import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';
import Loading from '../../../Components/Loading';

function UserSMSReports(){

        const {id} = useParams();
        const {http} = AuthUser();
        const [AppointmentData, setAppointmentData] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [data, setdata] = useState([]);
        const [User, setUsers] = useState([]);

    useEffect(() => {
       // setIsLoading(false);
        const fetchData = async () => {
          try {
           const response = await http.get(`/user/${id}/smslog`);
           console.log(response.data.data);
            setdata(response.data.data);
            setIsLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);



     useEffect(() => {
        const fetchData = async (userId) => {
          try {
            setIsLoading(true);
            const userResponse = await http.get(`auth/user/${userId}`);
            const data = userResponse.data.data;
            setUsers(data); 
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        };
        fetchData(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        async function fetchData1() {
          try {
                setIsLoading(true);
                const response =  await http.get(`auth/appointment/user/${id}`);
                const data = await response.data;
                  setAppointmentData(data);
                  setIsLoading(false);
          } catch (error) {
              toast.error(error.response);
          }
        } 
        setIsLoading(false);
        fetchData1();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const topNav = [
        {
            name: "User Profile",
            url: getAdminUrl(`user/edit/${id}`)
        },
        {
            name: "User Reports",
            url: getAdminUrl(`user/reports/${id}`)
        },
        {
            name: "History/Log",
            url: getAdminUrl(`user/sms-log/${id}`)
        }
    ]

  const SMScolumns = [
            {
                name: 'Date Sent',
                selector: row => row.date
            },
            {
                name: 'Time Sent',
                selector: row => row.time
            },
             {
                name: 'Contact',
                selector: row => row.phone
            },
            {
                name: 'Mobile',
                selector: row => row.mobile
            },
            {
                name: 'User Type',
                selector: row => row.user_type
            },
            {
                name: 'Status',
                selector: row => row.apistatus,
            },
            {
                name: 'SMS TYPE',
                selector: row => row.status,
            },
            {
                name: 'Message',
                selector: row => row.message
            },
        ];
    return(
        <>
        {isLoading && <Loading /> }
            <div className="user-single-head">
                <div className="flex gap-4 mb-8 w-full">
                    <div className="w-6/12 client-info">
                        <h4 className="capitalize">{User.user_type_primary}</h4>
                        <h2 className="text-white">{User.first_name + ' ' + User.last_name}</h2>
                        <div className="status-bar">
                            <span>{User.user_status}</span>
                            <span>{User.phone_number}</span>
                        </div> 
                    </div>
                    <div className="w-6/12">
                        <div className="flex gap-4">
                          {User.user_type_primary === 'student' ?(
                            <div className="white-box card-box w-4/12">
                                <h4>Target Number of Clinics</h4>
                                <h2>{User.selected_services_count}</h2>
                            </div>
                            ):(
                            <div className="white-box card-box w-4/12">
                               <h4>Total Scheduled</h4>
                               <h2>{AppointmentData.scheduledAppointments}</h2>
                            </div>
                            )}
                            <div className="white-box card-box w-4/12">
                                <h4>Total Completed</h4>
                                <h2>{AppointmentData.completedAppointments}</h2>
                            </div>
                            <div className="white-box card-box w-4/12">
                                <h4>Total Cancelled</h4>
                                <h2>{AppointmentData.cancelledAppointments}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <TopNav data={topNav} />

                    <div className="reports-table w-100 px-4 mb-6">
                        <h2 className="mb-6">SMS History/Log</h2>
                        <div className="table-section">
                            <DataTable
                            columns={SMScolumns}
                            className="table-title-style"
                            data={data}
                            progressPending={isLoading}
                            />
                       
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserSMSReports;