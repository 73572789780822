import React, {useState, useEffect} from "react";
import {
  Button,
  Input,
  Textarea,
  Typography
} from "@material-tailwind/react";
import { useFormik,FieldArray, Field } from 'formik';
import { useNavigate, useParams, Link } from 'react-router-dom';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { Editor } from "@tinymce/tinymce-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircleXmark
} 
from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../Components/Loading';

function MakeBookingEdit() {
    const {id} = useParams();
    const {http}=AuthUser();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const {handleSubmit,handleBlur, errors, touched, values,getFieldProps, setFieldValue,handleChange } = useFormik({
      initialValues: {
        acfb_welcometitle: '',
        acfb_sttitle: '',
        acfb_terms_content: '',
        acfb_statement: '',
        acfb_intro: '',
        acfb_termsconditions:'',
        acfb_booking_confirmation: ''
      },
      onSubmit: (values) => {
        // console.log(values, 'values');
        http.post('booking/acfbcontent',JSON.stringify(values, null, 2)).then((res)=>{
          toast.success(res.data.message);
          window.location.reload();
        }).catch(err => {
          console.log('err',err);
       });
      },
    });
  
    const handleEditorChange = (state) => {
      setData({});
      setFieldValue('acfb_intro',state);
    };
    
    const handleEditorTermsChange = (termscontent) => {
      setData({});
      setFieldValue('acfb_termsconditions',termscontent);
    };
    
    const handleEditorbooking_confirmation = (confirmationtext) => {
      setData({});
      setFieldValue('acfb_booking_confirmation',confirmationtext);
    };
    
 
  useEffect(() => {
    async function fetchData() {
      try {
          setLoading(true);
          const response =  await http.get(`booking/acfbcontent/1`);
          const data = await response.data.data;
          setData(data);
          const fields = ['id','acfb_welcometitle','acfb_sttitle','acfb_terms_content','acfb_statement','acfb_intro','acfb_termsconditions','acfb_booking_confirmation'];
          fields.forEach(field => setFieldValue(field, data[field], false));
          setLoading(false);
      } catch (error) {
        console.error(error);
      }
  }
  fetchData();
  }, []);
    return (<>
        { isLoading && <Loading /> }
        <div className="white-box">
           <div className="heading mb-3">
                <h2>Make A Booking</h2>
          </div>
      
        <form onSubmit={handleSubmit}>
        <Typography variant="h4" className="mb-4">ACFB Statements</Typography>
          <div className="flex flex-wrap w-full">
            <div className="form-input mb-12 w-full">
              <Input
              autoComplete="acfb_sttitle"
              name="acfb_sttitle"
              variant="static"
              fullWidth
              id="acfb_sttitle"
              placeholder="acfb_sttitle"
              className="border-black placeholder-shown:border-black"
              value={data.acfb_sttitle} 
              onChange={e => {
                setData({});
                setFieldValue(e.target.name,e.target.value);
              }}
              />
            </div>
           
            <div className="form-textarea mb-12 w-full">
              <Textarea
              name={`acfb_statement`}
              variant="static" 
              className="border-black placeholder-shown:border-black"
              value={data.acfb_statement} 
              onChange={e => {
                setData({});
                setFieldValue(e.target.name,e.target.value);
              }}
              placeholder="Content"
              />
            </div>
      
            <hr className="mt-6 mb-8" style={{ borderColor: '#707070' }} />
      
            <Typography variant="h4" className="mb-4">ACFB Welcome Content</Typography>
      
            <div className="form-textarea mb-12 w-full">
                <div className="form-input mb-12 w-full">
                  <Input
                    autoComplete="acfb_welcometitle"
                    name="acfb_welcometitle"
                    variant="static"
                    fullWidth
                    id="acfb_welcometitle"
                    placeholder="Title"
                    className="border-black placeholder-shown:border-black"
                    value={data.acfb_welcometitle} 
                    onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                    }}
                  />
                </div>
                <Editor
                  apiKey="f3isu9gcf0py01o0f1syofk0dsznwftqecxu14ukvrfsanf1"
                  value={data.acfb_intro}
                  disabled={false}
                  initialValue={data.acfb_intro}
                  init={{
                      height: 500,
                      menubar: false,
                      plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                      toolbar: 'undo redo | blocks fontfamily fontsize | code| bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  }}
                  inline={false} 
                  onEditorChange={handleEditorChange}
                  outputFormat='html'
                  />
            </div>
            <hr className="block mt-6 mb-8" style={{ borderColor: '#707070' }} />     

            <Typography variant="h4" className="mb-4">ACFB Term and conditions</Typography>
            <div className="form-textarea mb-12 w-full">
              <div className="form-input mb-12 w-full">
                <Input
                autoComplete="acfb_termscontent"
                name="acfb_terms_content"
                variant="static"
                fullWidth
                id="acfb_termscontent"
                placeholder="Terms and conditions small heading"
                className="border-black placeholder-shown:border-black"
                value={data.acfb_terms_content} 
                onChange={e => {
                  setData({});
                  setFieldValue(e.target.name,e.target.value);
                }}
                />
             </div>
              <Editor
                  apiKey="f3isu9gcf0py01o0f1syofk0dsznwftqecxu14ukvrfsanf1"
                  value={data.acfb_termsconditions}
                  disabled={false}
                  initialValue={data.acfb_termsconditions}
                  init={{
                      height: 500,
                      menubar: false,
                      plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                      toolbar: 'undo redo | blocks fontfamily fontsize | code| bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  }}
                  inline={false} 
                  onEditorChange={handleEditorTermsChange}
                  outputFormat='html'
                  />
              </div>

              <hr className="block mt-6 mb-8" style={{ borderColor: '#707070' }} />  

            <Typography variant="h4" className="mb-4">Booking Confirmation Text</Typography>
            <div className="form-textarea mb-12 w-full">
              <Editor
                  apiKey="f3isu9gcf0py01o0f1syofk0dsznwftqecxu14ukvrfsanf1"
                  value={data.acfb_booking_confirmation}
                  disabled={false}
                  initialValue={data.acfb_booking_confirmation}
                  init={{
                      height: 500,
                      menubar: false,
                      plugins: 'code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                      toolbar: 'undo redo | blocks fontfamily fontsize | code| bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  }}
                  inline={false} 
                  onEditorChange={handleEditorbooking_confirmation}
                  outputFormat='html'
                />
              </div>

            
            <hr className="block mt-6 mb-8" style={{ borderColor: '#707070' }} />  
          </div>
          <Button type="submit" className="btn-black mt-4">Save</Button>
        </form>
      </div>
    </>)
}
export default MakeBookingEdit;