import React, { useState, useEffect } from 'react';
import { Typography} from "@material-tailwind/react";
import AuthUser from '../helpers/AuthUser';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import 'react-calendar/dist/Calendar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useBookingData from '../helpers/api/useBookingData';
import Loading from '../Components/Loading';
function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-0" : "" } transition-transform`}>Read more…</span>
  );
}
function BookingConfirmation(){
    const {http} = AuthUser();
    const [open, setOpen] = useState(0);
    const [bookingdata, setBookingData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const data = useBookingData();
    const { orderData } = location.state || {};
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!orderData) {
                  // If orderData is not available yet, wait for a short duration and retry
                setLoading(true);
                setError(null);
                setTimeout(fetchData, 500);
                return;
            }
    
            try {
                setLoading(true);
                setError(null);
    
                const response = await http.get(`/checkout-bookings/${orderData.orderId}`);
                setBookingData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching booking data:', error);
    
                if (error.response && error.response.status === 429) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    fetchData();
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching booking data.');
                    setLoading(false);
                }
            } finally {
                setLoading(false);
            }
         };
        fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [orderData?.orderId]);

    if (!orderData) {
        navigate('/make-booking');
        return null;
    }

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
         { !data && isLoading && <Loading /> }
        {data &&
            <div className="max-w-screen-lg mx-auto">

                <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
                    {data.acfb_sttitle}
                    </AccordionHeader>
                    <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
                      {data.acfb_statement}
                    </AccordionBody>
                </Accordion>
                
                <div className="bg-white py-8 px-4 md:px-8 rounded-xl">

                    <Typography variant="h2" className="text-center mb-6 font-normal">Your appointment has been booked successfully.</Typography>

                    <div className="text-lg text-darkgrey1 mb-1">
                        <strong>Massage Type:</strong> {bookingdata.service}
                    </div>
                    <div className="text-lg text-darkgrey1 mb-1">
                        <strong>Appointment Date:</strong> {bookingdata.appointment_date}
                    </div>
                    <div className="text-lg text-darkgrey1 mb-4">
                        <strong>Appointment Time:</strong> {bookingdata.time}
                    </div>

                    <div className="w-full mt-5">
                        {data.acfb_booking_confirmation&&
                            <div className="mt-6 terms-content">
                                <div dangerouslySetInnerHTML={{ __html: data.acfb_booking_confirmation }}></div>
                            </div>
                        }
                    </div>

                    <div className="mt-6">
                        <Link to="/make-booking" className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black font-medium inline-block rounded-md shadow-none text-white capitalize text-sm px-8 py-3">Make Another Booking</Link>
                    </div>

                </div>
            
            </div>
        }
        </main>
    );
}
export default BookingConfirmation;