import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";

import "./Header.css";

import LogoMain from '../../images/acfb-logo.png';
import { Link, useNavigate } from 'react-router-dom';

import AuthUser from '../../helpers/AuthUser';

function HeaderBlank() {

  const {user,logout} = AuthUser();
  const navigate = useNavigate();

  const role = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  if(token != null){
   var isRole = role.user_type_primary;
   var isRole2 = role.user_type_secondary;
  }
  
  const logoutUser=()=>{
    if(token !== undefined){
      logout();
    }
  }

  const [openNav, setOpenNav] = useState(false);
  
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
 
  return (
  	<header>
  		<div className="header_bottom relative bg-white">
		    <Navbar className="mx-auto bg-transparent backdrop-saturate-0 backdrop-blur-none relative z-10 shadow-none border-none pt-8 pb-12 px-4 text-dark">
		      <div className="container mx-auto flex flex-col items-center">
		        <div className="logo">
             <Link to="/">
  			        <img
  			          src={LogoMain}
  			          alt="acfb-logo"
  			        />
              </Link>
		        </div>

            <hr className="mt-4 mb-4 w-1/3" />

		        <div className="text-center">
		        	<div className="phone-no mb-2 text-blue-900">
		        		<Link to="tel:1300 002 232" className="text-2xl md:text-4xl"><b>1300</b> 002 232</Link>
		        	</div>
		        </div>

            { isRole === 'client' || isRole2 === 'client' ? (
                  <Link to="/" onClick={logoutUser}>
                       <span>
                           Logout
                       </span>
                   </Link>
              ) : (
                ""
              )
            }

		      </div>
		    </Navbar>
	    </div>
    </header>
  );
}


export default HeaderBlank;