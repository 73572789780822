// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/header-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-nav span {
    top: 0;
    right: 0;
}

.header_bottom::after {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    content: "";
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: -40px;
    background-size: 100%;
    background-repeat: no-repeat;
}
.header_bottom nav {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
}
@media (max-width: 1000px){
    .header_bottom::after {
        background-size: 1300px;
        background-position: center center;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/HeaderBlank/Header.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,QAAQ;AACZ;;AAEA;IACI,yDAAiD;IACjD,WAAW;IACX,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,4BAA4B;AAChC;AACA;IACI,6BAAqB;YAArB,qBAAqB;AACzB;AACA;IACI;QACI,uBAAuB;QACvB,kCAAkC;IACtC;AACJ","sourcesContent":[".mobile-nav span {\n    top: 0;\n    right: 0;\n}\n\n.header_bottom::after {\n    background-image: url(../../images/header-bg.png);\n    content: \"\";\n    width: 100%;\n    height: 110px;\n    position: absolute;\n    bottom: -40px;\n    background-size: 100%;\n    background-repeat: no-repeat;\n}\n.header_bottom nav {\n    backdrop-filter: none;\n}\n@media (max-width: 1000px){\n    .header_bottom::after {\n        background-size: 1300px;\n        background-position: center center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
