import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, className, isSearchable, isDisabled, isMulti, placeholder,defaultValue }) => {
    return (
        <div className={className}>
            <Select
                isDisabled={isDisabled}
                isMulti={isMulti}
                placeholder={placeholder}
                isSearchable={isSearchable}
                defaultValue={defaultValue}
                className="select-inner-border" 
                onChange={value => { onChange(value) }} 
                options={options} 
                />
        </div>

    )
}