import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/LoginForm.css';
import AuthUser from '../helpers/AuthUser';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Typography, Input, Button } from "@material-tailwind/react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-0" : "" } transition-transform`}>Read more…</span>
  );
}

function ResetPassword(){

    const {http} = AuthUser();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
     const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');
  
 
     const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
          const response = await http.post(`auth/password/reset`, {
            token,
            email,
            password,
            password_confirmation: confirmPassword,
          });
          toast.success(response.data.message);
          console.log('sucess')
          navigate('/');
        } catch (error) {
            console.log('catch')
           toast.error('Unable to reset password. Please make sure password must contain at least one uppercase letter, one lowercase letter, and one number. ');
        }
      };

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            <div className="max-w-screen-lg mx-auto">

                <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
                      IMPORTANT – READ THE FOLLOWING STATEMENT BEFORE BOOKING YOUR APPOINTMENT
                    </AccordionHeader>
                    <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
                      If you are unwell, have flu-like symptoms, have been in close contact with someone with COVID-19 or have COVID-19, DO NOT make an appointment. If you are feeling unwell prior to your appointment, please call ACFB and cancel your booking. Please provide ACFB with as much notice as possible if you need to cancel your clinic due to sickness.
                    </AccordionBody>
                </Accordion>


                <Typography variant="h2" className="text-center mb-5 font-normal hidden">Welcome to ACFB’s Student Massage Clinic</Typography>
                
                <div className="block md:flex md:flex-row md:gap-6">
                    <div className="w-full md:w-3/5">
                        <div className="bg-white p-8 md:p-10 rounded-xl reset-link">
                            <div className="flex items-center mb-5">
                                <Typography variant="h3" className="font-bold text-2xl">Reset Password</Typography>
                                <Typography variant="paragraph" className="font-normal text-sm ml-auto">* Required fields</Typography>
                            </div>
                            <form onSubmit={handlePasswordReset}>
                                <div className="form-input mb-4">
                                    <Input
                                      type="password"
                                      value={password}
                                      className="border-black placeholder-shown:border-black"
                                      onChange={(e) => setPassword(e.target.value)}
                                      labelProps={{
                                          className: "hidden" 
                                        }}
                                      placeholder="New Password"
                                    />
                                </div>
                                <div className="form-input mb-4">
                                    <Input
                                      type="password"
                                      value={confirmPassword}
                                      className="border-black placeholder-shown:border-black"
                                      onChange={(e) => setConfirmPassword(e.target.value)}
                                      labelProps={{
                                          className: "hidden" 
                                        }}
                                      placeholder="Confirm Password"
                                    />
                                </div>
                                <Typography variant="paragraph" className="font-normal text-sm ml-auto mb-4">Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number.</Typography>
                                <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none text-white capitalize text-sm px-8 py-3" type="submit">Reset Password</Button>
                            </form>
                        </div>
                   </div>
                </div>
                <Toaster/>
            </div>
        </main>
    );
}

export default ResetPassword;