import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import {
  Input,
  Button
} from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import {getAdminUrl} from '../../../helpers/Utils';
import TopNav from '../../../Components/TopNav/TopNav';
import MultiSelect from '../../../helpers/CustomMultiSelect';

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import DataTable from 'react-data-table-component';
import {Morningoptions, AfternoonOptions, EveningOptions} from '../../../helpers/appointmentOptions';

const topNav = [
    {
        name: "View Allocations",
        url: getAdminUrl('view-allocation')
    },
    {
        name: "View Custom Allocations",
        url: getAdminUrl('view-custom-allocation')
    },
    {
        name: "Add Custom Allocation",
        url: getAdminUrl('view-custom-allocation'),
        button: [
            {
                name: "Add Custom Allocation"
            }
        ]
    }
] 

function CustomAllocation() {

    const {http} = AuthUser();
    const [loading, setLoading] = useState(false);
    const [customallocation, setCustomAllocation] = useState([]);
    const [service, setService] = useState([]);
    const [error, setErrors] = useState();
    const [selectedDate, setDate] = useState({ 
        startDate: null,
        endDate: null 
      }); 

    useEffect(() => {
        ServiceGet()
    }, [])

    const ServiceGet = () => {
        http.get('auth/services').then((resp)=>{
            setService(resp.data.data);
        });  
    }

    const handleValueChange = (newValue) => {
        setDate(newValue); 
        setFieldValue("date", newValue);
    } 

    const soptions = service.map(d => ({
        "value" : d.id, 
        "label" : d.title
    }))


    const handlemorningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('morning_slots', temp.length > 0 ? temp : [])
    }

    const handleafternoontimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('afternoon_slots', temp.length > 0 ? temp : [])
    }
    const handleeveningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('evening_slots', temp.length > 0 ? temp : [])
    }

    const handleMassageChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('massage_type', temp.length > 0 ? temp : [])
    }
    useEffect(() => {
      setLoading(true);
      http.get('custom-allocation')
        .then((resp) => {
          setCustomAllocation(resp.data.data);
          setLoading(false);
        })
        .catch(err => {
          console.log('err', err.response.data.message);
          setLoading(false);
        });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    const handleRowSelected = useCallback((state) => {
        setSelectedRows(state.selectedRows);
    }, []);

      const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ?`)) {
          try {
            const idsToDelete = selectedRows.map(row => row.id); 
            await http.post('/custom-allocation/delete', { ids: idsToDelete });
            setToggleCleared(!toggleCleared);
            setCustomAllocation(customallocation.filter(row => !selectedRows.includes(row)));
          } catch (error) {
            console.error('Error deleting rows: ', error);
          }
        }
      };

    const contextActions = useMemo(() => {
        return (<>
          <Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
            Delete
          </Button>
        </>);
    }, [handleDelete]);

    const { handleSubmit, handleBlur, errors, touched, getFieldProps,handleChange, setFieldValue } = useFormik({
        initialValues: {
            date: '',
            massage_type: [],
            morning_slots: [],
            afternoon_slots: [],
            evening_slots: [],
            limit: null,
        },
        validationSchema: Yup.object({
            massage_type: Yup.array().min(1, 'At least one massage type is required'),
        }),
        onSubmit: (values) => {
            http.post('custom-allocation',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully toasted!');
                setErrors();
            }).catch(err => {
                setErrors(err.response.data.message);
                console.log('error',err.response.data.message);
           });
        },
    });

  
    const columns = [
        {
            name: 'Days',
            selector: row =>   {
                return(
                 <div class="times text-wrap">
                    {moment(row.startDate).format('DD/MM/YYYY')} - {moment(row.endDate).format('DD/MM/YYYY')}
                 </div>
                 )
               }
        },
        {
            name: 'Morning Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.morning_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Afternoon Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.afternoon_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Evening Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.evening_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Massage Type',
            selector: row =>
            { 
             return(
              <div class="times text-wrap">
                { JSON.parse(row.massage_type).map((row,index)=> (
                  <span> {row.label} </span>
                ))}
              </div>
              )
            }
         },
        {
            name: 'Limit',
            selector: row => row.limit,
        },
        {
            name:"Action",
            cell: (row) => (
                <>
                    <Link to={"/admin/view-custom-allocation/edit/" + row.id } className='btn btn-primary table-btn-link'>Update Day</Link>
                </>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    return (
        <>
         <div className="page-header">

            <TopNav data={topNav} />

            <div className="white-box mb-2">
                <div className="master-allocation-head">

                    <div className="flex">
                        <h2 className="mb-3">Update Allocations</h2>
                    </div>
                  
                    <form onSubmit={handleSubmit}>

                   
                        <div className="flex gap-5 custom-allocation-row">
                            <div className="form-select days-input w-4/12 relative">
                                <label htmlFor="rol">Day(s)</label>
                                 <Datepicker 
                                        value={selectedDate} 
                                        useRange={false} 
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        selectsRange
                                        onChange={handleValueChange} 
                                        showShortcuts={false} 
                                        displayFormat={"DD/MM/YYYY"} 
                                        onBlur={handleBlur}
                                    /> 
                                  {(touched.date && errors.date) && <span className="error">{errors.date}</span>}
                             </div>

                            <div className="form-select w-4/12 time-slot relative">
                                <label>Morning Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('morning_slots')}
                                    className='input'
                                    onChange={handlemorningtimeChange}
                                    placeholder={"Choose..."}
                                    options={Morningoptions}
                                 />

                                         
                            </div>

                            <div className="form-select w-4/12 time-slot relative">
                                <label>Afternoon Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('afternoon_slots')}
                                    className='input'
                                    onChange={handleafternoontimeChange}
                                    placeholder={"Choose..."}
                                    options={AfternoonOptions}
                                    onBlur={handleBlur}
                                 />
                            </div>

                            <div className="form-select w-4/12 time-slot relative">
                                <label>Evening Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('evening_slots')}
                                    className='input'
                                    onChange={handleeveningtimeChange}
                                    placeholder={"Choose..."}
                                    options={EveningOptions}
                                    onBlur={handleBlur}
                                 />
                            </div>

                            <div className="form-select w-4/12 message-type relative">
                                <label>Massage Type</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('massage_type')}
                                    className='input'
                                    onChange={handleMassageChange}
                                    placeholder={"Select..."}
                                    options={soptions}
                                    onBlur={handleBlur}
                                 />
                           {(touched.massage_type && errors.massage_type) && <span className="error">{errors.massage_type}</span>}
                            </div>

                            <div className="w-4/12 limit-input">
                                <div className="form-input">
                                    <Input
                                        autoComplete="limit"
                                        name="limit"
                                        variant="static"
                                        fullWidth
                                        type="number"
                                        id="limit"
                                        placeholder="0" 
                                        min="0" 
                                        max="500"
                                        label="Limit"
                                        className="border-black placeholder-shown:border-black"
                                        {...getFieldProps('limit')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                   {(touched.limit && errors.limit) && <span className="error">{errors.limit}</span>}

                                </div>
                            </div>
                        </div>

                        <div className="bottom-button-group flex flex-wrap justify-end">
                            <div className="col">
                                <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Add Custom Allocation</button>
                            </div>
                        </div>
                      {error && <div className="error color-red"> {error} </div> }
                    </form>
                  <Toaster />
                </div>
            </div>

            <DataTable
                title="Custom Allocations" 
                columns={columns}
                data={customallocation}
                className="table-title-style"
                selectableRows
                contextActions={contextActions}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                progressPending={loading}
                contextual
            />

          </div>
        </>
    )
}
export default CustomAllocation;