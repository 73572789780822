import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';

export default function AuthUser(){
    const navigate = useNavigate();

    const getToken = () =>{
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = localStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const getSpecialNeedOptions  = (params) => {
        return http.get(`auth/needs`, { params });
    };

    const getStudents  = (params) => {
        return http.get(`auth/students`, { params });
    };

    const getClients  = (params) => {
        return http.get(`auth/clients`, { params });
    };
    
    const getAvailability  = (params) => {
        return http.get(`auth/availability`, { params });
    };

    const getpassword  = (params) => {
       return http.get(`auth/showpwd`, { params });
    };

    const Services = (params) => {
        return http.get(`auth/services`, { params });
    }

    const StudentRecords = (params) => {
        return http.get(`student-booking-records`, { params });
    }

    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
        localStorage.setItem('token',JSON.stringify(token));
        localStorage.setItem('user',JSON.stringify(user));
        setToken(token);
        setUser(user);
    }

    const logout = () => {
        localStorage.clear();
        navigate('/');
    }

    useEffect(()=>{
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                localStorage.clear();
                navigate('/');
                toast.error("Session timed out, please login again");
            } else {
                console.log('logout time',new Date(decodedToken.exp * 1000).toLocaleTimeString());
            }
        }
    },[]);

    const http = axios.create({
        baseURL:process.env.REACT_APP_API_URL,
        headers:{
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Headers': 'Content-Type, Authorization',
           'Access-Control-Allow-Methods': "GET,HEAD,POST,OPTIONS",
           "Content-Type": "application/json",
           "Access-Control-Max-Age": "86400",
           Authorization : `Bearer ${token},`
       }
   });
    const httpwithoutAuth = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    });
    
    return {
        setToken:saveToken,
        token,
        user,
        getpassword,
        getSpecialNeedOptions,
        getToken,
        http,
        httpwithoutAuth,
        logout,
        getStudents,
        getClients,
        StudentRecords,
        getAvailability,
        Services,
    }
    
}