import { useState, useEffect } from "react";
import { 
    Textarea, 
    Button } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast from 'react-hot-toast';
import CustomSelect from '../../../helpers/CustomSelect';

function SendSMS() {
    const {http}=AuthUser();
    const [users, setUsers] = useState([]);
    const usersOptions = users.map(d => ({
        "value" : d.id,
        "label" : d.first_name + ' ' + d.last_name  + ' (' + d.phone_number + ') '
    }))
    useEffect(() => {
        http.get(`/users/search`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsers]);
    const navigate = useNavigate();
    const { handleSubmit, errors, touched, getFieldProps,handleChange, setFieldValue } = useFormik({
        initialValues: {
            phone: '',
            message: '',
        },
        validationSchema: Yup.object({
             phone: Yup.string().required('Required'),
             message: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            values = { ...values}
            http.post('/sms',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('SMS Sent Successfully');
                navigate("/admin/sms");
            }).catch(err => {
                toast.error("an error occured!");
            });
        },
    });

    return (
        <>
            <div className="white-box">
                <div className="heading mb-3">
                    <h2>Send SMS</h2>
                </div>
                <form className="custom-form" onSubmit={handleSubmit}>
                    <div className="form-select no-label mb-8">
                        <CustomSelect
                            className='input'
                            name="phone"
                            placeholder="Search User or enter mobile number"
                            isClearable={true}
                            options={usersOptions}
                            onChange={value => setFieldValue('phone', value?.value || null)}
                            value={getFieldProps('phone')?.value || null}
                        />
                        {(touched.phone && errors.phone) && <span className="error">{errors.phone}</span>}
                    </div>
                    <div className="form-textarea mb-8">
                        <Textarea
                            autoComplete="message"
                            name="message"
                            variant="static"
                            fullWidth
                            id="message"
                            className="border-black placeholder-shown:border-black"
                            {...getFieldProps('message')}
                            onChange={handleChange}
                            placeholder="Write your message here…"
                        />
                        {(touched.message && errors.message) && <span className="error">{errors.message}</span>}
                    </div>
                    <div className="flex flex-wrap justify-end w-full">
                        <div className="send-btn-outer" style={{ marginTop: 'auto'}}>
                            <Button className="btn-black" type="submit">Send</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default SendSMS;
