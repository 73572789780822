import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Button
} from "@material-tailwind/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark }
from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem} from 'react-pro-sidebar';
import AuthUser from '../helpers/AuthUser';
import { Link, useNavigate } from "react-router-dom";
import {getStudentUrl} from '../helpers/Utils';
import MultilevelMenu from '../Components/Sidebar/MultilevelMenu';
import LogoImg from '../images/logo.png';
import MobileNavbar from '../Components/MobileNav/MobileNavbar';


const menuData = [
    {
      name: "Enter Availability",
      url: getStudentUrl('enter-availability'),
      roles: ['student'],
    },
    {
      name: "Upcoming Bookings",
      url: getStudentUrl('upcoming-bookings'),
      roles: ['student'],
    },
    {
      name: "Past Bookings",
      url: getStudentUrl('past-bookings'),
      roles: ['student'],
    }
]

const MobileHeaderStudent = () => {
  const [openNav, setOpenNav] = React.useState(false);

  const navigate = useNavigate();
  const {getToken, token, logout, user} = AuthUser();
  const userRole = 'student';
  const logoutUser=()=>{
    if(token !== undefined){
      logout();
    }
  }
  useEffect(() => {
    if(!getToken()){
      navigate('/');
    }
  });

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const handleNavbarToggle = () => {
    setOpenNav(!openNav);
  };

  const handleMenuItemClick = (url) => {
    setOpenNav(false);
    navigate(url);
  };

  return (
    <div className="flex z-10 relative bg-white px-4 py-4">
        <div className="logo">
          <img src={LogoImg} />
        </div>

        <div className="ml-auto flex items-center">
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-lg hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <FontAwesomeIcon icon={faXmark} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </IconButton>
        </div>

        {openNav &&
        <div className="absolute shadow-lg left-0 bg-white border-t-2 top-full">
          <div className="flex flex-wrap py-4 w-full items-center gap-2 lg:hidden">
            
            <div className="w-full">
              <MobileNavbar menuData={menuData} onMenuItemClick={handleMenuItemClick} />
            </div>

            <Menu className="mt-auto mb-2">
                {user.user_type_secondary != null &&
                  <MenuItem icon={<i className="fas fa-user"></i>}>
                      <Link to="/make-booking">
                         <span>Switch to Client</span>
                     </Link>
                   </MenuItem>
                }
                <MenuItem icon={<i className="fas fa-user"></i>}>
                  <Link to="/" onClick={logoutUser}>
                     <span>
                         Logout
                     </span>
                 </Link>
               </MenuItem>
            </Menu>

          </div>
        </div>
      }


    </div>
  );
};

export default MobileHeaderStudent;
