import { useState, useEffect } from 'react';
import './App.css';
import AuthUser from "./helpers/AuthUser";
import AdminLayout from './Layouts/AdminLayout';
import PublicLayout from './Layouts/PublicLayout';
import StudentLayout from './Layouts/StudentLayout';
import ClinicLayout from './Layouts/ClinicLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

function App() {
  const { getToken } = AuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const history = useLocation();
  const getUrlPart = history.pathname.split('/');
  let isAdminUrl = false;
  if (getUrlPart.indexOf("admin") > -1) {
    isAdminUrl = true;
  }

  const isOnAdminPage = window.location.pathname.includes('admin');
  const isOnStudentPage = window.location.pathname.includes('student');
  const pathSegments = window.location.pathname.split('/');
  const isPathGreaterThanOne = pathSegments.length > 2;

  useEffect(() => {
    setIsLoading(true);
    if (!getToken() && !<PublicLayout />) {
      navigate('/');
    } else if (!getToken() && isPathGreaterThanOne && isOnAdminPage) {
      navigate('/');
    } else if (!getToken() && isPathGreaterThanOne && isOnStudentPage) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const role = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  let isRolePrimary = null;
  let isRoleSecondary = null;
  if (token != null) {
    isRolePrimary = role.user_type_primary;
    isRoleSecondary = role.user_type_secondary;
  }
  useEffect(() => {
    const currentUrl = window.location.pathname;

    if (isAdminUrl && currentUrl === '/admin' && getToken()) {
       navigate('/admin/dashboard'); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminUrl, navigate]);
return (
  <div>
    {isLoading ? (
      <>
        {((isRolePrimary === 'admin' && isAdminUrl === true) || (isRolePrimary === 'editor' && isAdminUrl === true)) && getToken() ? (
          <div className="Admin">
            {console.log('isRolePrimaryadmin', isRolePrimary, 'isAdminUrl', isAdminUrl)}
            <AdminLayout />
          </div>
        ) : (
          <>
            {((isRolePrimary === 'student' && isRoleSecondary === 'client') && getToken()) && (
              <>
                {isOnStudentPage && (
                  <div className="Student">
                    <StudentLayout />
                  </div>
                )}

                {!isOnStudentPage && (
                  <div className="Client">
                    <ClinicLayout />
                  </div>
                )}
              </>
            )}

            {(isRolePrimary === 'student' && isRoleSecondary === 'none' && getToken()) && (
              <>
                {isOnStudentPage && (
                  <div className="Student">
                    <StudentLayout />
                  </div>
                )}
              </>
            )}

            {(isRolePrimary === 'student' && getToken() && !isRoleSecondary && isOnStudentPage) && (
              <div className="Student">
                <StudentLayout />
              </div>
            )}

            {(isRolePrimary === 'client' && getToken() && !isRoleSecondary && !isOnStudentPage) && (
              <div className="Client">
                <ClinicLayout />
              </div>
            )}

            {(isRoleSecondary === 'student' && getToken() && isOnStudentPage) && (
              <div className="Student">
                <StudentLayout />
              </div>
            )}

            {(!isRolePrimary && !isRoleSecondary) && (
              <div className="Public">
                <PublicLayout />
              </div>
            )}

           {/* 
            Commented out the following lines:
            {((isRolePrimary === 'admin' || isRolePrimary === 'editor') && 
              (window.location.pathname === '/' || 
              (isRolePrimary === 'student' && window.location.pathname === '/')) && getToken()) && (
                <div className="Public">
                  <PublicLayout />
                </div>
              )}
          */}
            {((isRolePrimary === 'admin' || isRolePrimary === 'editor') || (isRolePrimary === 'student'  && window.location.pathname === '/') 
            && getToken()) && (
              <div className="Public">
                <PublicLayout />
              </div>
            )}
          </>
        )}
      </>
    ) : (
      <div>Loading...</div>
    )}

    <Toaster />
  </div>
  );
}

export default App;
