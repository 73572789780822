import React from 'react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button
} from "@material-tailwind/react";

import ViewAll from '../Components/Sms/ViewAll';
import { Link } from 'react-router-dom';
import {getAdminUrl} from "../../helpers/Utils";
import TopNav from '../../Components/TopNav/TopNav';

const topNav = [
    {
        name: "View All SMS",
        url: getAdminUrl('sms')
    },
    {
        name: "Send New SMS",
        url: getAdminUrl('sms/new-sms'),
        button: [
            {
                name: "Send New SMS"
            }
        ]
    }
]

function Sms() {

    return (
        <>
             <TopNav data={topNav} />

            <ViewAll />
        </>        
    )
}
export default Sms;