import React, {useState, useEffect} from "react";
import Datepicker from "react-tailwindcss-datepicker";
import {
  Input
} from "@material-tailwind/react";
import {useNavigate, useParams } from 'react-router-dom';
import MultiSelect from '../../../helpers/CustomMultiSelect';
import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import {getAdminUrl} from '../../../helpers/Utils';
import TopNav from '../../../Components/TopNav/TopNav';
import {Morningoptions, AfternoonOptions, EveningOptions} from '../../../helpers/appointmentOptions';

const topNav = [
    {
        name: "View Allocations",
        url: getAdminUrl('view-allocation')
    },
    {
        name: "View Custom Allocations",
        url: getAdminUrl('view-custom-allocation')
    },
    {
        name: "Add Custom Allocation",
        url: getAdminUrl('view-custom-allocation'),
        button: [
            {
                name: "Add Custom Allocation"
            }
        ]
    }
] 

function EditCustomAllocation(props){
    const navigate = useNavigate();
    const {id} = useParams();
    const {http} = AuthUser();
    const [data, setData] = useState([]);
    const [service, setService] = useState([]);
    // const [massage, setMassage] = useState('');
    const [selectedDate, setDate] = useState({ 
        startDate: null,
        endDate: null 
    }); 

    const [dataLimit, setLimit] = useState([]);
    const handlemorningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('morning_slots', temp.length > 0 ? temp : [])
    }

    const handleafternoontimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('afternoon_slots', temp.length > 0 ? temp : [])
    }
    const handleeveningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('evening_slots', temp.length > 0 ? temp : [])
    }

    const handleMassageChange = (selectedItems) => {
        const massage = selectedItems.map(item => item);
        // setMassage(massage.length > 0 ? massage : [])
        setFieldValue('massage_type', massage.length > 0 ? massage : [])
    }

    const handleChange = (newValue) => {
        setDate(newValue); 
        setFieldValue('date',newValue)
    }

    useEffect(() => {
        async function fetchData() {
            try {
              const response =  await http.get(`custom-allocation/${id}`);
              const data = await response.data.data;
              setData(data); 
              setLimit(data); 
              setDate({
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate)
              });
              const fields = ['id','date','startDate','endDate', 'morning_slots', 'afternoon_slots', 'evening_slots', 'massage_type', 'limit'];
              fields.forEach(field => setFieldValue(field, data[field], false));
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      const ServiceGet = () => {
        http.get('auth/services').then((resp) => {
          setService(resp.data.data);
        });
      };

      ServiceGet();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const soptions = service.map(d => ({
        "value" : d.id, 
        "label" : d.title
    }))


    const { handleSubmit,errors, touched, setFieldValue } = useFormik({
      initialValues: {
        date: '',
        massage_type: [],
        morning_slots: [],
        afternoon_slots:[],
        evening_slots:[],
        limit: ''
      },
      validationSchema: Yup.object({
        // date: Yup.date()
        // .required('Date is required') // Set a custom error message for when the date is not provided
        // .min(new Date(), 'Date must be in the future'),
      }),
     onSubmit: (values) => {
        console.log(values);
        http.put(`custom-allocation/${id}`,JSON.stringify(values, null, 2)).then((result)=>{
        if (result.data['status'] === true) {
            toast.success("Allocation Updated Successfully!");
             navigate("/admin/view-custom-allocation");
            }
        }).catch(error => {
          // console.log('error',error.response.data.message);
             toast.error("Day(s) Field Is Required!");
        });
      }
  });
 
 
    return (
        <>
        <div className="page-header">
          
          <TopNav data={topNav} />

            <div className="white-box mb-2">
                <div className="master-allocation-head">
                    <div className="flex">
                        <h2 className="mb-3">Update Custom Allocations</h2>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex gap-5 custom-allocation-row">
                            <div className="form-select days-input w-4/12 relative">
                                <label htmlFor="rol">Day(s)</label>
                                <div class="date-custom-outers">
                                    <Datepicker 
                                        value={selectedDate} 
                                        useRange={false} 
                                        startDate={selectedDate.startDate}
                                        endDate={selectedDate.endDate}
                                        selectsRange
                                        onChange={handleChange} 
                                        showShortcuts={false} 
                                        displayFormat={"DD/MM/YYYY"} 
                                    /> 
                                    {(touched.date && errors.date) && <span className="error">{errors.date}</span>}
                                </div>

                           </div>
                         
                            <div className="form-select w-4/12 time-slot relative">
                                <label> Morning Slot(s) </label>
                                {data.morning_slots && Morningoptions.length>0 && 
                                <MultiSelect
                                      isSearchable={false}
                                      isMulti={true}
                                      className='input'
                                      onChange={handlemorningtimeChange}
                                      defaultValue={data.morning_slots}
                                      value={data.morning_slots}
                                      options={Morningoptions}
                                  />
                                }
                            </div> 
                             
                            <div className="form-select w-4/12 time-slot relative">
                                <label>Afternoon Slot(s) </label>
                                {data.afternoon_slots && AfternoonOptions.length>0 && 
                                  <MultiSelect
                                      isSearchable={false}
                                      isMulti={true}
                                      className='input'
                                      onChange={handleafternoontimeChange}
                                      defaultValue={data.afternoon_slots}
                                      value={data.afternoon_slots}
                                      options={AfternoonOptions}
                                  />
                                }
                            </div> 

                            <div className="form-select w-4/12 time-slot relative">
                                <label> Evening Slot(s) </label>
                                {data.evening_slots && EveningOptions.length>0 &&
                                  <MultiSelect
                                      isSearchable={false}
                                      isMulti={true}
                                      className='input'
                                      onChange={handleeveningtimeChange}
                                      defaultValue={data.evening_slots}
                                      value={data.evening_slots}
                                      options={EveningOptions}
                                  />
                                 }
                               </div>  

                            <div className="form-select w-4/12 message-type relative">
                                <label>Massage Type</label>
                              
                                  {data.massage_type && data.massage_type.length>0 && 
                                  <MultiSelect
                                      isSearchable={false}
                                      isMulti={true}
                                      className='input'
                                      onChange={handleMassageChange}
                                      defaultValue={data.massage_type}
                                      value={data.massage_type}
                                      options={soptions}
                                  />
                                }
                                
                            </div>

                            <div className="w-4/12 limit-input">
                                <div className="form-input">
                                    <Input
                                        autoComplete="limit"
                                        name="limit"
                                        variant="static"
                                        fullwidth="true"
                                        type="number"
                                        id="limit"
                                        placeholder="0" 
                                        min="0" 
                                        max="100"
                                        label="Limit"
                                        className="border-black placeholder-shown:border-black"
                                        value={dataLimit.limit}
                                        onChange={e => {
                                          setLimit({});
                                          setFieldValue(e.target.name,e.target.value);
                                         }}
                                    />
                                   {(touched.limit && errors.limit) && <span className="error">{errors.limit}</span>}
                                 
                                </div>
                            </div>
                        </div>

                        <div className="bottom-button-group flex flex-wrap justify-end">
                            <div className="col">
                                <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Update Day</button>
                            </div>
                        </div>
                    </form>
                    <Toaster />
                </div>
            </div>
         </div>
        </>
    )
}
export default EditCustomAllocation;