import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/LoginForm.css';
import { useFormik } from 'formik';
import FromHelper from '../helpers/FromHelper';
import AuthUser from '../helpers/AuthUser';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Typography, Input, Button } from "@material-tailwind/react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import ForgotPasswordPage from '../Pages/ForgotPasswordPage';

function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-0" : "" } transition-transform`}>Read more…</span>
  );
}

function ForgotPassword(){

    const {http} = AuthUser();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const { token } = useParams();
    const [hasToken, setHasToken] = useState(token ? true : false);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
       if (!email) {
        setError('Email is required');
        return;
      }
      try {
        const response = await http.post('auth/forgot-password', { email });
        setSuccess(true);
      } catch (error) {
        setError('Failed to send reset link. Please check your email and try again.');
      }
    };

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            <div className="max-w-screen-lg mx-auto">

                <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
                      IMPORTANT – READ THE FOLLOWING STATEMENT BEFORE BOOKING YOUR APPOINTMENT
                    </AccordionHeader>
                    <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
                      If you are unwell, have flu-like symptoms, have been in close contact with someone with COVID-19 or have COVID-19, DO NOT make an appointment. If you are feeling unwell prior to your appointment, please call ACFB and cancel your booking. Please provide ACFB with as much notice as possible if you need to cancel your clinic due to sickness.
                    </AccordionBody>
                </Accordion>


                <Typography variant="h2" className="text-center mb-5 font-normal hidden">Welcome to ACFB’s Student Massage Clinic</Typography>
                
                <div className="flex flex-row gap-6 justify-center">
                
                    <div className="w-full md:w-3/5">
                          <div className="bg-white p-8 md:p-10 rounded-xl reset-link">
                            <div className="flex items-center mb-5">
                                <Typography variant="h3" className="font-bold text-2xl">Forgot Password</Typography>
                                <Typography variant="paragraph" className="font-normal text-sm ml-auto">* Required fields</Typography>
                            </div>
                            {success ? (
                                <p>A password reset link has been sent to your email.</p>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                  <div className="form-input mb-4">
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        className="border-black placeholder-shown:border-black"
                                        labelProps={{
                                          className: "hidden" 
                                        }}
                                        onChange={handleEmailChange}
                                    />
                                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                                  </div>
                                  <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none text-white capitalize text-sm px-8 py-3" type="submit">Send Reset Link</Button>
                                </form>
                            )}
                            </div>
                         </div>
                   </div>
                <Toaster/>
            </div>
        </main>
    );
}

export default ForgotPassword;