import { useEffect, useState } from 'react';
import AuthUser from '../AuthUser';

function useBookingData() {
  const [data, setData] = useState(null);
  const {http}=AuthUser();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await http.get('booking/acfbcontent/1');
        const fetchedData = response.data.data;
        setData(fetchedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return data;
}

export default useBookingData;
