import { useState,useEffect } from "react";
import { 
    Textarea, 
    Button,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast from 'react-hot-toast';
function Reminders() {
    const {http}=AuthUser();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: {
            message_client: '',
            student_reminder: ''
        },
        validationSchema: Yup.object({
            //  message_client: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            http.post('sms/reminders',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully Updated!');
                navigate("/admin/reminders");
            });
        },
    });
    useEffect(() => {
        async function fetchData() {
          try {
              setLoading(true);
              const response =  await http.get(`sms/reminders/1`);
              const data = await response.data.data;
              setLoading(false);
              setData(data);
              const fields = ['id','student_reminder','client_reminder'];
              fields.forEach(field => setFieldValue(field, data[field], false));
          } catch (error) {
            console.error(error.response.message);
          }
      }
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      if (!data) {
        return <div>Loading...</div>;
      }
    return (
        <>
            <div className="white-box">
                <div className="heading mb-3">
                    <h2 className="mb-2">Automated Appointment Reminder</h2>
                    <p>This is a 24 hour automated reminder SMS that will be to all students & clients for appointments.</p>
                </div>
                <form className="custom-form" onSubmit={handleSubmit}>
                    <div className="flex gap-10">
                        <div className="w-6/12">
                            <div className="form-textarea mb-8 relative">
                                <label className="block mb-4">Client (Auto) SMS</label>
                                <Textarea
                                    autoComplete="off"
                                    name="client_reminder"
                                    variant="static"
                                    fullWidth
                                    id="message_client"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.client_reminder} 
                                    onChange={e => {
                                    setData({});
                                    setFieldValue('client_reminder',e.target.value);
                                    }}
                                />
                                {(touched.client_reminder && errors.client_reminder) && <span className="error">{errors.client_reminder}</span>}
                            </div>
                        </div>
                        <div className="w-6/12">
                            <div className="form-textarea mb-8 relative">
                                <label className="block mb-4">Student (Auto) SMS</label>
                                <Textarea
                                    autoComplete="off"
                                    name="student_reminder"
                                    variant="static"
                                    fullWidth
                                    id="message_student"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.student_reminder} 
                                    onChange={e => {
                                    setData({});
                                    setFieldValue('student_reminder',e.target.value);
                                    }}
                                />
                                {(touched.student_reminder && errors.student_reminder) && <span className="error">{errors.student_reminder}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-end w-full">
                        <div className="send-btn-outer" style={{ marginTop: 'auto'}}>
                            <Button className="btn-black" type="submit">Update Reminders</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Reminders;