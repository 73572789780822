import PublicRoutes from "../Routes/Public/PublicRoutes";
import {useLocation} from 'react-router-dom'
import HeaderBlank from '../Components/HeaderBlank/HeaderBlank';
import FooterBlank from '../Components/FooterBlank/FooterBlank';

export default function PublicLayout(){
    let location = useLocation();
    return (
        <div className="wrapper flex flex-col min-h-screen">
            {location.pathname === '/admin' ? "" : <HeaderBlank/>}
                <PublicRoutes/>
            {location.pathname === '/admin' ? "" : <FooterBlank/>}
        </div>
    );
}