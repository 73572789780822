import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthUser from '../../helpers/AuthUser';
import DataTable from 'react-data-table-component';
import {getStudentUrl} from "../../helpers/Utils";
import TopNav from '../../Components/TopNav/TopNav';

const topNav = [
    {
        name: "Upcoming Bookings",
        url: getStudentUrl('upcoming-bookings')
    },
    {
        name: "Past Bookings",
        url: getStudentUrl('past-bookings')
    }
]

const columns = [
    {
        name: 'Massage Type',
        selector: row => row.massage_type,
    },
    {
        name: 'Date',
        selector: row => row.appointment_date,
    },
    {
        name: 'Time',
        selector: row => row.time,
    },
    {
        name: 'Status',
        selector: row => row.status,
    },
];

export default function UpcomingBookings(props){

    const {http} = AuthUser();
    const [loading, setLoading] = useState(false);
    const [upcoming, setUpcomingBookings] = useState([]);

    const GetupcomingBookings = async () => {
        setLoading(true);
        await http.get(`upcoming-bookings`).then((resp)=>{
            setUpcomingBookings(resp.data.data); 
            setLoading(false);
        }).catch(err => {
            setUpcomingBookings({});
        });  
    }
  
    useEffect(() => {
        GetupcomingBookings()
    }, [])

    return (
        <>
            <div>
                <div className="client-nav">
                    <TopNav data={topNav} />
                </div>

                <div className="white-box">
                    <div className="view-all-appointment">
                    <DataTable
                            columns={columns}
                            data={upcoming}
                            className="table-title-style"
                            selectableRows={false}
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
} 
