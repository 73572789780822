import React, {useState, useEffect} from "react";
import {
  Button,
  Input,
  Textarea
} from "@material-tailwind/react";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'moment';

function EditGift() {

    const {id} = useParams();
    const {http}=AuthUser();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [selectedDate, setDate] = useState(null)
    const [DeliveredDate, setDeliveredDate] = useState(null)
    const [show, setShow] = useState(false)

   const handleChangeDelivered = (selected) => {
      setData({});
      setDeliveredDate(selected.startDate); 
    }
  
   const handleChangeExpires = (selected) => {
       setDate(Moment(selected.startDate).format('DD/MM/YYYY'));
       setFieldValue('expires_at',Moment(selected.startDate).format('DD/MM/YYYY'))
    }
 
  const handleClose = (state) => {
    setShow(state)
  }


  useEffect(() => {
    async function fetchData() {
      try {
          const response =  await http.get(`voucher/${id}`);
          const data = await response.data.data;
          setData(data);
          setDate(Moment(data.expires_at).format('DD/MM/YYYY'));
          setDeliveredDate(Moment(data.delivered_at).format('DD/MM/YYYY'));
          const fields = ['id','code','current_balance','purchase_amount','sender_name','recipient_name','delivered_at','recipient_email','expires_at','message','notes'];
          fields.forEach(field => setFieldValue(field, data[field], false));
          console.log(data, 'datadata')
      } catch (error) {
        toast.error('Error with response code.');
      }
  }
  fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit,handleBlur, errors, touched, setFieldValue } = useFormik({
      initialValues: {
          expires_at: '',
          delivered_at: '',
      },
      validationSchema: Yup.object({
        expires_at: Yup.string().required('Required'),
        recipient_email: Yup.string().required('Required'),
      }),
      onSubmit: (values) => {
        const data = {...values,id:id,expires_at:selectedDate}
        
        http.put(`voucher/${id}`,JSON.stringify(data, null, 2)).then((result)=>{
            if (result.data['status'] === true) {
                toast.success("Voucher Updated Successfully!");
                navigate("/admin/gift");
            }
        }).catch(err => {
            toast.error("an error occured!");
        });
      }
  });

  return (
        <>
           <div className="white-box p-0">
         
              <div className="heading mb-3 pt-8 px-8">
                <h2>Edit Gift Card Details</h2>
              </div>

              <form onSubmit={handleSubmit} className="px-5 pb-8">
                <div className="flex mb-14 w-full">
                  <div className="form-input relative px-3 w-6/12">
                      <Input
                        autoComplete="code"
                        name="code"
                        variant="static"
                        fullwidth={"true"}
                        id="code"
                        disabled
                        className="border-black placeholder-shown:border-black"
                        value={data.code} 
                        onChange={e => {
                          setData({});
                          setFieldValue(e.target.name,e.target.value);
                         }}
                        onBlur={handleBlur}
                        />
                        {(touched.code && errors.code) && <span className="error">{errors.code}</span>}
                  </div>

                  <div className="form-input px-3 w-3/12">
                    <Input
                      autoComplete="amount"
                      variant="static"
                      fullWidth
                      id="amount"
                      name="purchase_amount"
                      label="Purchased Amount"
                      className="border-black placeholder-shown:border-black"
                      value={data.purchase_amount} 
                      onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                       }}
                      onBlur={handleBlur}
                      disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                      />
                      {(touched.purchase_amount && errors.purchase_amount) && <span className="error">{errors.purchase_amount}</span>}
                  </div>

                  <div className="form-input px-3 w-3/12">
                    <Input
                      autoComplete="current_balance"
                      variant="static"
                      fullWidth
                      id="balance"
                      name="current_balance"
                      label="Current Balance"
                      className="border-black placeholder-shown:border-black"
                      value={data.current_balance} 
                      onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                       }}
                      onBlur={handleBlur}
                      disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                      />
                      {(touched.current_balance && errors.current_balance) && <span className="error">{errors.current_balance}</span>}
                  </div>
                </div>

                <div className="flex mb-14 w-full">
                  <div className="form-input px-3 w-5/12">
                    <Input
                    autoComplete="sender"
                    variant="static"
                    fullWidth
                    id="sender"
                    name="sender_name"
                    label="Sender’s Name"
                    className="border-black placeholder-shown:border-black"
                    value={data.sender_name} 
                    onChange={e => {
                      setData({});
                      setFieldValue(e.target.name,e.target.value);
                     }}
                    onBlur={handleBlur}
                    disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                    />
                    {(touched.sender_name && errors.sender_name) && <span className="error">{errors.sender_name}</span>}
                  </div>

                  <div className="form-input px-3 w-4/12">
                    <Input
                      autoComplete="recipient"
                      variant="static"
                      fullWidth
                      id="recipient"
                      label="Recipient’s Name"
                      name="recipient_name"
                      placeholder="Emma"
                      className="border-black placeholder-shown:border-black"
                      value={data.recipient_name} 
                      onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                       }}
                      onBlur={handleBlur}
                      disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                    />
                    {(touched.recipient_name && errors.recipient_name) && <span className="error">{errors.recipient_name}</span>}
                  </div>

                  <div className="form-select px-3 days-input w-3/12 relative">
                      <label htmlFor="rol">Gift Card Created</label>

                      <div class="date-custom-outer">
                        <Datepicker 
                            dateFormat={"DD/MM/YYYY"}
                            placeholder={"Date"} 
                            useRange={false} 
                            asSingle={true} 
                            onChange={handleChangeDelivered}
                            show={show} 
                            setShow={handleClose}  
                            selected={DeliveredDate}
                            onFocus={() => setShow(true)} 
                            disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                        />
                        {errors.delivered_at ? <div className='error'>{errors.delivered_at}</div> : null}
                        <div className="date-test">
                             <input
                                type="text"
                                className="date-input-innner"
                                placeholder="Select Date"
                                value={data.delivered_at}
                                onFocus={() => setShow(true)}
                                disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                                readOnly
                            />
                        </div> 
                      </div>                                 
                  </div>

                </div>


                <div className="flex w-full">
                  <div className="form-textarea px-3 with-label w-5/12">
                    <Textarea 
                      style={{ height: '227px' }} 
                      variant="static" 
                      className="border-black placeholder-shown:border-black"
                      label="Message" 
                      name="message"
                      placeholder="Merry Christmas!" 
                      value={data.message}
                      onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                       }}
                      onBlur={handleBlur}
                      disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                    />
                  </div>

                  <div className="px-3 w-7/12">
                    <div className="flex ">
                      <div className="form-input recipient_email pl-0 px-3 w-7/12">
                        <Input
                        autoComplete="recipient_email"
                        variant="static"
                        fullWidth
                        id="recipient_email"
                        name="recipient_email"
                        label="Recipient’s Email"
                        placeholder="emma.cc@hotmail.com"
                        className="border-black placeholder-shown:border-black"
                        value={data.recipient_email}
                        onChange={e => {
                          setData({});
                          setFieldValue(e.target.name,e.target.value);
                         }}
                        onBlur={handleBlur}
                        disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                        />
                        {(touched.recipient_email && errors.recipient_email) && <span className="error">{errors.recipient_email}</span>}
                      </div>

                      <div className="form-select expiration_date days-input px-3 pr-0 w-5/12 relative">
                        <label htmlFor="rol">Expiration Date</label>
                        {console.log('selectedDate',selectedDate)}
                        <div class="date-custom-outer">
                        <div className="date-test">
                            <input
                                type="text"
                                className="date-input-innner"
                                placeholder="Select Date"
                                value={selectedDate}
                                onFocus={() => setShow(true)}
                                disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                                readOnly
                            />
                         </div>  
                          <Datepicker 
                              dateFormat={"DD/MM/YYYY"}
                              placeholder={"Date"} 
                              useRange={false} 
                              asSingle={true} 
                              value={selectedDate}
                              selected={selectedDate}
                              onChange={handleChangeExpires}
                              disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                          />
                          {errors.expires_at ? <div className='error'>{errors.expires_at}</div> : null}     
                           
                       </div>                 
                      </div>


                    </div>

                    <hr className="mt-8" style={{ borderColor: '#707070' }} />

                    <div className="form-textarea mt-6 with-label w-full">
                      <Textarea 
                        style={{ height: '86px' }} 
                        variant="static" 
                        className="border-black placeholder-shown:border-black"
                        label="Internal Notes" 
                        placeholder="" 
                        name="notes"
                        value={data.notes}
                        disabled={ data.appointment_order && data.is_used === true ?( 'disabled' ):('')}
                        onChange={e => {
                          setData({});
                          setFieldValue(e.target.name,e.target.value);
                         }}
                      />
                    </div>

                  </div>
                </div>

                <div className="bottom-button-group m-0 flex flex-wrap justify-end">
                  <div className="col">
                   {data.appointment_order && data.is_used === true ?(
                      <Link to="/admin/gift" className="bg-black hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none hidden lg:inline-block text-white hover:text-white capitalize text-base font-semibold px-6 py-3">View All Gift Cards</Link>
                      ):(<Button className="btn-black" type="submit">Update Gift Card Details</Button>)}  
                  </div>
                </div>

              </form>
              <Toaster />
          </div>  
        </>
    )
}
export default EditGift;