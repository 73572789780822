import React, { useState, useEffect } from 'react';
import '../styles/LoginForm.css';
import { useFormik } from 'formik';
import CustomSelect from '../helpers/CustomSelect';
import AuthUser from '../helpers/AuthUser';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { Typography, Input, Checkbox, Button } from "@material-tailwind/react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';


function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-1" : "opacity-1" } transition-transform`}>Read more…</span>
  );
}

function ClientRegister(){
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const {http} = AuthUser();
    const navigate = useNavigate();
    const [isRevealPwd, setIsRevealPwd] = useState(false);

    const gendertype = [
        { 
          value: 'male',
          label: 'Male'
         },
        { 
          value: 'female',
          label: 'Female' 
        },
        { 
          value: 'non-binary',
          label: 'Non-binary' 
        },
    ];
    const { handleSubmit, errors, touched, handleBlur,getFieldProps,setFieldValue } = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            user_type_primary: 'client',
            user_status: 'active',
            phone_number: '',
            gender: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('Required').min(2).max(50),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            gender: Yup.string().required('Required'),
            password: Yup.string().min(4).required('Required'),
            acceptTerms:Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions.'),
        }),
        onSubmit: values => {
            console.log('values',values);
            http.post('auth/register',JSON.stringify(values, null, 2)).then((res)=>{
            }).then(response => {
                toast.success(`Registration completed successfully`);
                navigate('/')
            }).catch(err => {
                if (err.message) {
                    toast.error('Email is already registered.');
                }
            });
          
        },
      });

    const [open, setOpen] = useState(0);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const togglePassword = () => {
        setIsRevealPwd(prevState => !prevState)
    };

    const [data, setData] = useState();
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await http.get('home/acfbcontent/5');
          const data = response.data?.data;
          if (data && data.acfb_booking_data) {
            setData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }, []);

    if (!data) {
        return <></>;
    }

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            <div className="max-w-screen-lg mx-auto">
              
                <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
                      IMPORTANT – READ THE FOLLOWING STATEMENT BEFORE BOOKING YOUR APPOINTMENT
                    </AccordionHeader>
                    <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
                      If you are unwell, have flu-like symptoms, have been in close contact with someone with COVID-19 or have COVID-19, DO NOT make an appointment. If you are feeling unwell prior to your appointment, please call ACFB and cancel your booking. Please provide ACFB with as much notice as possible if you need to cancel your clinic due to sickness.
                    </AccordionBody>
                </Accordion>

                <Typography variant="h2" className="text-center mb-5 font-normal hidden">Welcome to ACFB’s Student Massage Clinic</Typography>
                
                <div className="block md:flex md:flex-row md:gap-6">
                    <div className="w-full mb-6 md:m-0 md:w-2/5">
                        <div className="bg-white py-10 px-8 rounded-xl text-center">
                            <Typography variant="h3" className="font-bold mb-4 text-2xl">Existing client or student?</Typography>
                            <Typography variant="h5" className="font-normal mb-6 text-lg">Welcome back! Please login to continue</Typography>
                            <Link to="/"><Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3">Login</Button></Link>
                        </div>
                    </div>
                    <div className="w-full md:w-3/5">
                        <form onSubmit={handleSubmit}>

                            <div className="bg-white p-8 md:p-10 rounded-xl">
                                <div className="flex items-center mb-5">
                                    <Typography variant="h3" className="font-bold text-2xl">Register Now</Typography>
                                    <Typography variant="paragraph" className="font-normal text-sm ml-auto">* Required fields</Typography>
                                </div>
                            
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7 pt-8 pb-4 w-full">
                                    <div className="form-input w-full">
                                        <Input
                                            autoComplete="first_name"
                                            name="first_name"
                                            variant="static"
                                            fullWidth
                                            id="firstName"
                                            placeholder='First Name *'
                                            label="First Name"
                                            {...getFieldProps('first_name')}
                                            className="border-black placeholder-shown:border-black"
                                        />
                                      {(touched.first_name && errors.first_name) && <span className="error">{errors.first_name}</span>}
                                    </div>
                                    <div className="form-input w-full">
                                        <Input
                                            autoComplete="last_name"
                                            name="last_name"
                                            variant="static"
                                            fullWidth
                                            id="lastName"
                                            label="Last Name *"
                                            placeholder='Last Name *'
                                            {...getFieldProps('last_name')}
                                            className="border-black placeholder-shown:border-black"
                                           />
                                           {(touched.last_name && errors.last_name) && <span className="error">{errors.last_name}</span>}
                                    </div>
                                    
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7 pt-8 pb-4 w-full">
                                    <div className="form-select w-full relative">
                                      <label htmlFor="rol">Gender *</label>
                                        <CustomSelect
                                            isSearchable={false}
                                            className={` w-full input ${touched.gender && errors.gender ? 'border-red-400' : 'border-black'}`}
                                            onChange={value=>setFieldValue('gender',value.value)}
                                            label="Gender"
                                            value={getFieldProps('gender').value}
                                            onBlur={handleBlur} 
                                            options={gendertype}
                                        />
                                      {(touched.gender && errors.gender) && <span className="error">{errors.gender}</span>}
                                    </div>

                                    <div className="form-input w-full">
                                        <Input
                                            autoComplete="phone_number"
                                            name="phone_number"
                                            variant="static"
                                            fullWidth
                                            id="phoneNumber"
                                            label="Mobile Number *"
                                            placeholder='Mobile Number'
                                            className="border-black placeholder-shown:border-black"
                                            {...getFieldProps('phone_number')}
                                        />
                                         {(touched.phone_number && errors.phone_number) && <span className="error">{errors.phone_number}</span>}
                                    </div>

                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7 pt-8 pb-4 w-full">
                                    <div className="form-input w-full">
                                    <Input
                                        type="email"
                                        variant="static"
                                        fullWidth
                                        placeholder="E-mail"
                                        id="email"
                                        label="Email Address *"
                                        {...getFieldProps('email')}
                                        className="border-black placeholder-shown:border-black"
                                        />
                                        {(touched.email && errors.email) && <span className="error">{errors.email}</span>}
                                       
                                    </div>
                                    
                                    <div className="form-input w-full password">
                                        <Input
                                            type={isRevealPwd ? "text" : "password"}
                                            autoComplete="password"
                                            name="password"
                                            variant="static"
                                            fullWidth
                                            id="password"
                                            label="Password *"
                                            placeholder='Password'
                                            {...getFieldProps('password')}
                                            className="border-black placeholder-shown:border-black"
                                         />
                                         <img
                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                            className = {isRevealPwd ? "Hide password" : "Show password"}
                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                            onClick={togglePassword}
                                            alt={isRevealPwd ? "Hide password" : "Show password"}
                                        />
                                          {(touched.password && errors.password) && <span className="error">{errors.password}</span>}
                                    </div>

                                </div>
                                
                                
                                <div className="checkbox mt-4 mb-4 w-full mt-5">
                                  <Checkbox 
                                     {...getFieldProps('acceptTerms')}
                                        containerProps={{
                                            className: "p-0 mr-3"
                                        }} 
                                        label={
                                          <Typography color="blue-gray" className="flex font-medium">By registering, you are agreeing that you have read and agree to the Terms & Conditions.</Typography>
                                        } 
                                    />
                                      {(touched.acceptTerms && errors.acceptTerms) && <span className="error">{errors.acceptTerms}</span>}
                                </div>

                                <div className="pt-4 pb-4 mb-8 h-96 overflow-auto terms-content client-register-section">
                                    <div dangerouslySetInnerHTML={{ __html: data.acfb_terms }}></div>
                                </div>

                                <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3" type="submit">Complete Registration</Button>
                            </div>
                    </form>
                    </div>
                </div>

                <Toaster/>

            </div>
        </main>
    );
}

export default ClientRegister;