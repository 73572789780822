import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Textarea,
  Checkbox,
  Typography,
  Radio,
  Dialog,
  DialogFooter
} from "@material-tailwind/react";
import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import CustomSelect from '../../../helpers/CustomSelect';

import Loading from '../../../Components/Loading';

import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';

import {usertype, usertype2, uservaccinated, genderpreference, userstatus, usergenderuser} from '../../../helpers/appointmentOptions';

function EditUser(){
    const {id} = useParams();
    const navigate = useNavigate();
    const {http,getSpecialNeedOptions,user} = AuthUser();
    const [AppointmentData, setAppointmentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [sneed, setNeeds] = useState([]);
    const [data, setUsers] = useState([]);
    const [dataFirstName, setFirstName] = useState([]);
    const [dataLastName, setLastName] = useState([]);
    const [dataEmail, setEmail] = useState([]);
    const [dataPhone, setPhone] = useState([]);

    const [dataGender, setDataGender] = useState([]);
    const [dataUserPrimary, setDataUserPrimary] = useState([]);
    const [dataUserStatus, setDataUserStatus] = useState([]);
    const [dataSpecialID, setDataSpecialID] = useState([]);
    const [dataUserSecondary, setDataUserSecondary] = useState([]);
    const [dataVaccinated, setDataVaccinated] = useState([]);
    const [dataGenderPreference, setDataGenderPreference] = useState([]);
    const [dataCount, setServiceCount] = useState([]);
    const [dataManual, setManualCompleted] = useState([]);
    const [dataPending, setPendingBalance] = useState([]);
    const [dataBlocknotes, setnoBlocknotes] = useState([]);

    const [completedAppointmentCount, setCompletedAppointmentCount] = useState([]);



    const [dataBlock, setDataBlockRestriction] = useState([]);
    const [dataTerms, setDataTerms] = useState([]);
    const [dialogopen, setDialog] = useState(false);
    const [services, setServices] = useState([]);

    useEffect(() => {
        Serviceslist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Serviceslist = () => {
     http.get('auth/services').then((resp)=>{
        setServices(resp.data.data);
     }).catch(err => {
        toast.error(err.response.data.message);
     });  
    }

    const handleMessageType = (item,userid) => {
        setFieldValue('massage_type',item);
        fetchuserAppointmentData(userid, item)
       
    }

   const specialoptions = sneed.map(d => ({
     "value" : d.id,
     "label" : d.title
   }))

    const dialogHandleOpen = () => setDialog(!dialogopen);
    const openDialog = () => {
        setDialog(true);
    }
    const closeDialog = () => {
        setDialog(false);
    }

   useEffect(() => {
        async function fetchData1() {
          try {
                setIsLoading(true);
                const response =  await http.get(`auth/appointment/user/${id}`);
                const data = await response.data;
                setAppointmentData(data);
          } catch (error) {
              toast.error(error.response);
          }
        } 
        setIsLoading(false);
      fetchData1();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchuserAppointmentData = async (userId, serviceId) => {
        if (userId && serviceId) {
          try {
            const response = await http.get(`auth/student/${userId}/appointments-count/${serviceId}`);
            setCompletedAppointmentCount(response.data.completedAppointmentCount);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
    };


    useEffect(() => {
        const fetchData = async (userId) => {
          try {
            setIsLoading(true);
            const needsResponse = await getSpecialNeedOptions();
            setNeeds(needsResponse.data.data);
    
            const userResponse = await http.get(`auth/user/${userId}`);
            const data = userResponse.data.data;
            setUsers(data); setFirstName(data); setLastName(data); setEmail(data); setPhone(data); setDataGender(data); setDataUserPrimary(data); setDataUserStatus(data); setDataSpecialID(data); setPendingBalance(data);
            setDataUserSecondary(data); setDataVaccinated(data); setDataGenderPreference(data); setServiceCount(data); setManualCompleted(data);
            setDataBlockRestriction(data); setDataTerms(data);setnoBlocknotes(data);
            fetchuserAppointmentData(data.id, data.massage_type)
            const fields = ['id','first_name', 'last_name', 'email', 'phone_number', 'gender', 'user_type_primary', 'user_type_secondary', 'user_status', 'vaccinated', 'gender_pref', 'massage_type', 'selected_services_count', 'manual_completed_clinic' , 'pending_balance', 'terms_and_conditions', 'noBlockrestriction','noBlocknotes'];
            fields.forEach(field => setFieldValue(field, data[field], false));
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        };
        fetchData(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    const { handleSubmit,handleBlur, errors, touched, getFieldProps, setFieldValue } = useFormik({
        initialValues: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          phone_number: '',
          gender: '',
          user_type_primary: '',
          user_status: '',
          special_id: '',
          pending_balance: '',
          // noBlocknotes: '',
        },
        validationSchema: Yup.object({
           //  title: Yup.string().required('Required').min(2).max(50),
        }),
       onSubmit: (values) => {
        console.log(values, 'values')
            http.put(`auth/user/${id}`,JSON.stringify(values, null, 2)).then((result)=>{
                if (result.data['status'] === true) {
                    toast.success("User Updated!");
                    navigate("/admin/users");
                }
            }).catch(err => {
              console.log('err',err);
          });
        }
    });

    const handleDelete = (event,userId) => {
        event.preventDefault();            
        http.delete(`auth/user/${userId}`)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/admin/users");
          setDialog(false);
        }).catch((error) => {
            if (error?.response) {
               toast.error(error?.response?.data.message);
             } else {
              toast.error('An error occurred.');
           }
        });
    }
    
    const handleDeleteCard = (event, userId) => {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete the card details?")) {
            http.put(`auth/user/savedcard/${userId}`)
            .then((response) => {
                toast.success(response.data.message);
                navigate("/admin/users");
            })
            .catch((error) => {
                if (error?.response) {
                    toast.error(error?.response?.data.message);
                } else {
                    toast.error('An error occurred.');
                }
            });
        } 
    }
    const handleResetPassword = async (event,UserEmail) => {
        event.preventDefault();
        try {
           setIsLoading(true);
          const response = await http.post('auth/forgot-password', {'email':UserEmail});
          setIsLoading(false);
          toast.success(response.data.message);
        } catch (error) {
            if(error?.response?.data){
                toast.error(error?.response?.data?.message);
            }else{
                toast.error('Failed to send reset link. Please check your email and try again.');
            }
        }
      };
    const topNav = [
        {
            name: "User Profile",
            url: getAdminUrl(`user/edit/${id}`)
        },
        {
            name: "User Reports",
            url: getAdminUrl(`user/reports/${id}`)
        },
        {
            name: "History/Log",
            url: getAdminUrl(`user/sms-log/${id}`)
        }
    ]


    return(
        <>
        {isLoading && <Loading /> }
                <div className="user-single-head">
                    <div className="flex gap-4 mb-8 w-full">
                        <div className="w-6/12 client-info">
                            <h4 className="capitalize">{data.user_type_primary}</h4>
                            <h2 className="text-white">{data.first_name + ' ' + data.last_name}</h2>
                            <div className="status-bar">
                                <span>{dataUserStatus.user_status}</span>
                                <span>{data.phone_number}</span>
                            </div>
                        </div>
                        <div className="w-6/12">
                            <div className="flex gap-4">
                                {data.user_type_primary !== 'student' &&
                                    <div className="white-box card-box w-4/12">
                                        <h4>Available Credit</h4>
                                        {data.pending_balance ?
                                            <h2>$ {data.pending_balance}</h2>
                                            : <h2>$ 0.00</h2>
                                        }
                                    </div>
                                }
                                 {data.user_type_primary !== 'student' ?(
                                    <div className="white-box card-box w-4/12">
                                       <h4>Total Scheduled</h4>
                                       <h2>{AppointmentData.scheduledAppointments}</h2>
                                    </div>
                                    ):(
                                    <div className="white-box card-box w-4/12">
                                       <h4>Target Number of Clinics</h4>
                                       <h2>{data.selected_services_count}</h2>
                                    </div>
                                 )}
                                <div className="white-box card-box w-4/12">
                                    <h4>Total Completed</h4>
                                    <h2>{AppointmentData.completedAppointments}</h2>
                                </div>
                                <div className="white-box card-box w-4/12">
                                    <h4>Total Cancelled</h4>
                                    <h2>{AppointmentData.cancelledAppointments}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>

                    <TopNav data={topNav} />

                    <div className="white-box  p-0">
                        <div className="heading mb-3 pt-8 px-8">
                            <h2>Edit User Profile</h2>
                        </div>

                         <form onSubmit={handleSubmit}>
                            <div className="px-8">
                                <div className="flex gap-6 mb-14 w-full">
                                    <div className="form-input w-3/12">
                                        <Input
                                        autoComplete="fname"
                                        name="first_name"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="firstName"
                                        label="First Name"
                                        className="border-black placeholder-shown:border-black"
                                        value={dataFirstName.first_name} 
                                        onChange={e => {
                                          setFirstName({});
                                          setFieldValue('first_name',e.target.value);
                                        }}
                                        />
                                        {(touched.first_name && errors.first_name) && <span className="error">{errors.first_name}</span>}
                                    </div>
                                    <div className="form-input w-3/12">
                                        <Input
                                        autoComplete="lname"
                                        name="last_name"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="lastName"
                                        label="Last Name"
                                        className="border-black placeholder-shown:border-black"
                                        value={dataLastName.last_name}
                                        onChange={e => {
                                          setLastName({});
                                          setFieldValue('last_name',e.target.value);
                                         }}
                                        />
                                        {(touched.last_name && errors.last_name) && <span className="error">{errors.last_name}</span>}
                                    </div>
                                    <div className="form-input w-3/12">
                                        <Input
                                        autoComplete="email"
                                        name="email"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="email"
                                        label="Email"
                                        className="border-black placeholder-shown:border-black"
                                        value={dataEmail.email}
                                        onChange={e => {
                                          setEmail({});
                                          setFieldValue('email',e.target.value);
                                        }}
                                        />
                                        {(touched.email && errors.email) && <span className="error">{errors.email}</span>}
                                    </div>
                                    <div className="form-input w-3/12">
                                        <Input
                                        autoComplete="phone_number"
                                        name="phone_number"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="phone_number"
                                        label="Mobile Number *"
                                        className="border-black placeholder-shown:border-black"
                                        value={dataPhone.phone_number}
                                        onChange={e => {
                                          setPhone({});
                                          setFieldValue('phone_number',e.target.value);
                                         }}
                                        />
                                        {(touched.phone_number && errors.phone_number) && <span className="error">{errors.phone_number}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="px-8">
                                <div className="flex gap-6 mb-14 w-full">
                                    <div className="form-select w-3/12 relative">
                                        <label htmlFor="rol">Gender *</label>
                                        <CustomSelect
                                            isSearchable={false}
                                            className='input'
                                            value={dataGender.gender}
                                            options={usergenderuser}
                                            onChange={event => {
                                                setDataGender({});
                                                setFieldValue('gender',event.value);
                                            }}
                                        />
                                        {(touched.gender && errors.gender) && <span className="error">{errors.gender}</span>}
                                    </div>
                                    <div className="form-select w-3/12 relative">
                                        <label htmlFor="rol">Primary Role *</label>
                                        <CustomSelect
                                            className='input'
                                            name="user_type_primary"
                                            id="user_type_primary"
                                            variant="static"
                                            value={dataUserPrimary.user_type_primary}
                                            onBlur={handleBlur}
                                            options={usertype}
                                            onChange={event => {
                                                setDataUserPrimary({});
                                                setFieldValue('user_type_primary',event.value);
                                            }}
                                          />
                                        {(touched.user_type_primary && errors.user_type_primary) && <span className="error">{errors.user_type_primary}</span>}
                                    </div>

                                    {dataUserPrimary.user_type_primary === 'student' &&
                                        <div className="form-select w-3/12 relative">
                                            <label htmlFor="rol">Secondary Role</label>
                                            <CustomSelect
                                                isSearchable={false}
                                                className='input'
                                                value={dataUserSecondary.user_type_secondary}
                                                onBlur={handleBlur}
                                                onChange={event => {
                                                    setDataUserSecondary({});
                                                    setFieldValue('user_type_secondary',event.value);
                                                }}
                                                options={usertype2}
                                            />
                                        </div>
                                    }

                                    <div className="form-select w-3/12 relative">
                                        <label htmlFor="rol">User Status *</label>
                                        <CustomSelect
                                          className='input'
                                          name="user_status"
                                          id="user_status"
                                          variant="static"
                                          value={dataUserStatus.user_status}
                                          onChange={event => {
                                                setDataUserStatus({});
                                                setFieldValue('user_status',event.value);
                                            }}
                                          options={userstatus}
                                          />
                                      {(touched.user_status && errors.user_status) && <span className="error">{errors.user_status}</span>}
                                  </div>

                                {dataUserPrimary.user_type_primary === 'client' ? (
                                    <div className="form-select w-3/12 relative">
                                            <label htmlFor="rol">Client Notes </label>
                                            <CustomSelect
                                              isSearchable={false}
                                              isClearable={true}
                                              className='input'
                                              value={dataSpecialID && dataSpecialID.special_id}
                                              onBlur={handleBlur}
                                              onChange={event => {
                                                if (event) {
                                                  setDataSpecialID({});
                                                  setFieldValue('special_id', event.value);
                                                } else {
                                                  setDataSpecialID(null);
                                                  setFieldValue('special_id', null);
                                                }
                                              }}
                                              options={specialoptions}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )
                                }

                                 {(dataUserPrimary.user_type_primary === 'client' || dataUserPrimary.user_type_secondary === 'client') && (
                                    <div className="form-input w-3/12 px-4">
                                        <Input
                                            autoComplete="credit"
                                            name="balance"
                                            variant="static"
                                            fullwidth={"true"}
                                            id="credit"
                                            label="Credit Balance"
                                            placeholder="0.00"
                                            className="border-black placeholder-shown:border-black"
                                            value={dataPending.pending_balance}
                                            onChange={e => {
                                                setPendingBalance({});
                                                setFieldValue('pending_balance', e.target.value);
                                            }}
                                        />
                                        {touched.pending_balance && errors.pending_balance && <span className="error">{errors.pending_balance}</span>}
                                    </div>
                                )}

                                {/*{dataUserPrimary.user_type_primary === 'client' &&
                                    <div className="form-input w-3/12 px-4">
                                        <Input
                                        autoComplete="credit"
                                        name="balance"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="credit"
                                        label="Credit Balance "
                                        className="border-black placeholder-shown:border-black"
                                        value={dataPending.pending_balance}
                                        onChange={e => {
                                          setPendingBalance({});
                                          // setFieldValue(e.target.name,e.target.value);
                                          setFieldValue('pending_balance',e.target.value);
                                        }}
                                        />
                                        {(touched.pending_balance && errors.pending_balance) && <span className="error">{errors.pending_balance}</span>}
                                    </div>
                                }*/}

                                {dataUserPrimary.user_type_primary === 'student' && <>
                                    <div className="form-select w-2/12 relative">
                                        <label htmlFor="rol">Vaccinated</label>
                                        <CustomSelect
                                            isSearchable={false}
                                            className='input'
                                            value={dataVaccinated.vaccinated}
                                            onBlur={handleBlur}
                                                onChange={event => {
                                                setDataVaccinated({});
                                                setFieldValue('vaccinated',event.value);
                                            }}
                                            options={uservaccinated}
                                        />
                                    </div>
                                </>}

                                
                  
                            {/* <div className="form-select w-3/12 px-4">
                                <CustomSelect
                                  className='input'
                                  name="special_id"
                                  variant="static"
                                  value={parseInt(selectedValue)}
                                  onChange={handleChangeSelectNeeds}
                                  options={specialoptions}
                                  />
                                {(touched.specialoptions && errors.specialoptions) && <span className="error">{errors.specialoptions}</span>}             
                            </div>*/}

                            {dataUserPrimary.user_type_primary !== 'student' &&
                                <div className="form-input w-3/12">
                                    <Input
                                    autoComplete="fname"
                                    name="password"
                                    variant="static"
                                    fullwidth={"true"}
                                    id="password"
                                    label="Password *"
                                    className="border-black placeholder-shown:border-black pointer-events-none"
                                    value='***************'
                                    />
                                    <Link onClick={(event) => { handleResetPassword(event,data.email)}}  className="underline underline-offset-1 text-xs font-medium">Send Password Reset Email</Link>
                                </div>
                            }

                           </div>
                       </div>

                        {dataUserPrimary.user_type_primary === 'student' &&
                            <div className="px-8">
                                <div className="flex gap-6 mb-8 w-full">
                                    <div className="form-select w-4/12 relative">
                                        <label htmlFor="rol">Gender Preference Of Client</label>
                                        <CustomSelect
                                            isSearchable={false}
                                            className='input'
                                            value={dataGenderPreference.gender_pref}
                                            onBlur={handleBlur}
                                                onChange={event => {
                                                setDataGenderPreference({});
                                                setFieldValue('gender_pref',event.value);
                                            }}
                                            options={genderpreference}
                                        />
                                    </div>

                                    <div className="form-input w-3/12 px-4">
                                        <Input
                                        autoComplete="password"
                                        name="password"
                                        variant="static"
                                        fullwidth={"true"}
                                        id="password"
                                        label="Password *"
                                        className="border-black placeholder-shown:border-black pointer-events-none"
                                        value='***************'
                                        />
                                        <Link onClick={(event) => { handleResetPassword(event,data.email)}}  className="underline underline-offset-1 text-xs font-medium">Send Password Reset Email</Link>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="px-8">
                             <div className="flex flex-wrap mt-8 mb-8 w-full">

                                  <div className="w-full form-textarea with-label">
                                        <Textarea
                                            name="noBlocknotes"
                                            variant="static" 
                                            className="border-black placeholder-shown:border-black"
                                            label="Admin Only Notes" 
                                            placeholder="Message"
                                            value={dataBlocknotes.noBlocknotes}
                                            onChange={e => {
                                              setnoBlocknotes({});
                                              setFieldValue('noBlocknotes',e.target.value);
                                            }}
                                          />
                                        {(touched.noBlocknotes && errors.noBlocknotes) && <span className="error">{errors.noBlocknotes}</span>}
                                    </div>
                              </div>


                            <div className="flex flex-wrap mt-8 mb-8 w-full">

                                {dataUserPrimary.user_type_primary === 'student' &&
                                    <div className="w-full mb-4">
                                        <label className="flex">
                                            <Checkbox 
                                                id="noBlock-restriction" 
                                                checked={dataBlock.noBlockrestriction} 
                                                containerProps={{
                                                    className: "p-0 mr-2"
                                                }} 
                                                name='noBlockrestriction' 
                                                // onChange={handleRestrictionBlockChange} 
                                                onChange={event => {
                                                    setDataBlockRestriction({});
                                                    setFieldValue('noBlockrestriction',event.target.checked);
                                                    console.log(event.target.checked, 'noBlockrestriction');
                                                }}
                                            />
                                             No time block restriction
                                        </label>
                                    </div>
                                }

                                <div className="form-select w-full">
                                    <p>* Required fields</p>
                                </div>
                            
                             </div>
                        </div>


                        {dataUserPrimary.user_type_primary === 'student' &&<>
                            <div className="px-8">
                                <hr className="mt-6 mb-14" style={{ borderColor: '#CCCCCC' }} />
                            </div>

                            <div className="message-type-area">
                                <Typography variant="h4" className="px-8 font-normal mb-8">ACFB Clinic Tally</Typography>
                                <div className="flex bg-greycolor9 py-6 px-8">
                                    <div className="w-4/12">
                                        Massage Type
                                    </div>
                                    <div className="w-4/12">
                                        Target Number of Clinics
                                    </div>
                                    <div className="w-4/12">
                                        Completed Clinics
                                    </div>
                                    <div className="w-4/12">
                                        Manual Completed Clinics
                                    </div>
                                </div>
                                <div className="py-4 px-8">
                                 {services.map((item, index) => (
                                      <div key={index} className="flex">
                                        <div className="w-4/12">
                                          <Radio
                                            id={`massage_type_${index}`}
                                            value={item.id}
                                            label={item.title}
                                            checked={getFieldProps('massage_type').value === item.id}
                                            onBlur={handleBlur}
                                            onChange={(event) => handleMessageType(item.id,data.id) }
                                          />
                                        </div>
                                        {getFieldProps('massage_type').value === item.id && (<>
                                            <div className="w-4/12">
                                                <Input
                                                  type="number"
                                                  variant="static"
                                                  id={`selected_services_count_${item.id}`}
                                                  placeholder="0"
                                                  labelProps={{
                                                      className: "hidden" 
                                                  }}
                                                  className="w-52 border-0 p-0 focus:border-gray-400 shadow-none"
                                                  value={dataCount.massage_type === item.id ? dataCount.selected_services_count: null}
                                                  name={`selected_services_count[${item.id}]`}
                                                  onChange={e => {
                                                    setServiceCount({});
                                                    setFieldValue(`selected_services_count[${item.id}]`,e.target.value);
                                                  }}
                                                />
                                            </div>
                                            <div className="w-4/12">
                                                {completedAppointmentCount}
                                            </div>
                                            <div className="w-4/12">
                                                <Input
                                                  type="number"
                                                  variant="static"
                                                  id={`manual_completed_clinic_${item.id}`}
                                                  placeholder="0"
                                                  labelProps={{
                                                      className: "hidden" 
                                                  }}
                                                  className="w-52 border-0 p-0 focus:border-gray-400 shadow-none"
                                                  value={dataManual.massage_type === item.id ? dataManual.manual_completed_clinic: null}
                                                  name={`manual_completed_clinic[${item.id}]`}
                                                  onChange={e => {
                                                    console.log('e.target.value',e.target.value)
                                                    setManualCompleted({});
                                                    setFieldValue(`manual_completed_clinic[${item.id}]`,e.target.value);
                                                  }}
                                                />
                                            </div>
                                        </>)}

                                      </div>
                                    ))}

                                </div>
                            </div>
                        </>}

                        <div className="bottom-button-group flex flex-wrap justify-end items-center pb-8 px-8 m-0">
                            {dataUserPrimary.user_type_primary === 'client' &&
                                <div>
                                    <div className="w-full">
                                        <label className="flex">
                                            <Checkbox 
                                                id="accept-terms" 
                                                checked={dataTerms.terms_and_conditions} 
                                                containerProps={{
                                                    className: "p-0 mr-2"
                                                }} 
                                                name='acceptTerms' 
                                                onChange={event => {
                                                    setDataTerms({});
                                                    setFieldValue('acceptTerms',event.target.checked);
                                                    console.log(event.target.checked, 'acceptTerms');
                                                }}
                                            />
                                            User agrees to terms & conditions
                                        </label>
                                    </div>
                                </div>
                            }

                            {user.user_type_primary === 'admin' &&
                                <div className="flex gap-3 w-full mt-4">
                                     <Button onClick={(event) => { handleDeleteCard(event,data.id)}} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3">Remove Saved Card</Button>
                                    <Button onClick={openDialog} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3">Delete</Button>
                                    <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 ml-auto rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Update User</Button>
                               </div>
                            }
                        </div>

                       </form>

                       <Dialog open={dialogopen} size={"md"} handler={dialogHandleOpen} className="px-6 py-8">

                            <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Are you sure you would like to delete this user?</Typography>

                            <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                                <Button onClick={(event) => { handleDelete(event,data.id)}} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Confirm</Button>
                                <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialog}>Close</Button>
                            </DialogFooter>
                        </Dialog>

                        <Toaster />
                    </div>
                </div>
         </>
    )
}
export default EditUser;