import { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../../helpers/AuthUser';
import { Link } from 'react-router-dom';
import {getAdminUrl} from "../../../../helpers/Utils";
import TopNav from '../../../../Components/TopNav/TopNav';

const topNav = [
    {
        name: "View All",
        url: getAdminUrl('services')
    },
    {
        name: "Add New",
        url: getAdminUrl('gift/services/add-new'),
        button: [
            {
                name: "Add New"
            }
        ]
    }
]


export default function GiftServices(){
    const {http}=AuthUser();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        GetServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const GetServices = async() => {
     setLoading(true);
     await http.get('products').then((resp)=>{
        setProducts(resp.data.data);
        setLoading(false);
     }).catch(error => {
        console.log(error.response.data.message);
      });   
    }

    const columns = [
        {
            name: 'Title',
            selector: row => row.name,
        },
        {
            name: 'Price',
            selector: row => '$' + row.price,
        },
        {
            name: 'Promo Price',
            selector: row => row.promo_price,
        },
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/gift/service/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    return (
        <>
            <TopNav data={topNav} />

            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>All</h2>
                            <div className="input-middle">
                            </div>
                            <div className="status-bar">
                            </div>
                        </div>
                    </div>

                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={products}
                            className="table-title-style"
                            selectableRows
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}