import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EditAppointmentForm from '../Payment/EditAppointmentForm';

const stripePromise = loadStripe('pk_live_51IpNMjBQYf8AEnjL2CLLLw77I9mLKvT5XrT44wOnI3K6ubQp3YnEgzun3d692U5svXwtcXWe9pv1GiYv9WqMJUiJ00JdV7aP3S');

function EditAppointment() {
  return (
    <Elements stripe={stripePromise}>
      <EditAppointmentForm />
    </Elements>
  );
}
export default EditAppointment;