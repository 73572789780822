import {
  Typography,
} from "@material-tailwind/react";
import LogoIcon from '../../images/logo-icon.png';

function LoadingFixed(){
	return(	
		  <div className="fixed h-full flex justify-center items-center flex-col inset-x-0 inset-y-0 bg-white/90 z-20">
				<img src={LogoIcon} className="logo-icon w-13" />
				<Typography variant="h4" className="text-blue-900 mt-3">Loading...</Typography>
			</div>
		);
}

export default LoadingFixed;