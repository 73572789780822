import React, { useState, useEffect } from 'react';
import {
  Input,
  Typography,
  Button,
  Dialog,
  DialogFooter
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';

function EditMassageType() {
   
    const navigate = useNavigate();
    const {http} = AuthUser();
    const {id} = useParams();
    const [show, setShow] = useState(false)
    const [StartDate, setStartDate] = useState(null)
    const [EndDate, setEndDate] = useState(null)
    const [data, setData] = useState([]);
    const [ImageData, setImageData] = useState([]);
    
    const [dialogopen, setDialog] = useState(false);
    const [size, setSize] = useState(null);

    const handleEndDateChange = (selectedDate) => {
      setEndDate(selectedDate.startDate)
      setFieldValue('promo_end_date',selectedDate)
    }
    const handleDateChange = (selectedDate) => {
        setStartDate(selectedDate.startDate)
        setFieldValue('promo_start_date',selectedDate)
    }

    const handleClose = (state) => {
        setShow(state)
    }
    
    useEffect(() => {
      async function fetchData() {
        try {
            const response =  await http.get(`auth/services/${id}`);
            const data = await response.data.data;
            setData(data);
            setImageData(data);
            setStartDate(data.promo_start_date);
            setEndDate(data.promo_end_date);
            const fields = ['id','title','price','promo_price','prev_promo_price','promo_start_date','promo_end_date','image'];
            fields.forEach(field => setFieldValue(field, data[field], false));
        } catch (error) {
          console.error(error);
        }
    }
    fetchData();
    }, []);

    const { handleSubmit, errors, touched, setFieldValue, handleBlur} = useFormik({
      initialValues: {
          title: '',
          price: '',
          promo_start_date:null,
          promo_end_date:null,
      },
      validationSchema: Yup.object({
          title: Yup.string().required('Required').min(2).max(50),
          price: Yup.string().required('Required').min(2).max(50),
      }),
      onSubmit: async (values)=> {
        if(values.promo_start_date){
          values = {...values,promo_start_date:values.promo_start_date.startDate,promo_end_date:values.promo_end_date.startDate,promo_price:values.promo_price?values.promo_price:values.prev_promo_price}
        }else{
          values = {...values,promo_price:values.promo_price?values.promo_price:values.prev_promo_price}
        }
       
        await http.put(`auth/services/${id}`,JSON.stringify(values, null, 2)).then((result)=>{
          if (result.data['status'] === true) {
              toast.success(result.data['message']);
              navigate("/admin/services");
             }
          }).catch(err => {
            toast.error(err.response.data.message);
         });
      },
  });

  const dialogHandleOpen = () => setDialog(!dialogopen);
  const openDialog = () => {
      setDialog(true);
  }
  const closeDialog = () => {
      setDialog(false);
  }

  const handleDelete = (event,serviceId) => {
    event.preventDefault();            
    http.delete(`auth/services/${serviceId}`)
    .then((response) => {
      toast.success(response.data.message);
      navigate("/admin/services");
      setDialog(false);
    }).catch((error) => {
        if (error.response) {
           toast.error(error.response.data.message);
         } else {
          toast.error('An error occurred.');
       }
    });
}
	return(
    <div>
    <div className="white-box p-0">
   
         <div className="heading mb-3 px-8 pt-8">
             <h2>Edit Booking Type</h2>
         </div>
         <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-8 mb-8 w-full px-8">
                     <div className="form-input">
                         <Input
                             autoComplete="title"
                             name="title"
                             variant="static"
                             fullwidth
                             id="title"
                             label="Title"
                             className="border-black placeholder-shown:border-black"
                             value={data.title}
                             onChange={e => {
                               setData({});
                               setFieldValue(e.target.name,e.target.value);
                              }}
                             onBlur={handleBlur}
                           />
                       {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                     </div>
                     <div className="form-input">
                         <Input
                             autoComplete="price"
                             name="price"
                             variant="static"
                             fullwidth
                             id="price"
                             label="Price"
                             className="border-black placeholder-shown:border-black"
                             value={data.price}
                             onChange={e => {
                               setData({});
                               setFieldValue(e.target.name,e.target.value);
                              }}
                             onBlur={handleBlur}
                           />
                         {(touched.price && errors.price) && <span className="error">{errors.price}</span>}
                     </div>
                     <div className="form-input">
                         <Input
                             autoComplete="promo_price"
                             name="promo_price"
                             variant="static"
                             fullwidth
                             id="promo_price"
                             label="Promo Price"
                             className="border-black placeholder-shown:border-black"
                             value={data.promo_price?data.promo_price:data.prev_promo_price}
                             onChange={e => {
                               setData({});
                               setFieldValue(e.target.name,e.target.value);
                              }}
                             onBlur={handleBlur}
                           />
                         {(touched.promo_price && errors.promo_price) && <span className="error">{errors.promo_price}</span>}
                     </div>
                 </div>
                 <div className="grid grid-cols-4 gap-8 mb-8 w-full px-8">
                     <div className="form-input days-input date-picker relative mt-6">
                         <label htmlFor="rol">Start Date</label>
                         <div class="date-custom-outer">
                            <div className="date-test">
                               <input
                                   type="text"
                                   className="date-input-innner"
                                   placeholder="Select Date"
                                   value={moment(StartDate).format('DD/MM/YYYY')}
                                   onFocus={() => setShow(true)}
                                   readOnly
                               />
                            </div>
                            <Datepicker 
                               className="w-full"
                               displayFormat={"DD/MM/YYYY"}
                               placeholder={"Date"} 
                               useRange={false} 
                               asSingle={true}
                               onChange={handleDateChange} 
                               show={show} 
                               setShow={handleClose}  
                               selected={StartDate}
                               onFocus={() => setShow(true)} 
                               disabled={false}
                             />
                            {errors.promo_start_date ? <div className='error'>{errors.promo_start_date}</div> : null}            
                        </div>   
                        </div>
                        <div className="form-input days-input date-picker mb-4 relative mt-6">
                             <label htmlFor="rol">End Date</label>
                             <div class="date-custom-outer">
                                <div className="date-test">
                                   <input
                                       type="text"
                                       className="date-input-innner"
                                       placeholder="Select Date"
                                       value={moment(EndDate).format('DD/MM/YYYY')}
                                       onFocus={() => setShow(true)}
                                       readOnly
                                   />
                                </div>
                                 <Datepicker 
                                   className="w-full"
                                   displayFormat={"DD/MM/YYYY"}
                                   placeholder={"Date"} 
                                   useRange={false} 
                                   asSingle={true}
                                   onChange={handleEndDateChange} 
                                   show={show} 
                                   setShow={handleClose}  
                                   selected={EndDate}
                                   onFocus={() => setShow(true)} 
                                   disabled={false}
                                 />
                                {errors.promo_end_date ? <div className='error'>{errors.promo_end_date}</div> : null}           
                             </div>        
                         </div>
                 </div>
                 <div className="grid grid-cols-4 gap-8 mb-8 w-full px-8">
                    <div className="form-input">
                         <img src={ImageData.image} className="w-full rounded-xl" alt='Massage image' />
                     </div>
                     <div className="form-input">
                         <input
                             type="file"
                             className="w-full px-4 py-2"
                             label="Image"
                             name="image"
                             onChange={(event) => {
                               const formData = new FormData();
                               const reader = new FileReader();
                               if (event.target.files[0]) {
                                     reader.readAsDataURL(event.target.files[0]);
                                   }
                               reader.onload = (readerEvent) => {
                               formData.append("image", readerEvent.target.result);
                               setFieldValue('image', readerEvent.target.result);
                               };
                            }}
                         />
                     </div>
                 </div>
             <div className="bottom-button-group flex flex-wrap gap-6 px-8 pb-8 m-0">
                <Button onClick={openDialog} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3">Delete</Button>
                <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3 ml-auto" type="submit">Update Booking Type</button>
             </div>
         </form>
         {console.log('data.id',data.id)}
         <Dialog open={dialogopen} size={size || "md"} handler={dialogHandleOpen} className="px-6 py-8">
          <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Are you sure you would like to delete this Booking Type?</Typography>
           <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                <Button onClick={(event) => { handleDelete(event,data.id)}} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Confirm</Button>
                <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialog}>Close</Button>
            </DialogFooter>
         </Dialog>
         <Toaster />
     </div>
 </div>
	)
}
export default EditMassageType;
