import React, { useState, useEffect } from 'react';
import {
  Input,
  Checkbox
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Datepicker from "react-tailwindcss-datepicker"; 
import CustomSelect from '../../../helpers/CustomSelect';
import {optionstime} from '../../../helpers/appointmentOptions';

function AddNewAvailability() {

    const {http, getStudents}=AuthUser();
    
    const navigate = useNavigate();
    const { handleSubmit, errors, touched, getFieldProps,handleChange, setFieldValue } = useFormik({
        initialValues: {
            title: '',
            student_name: '',
            date: '',
            times: []
        },
        validationSchema: Yup.object({
            // title: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: (values) => {
            values = { ...values, date: values.date.startDate, user_id: values.student_name}
            http.post('auth/availability',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully toasted!');
                navigate("/admin/availability");
            }).catch(err => {
                toast.error("an error occured!");
            });
        },
    });

    const [users, setUsers] = useState([]);
    useEffect(() => {
        UsersGet()
    }, [])

    const UsersGet = () => {
     getStudents().then((resp)=>{
        setUsers(resp.data.data);
     });
    }
    const options = users.map(d => ({
      "value" : d.id,
      "label" : d.email + '(' + d.first_name + ' ' + d.last_name + ')'
    }))

    const handleValueChange = (newValue) => {
        setFieldValue("date", newValue); 
    } 

	return(
        <div>
		   <div className="white-box">
              	<div className="heading mb-3">
                  	<h2>Add New Availability</h2>
              	</div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap w-full">
                        <div className="form-input w-full mb-4">
                            <Input
                                autoComplete="title"
                                name="title"
                                variant="static"
                                fullWidth
                                id="firstName"
                                label="Title"
                                className="border-black placeholder-shown:border-black"
                                {...getFieldProps('title')}
                                onChange={handleChange}
                            />
                               {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                        </div>

                        <div className="form-select w-full mb-4 relative z-10 mt-6">
                            <label>Student</label>

                            <CustomSelect
                                className='input'
                                onChange={value=> setFieldValue('student_name',value.value)}
                                value={getFieldProps('student_name').value}
                                options={options}
                            />
                             {(touched.student_name && errors.student_name) && <span className="error">{errors.student_name}</span>}

                        </div>

                        <div className="form-input w-full mb-4 relative mt-6">

                            <label>Date</label>

                            <Datepicker 
                                className="w-full"
                                dateFormat="dd.MM.yyyy"
                                placeholder={"Date"} 
                                useRange={false} 
                                asSingle={true} 
                                {...getFieldProps('date')}
                                selected={(getFieldProps('date').value && new Date(getFieldProps('date').value)) || null}
                                onChange={handleValueChange}
                            />
                            {errors.date ? <div className='error'>{errors.date}</div> : null}
                            
                         </div>

                        <div className="flex flex-col flex-wrap w-full w-max gap-0 mt-4">
                            <div className="form-input"><label>Times</label></div>
                            {optionstime.map(opt => (
                               <Checkbox id="time2"  {...getFieldProps('times')} name="times" label={opt.label} value={opt.value}/>
                            ))}
                        </div>

            
                    </div>

                    <div className="bottom-button-group flex flex-wrap justify-end">
                      <div className="col">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Add New</button>
                      </div>
                    </div>

                </form>

                <Toaster />

           	</div>
        </div>
	)
}

export default AddNewAvailability;