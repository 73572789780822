import React from "react";
import {
  Typography
} from "@material-tailwind/react";

import FootLogo from '../../images/acfb-footer-logo.png';

function FooterBlank() {
	return (
		<footer className="bg-blacklight py-14 px-4 mt-auto">
			<div className="container flex flex-col items-center mx-auto">
				<div className="text-center">
						<div className="footer-logo mb-4">
				      <img
				         src={FootLogo}
				         alt="acfb-logo"
				         className="h-full block m-auto w-4/6"
				       />
			      </div>
			      <Typography variant="small" className="text-greycolor1 font-bold">
				        © Copyright 2023 – Australian College of Fitness & Bodywork
				    </Typography>
				</div>
			</div>
		</footer>
  	);
}

export default FooterBlank;