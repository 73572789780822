import React, { useState, useEffect } from 'react';
import { Typography} from "@material-tailwind/react";
import AuthUser from '../helpers/AuthUser';
import DataTable from 'react-data-table-component';
import TopNav from '../Components/TopNav/TopNav';
import AccordionList from '../helpers/AccordionList';
const columns = [
    {
        name: 'Massage Type',
        selector: row => row.massage_type,
    },
    {
        name: 'Date',
        selector: row => row.appointment_date,
    },
    {
        name: 'Time',
        selector: row => row.time,
    },
    {
        name: 'Status',
        selector: row => row.status,
    },
];

function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-0" : "" } transition-transform`}>Read more…</span>
  );
}

export default function ClientUpcomingBookings(props){
    const {http, user} = AuthUser();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [upcoming, setUpcoming] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        UpcomingGet()
    }, [])

    const UpcomingGet = () => {
        setLoading(true);
        http.get('/client-upcoming-bookings').then((response)=>{
             setUpcoming(response.data.data);
             setLoading(false);
        }).catch(error => {
            setMessage(error.response?.data?.message || 'An error occurred.');
            setUpcoming([]);
            setLoading(false);
        });  
    }

   useEffect(() => {
        async function fetchData() {
          try {
             const response =  await http.get(`booking/acfbcontent/1`);
              const data = await response.data.data;
              setData(data);
          } catch (error) {
            console.error(error);
          }
    }
    fetchData();
    }, []);

    const topNav = [
        {
            name: "Make a Booking",
            url: '/make-booking'
        },
        {
            name: "Upcoming Bookings",
            url: '/upcoming-bookings'
        },
        {
            name: "Past Bookings",
            url: '/past-bookings'
        },
        {
            name: "Gift Cards",
            url: '/gift-cards'
        },
        ...(user.user_type_secondary != null
        ? [
        {
            name: "Switch to Student",
            url: '/student/enter-availability'
        }
        ]
        : [])
    ]

    return (

        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            <div className="max-w-screen-lg mx-auto">
                <AccordionList data={data} />
                <div className="client-nav">
                    <TopNav data={topNav} />
                </div>
                <div className="white-box">
                    {upcoming.length !=0 ? (
                    <Typography variant="h5" className="font-normal mb-4">Upcoming Bookings: {Object.keys(upcoming).length}</Typography>
                    ) : (
                    <Typography variant="h5" className="font-normal">Upcoming Bookings: 0</Typography>
                    )}
                    {message && <p className="error-message red">{message}</p>}
                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={upcoming}
                            className="table-title-style"
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
} 
