import React, {useState, useEffect} from "react";
import {
  Select,
  Option,
  Input,
  Button,
  Checkbox
} from "@material-tailwind/react";

import { Link } from 'react-router-dom'
import {getAdminUrl} from '../../../helpers/Utils';
import TopNav from '../../../Components/TopNav/TopNav';

import MultiSelect from '../../../helpers/CustomMultiSelect';
import CustomSelect from '../../../helpers/CustomSelect';
import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
import DataTable from 'react-data-table-component';
import {Morningoptions, AfternoonOptions, EveningOptions, dayselect} from '../../../helpers/appointmentOptions';

const topNav = [
    {
        name: "View Allocations",
        url: getAdminUrl('view-allocation')
    },
    {
        name: "View Custom Allocations",
        url: getAdminUrl('view-custom-allocation')
    },
    {
        name: "Add Custom Allocation",
        url: getAdminUrl('view-custom-allocation'),
        button: [
            {
                name: "Add Custom Allocation"
            }
        ]
    }
] 

function ViewAllocation() {

    const {http} = AuthUser();
    const [loading, setLoading] = useState(false);
    const handledayChange = (selectedItems) => {
        console.log('selectedItems',selectedItems.value);
      setFieldValue('weekday', selectedItems.value);
    }

    const handlemorningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('morning_slots', temp.length > 0 ? temp : [])
    }

    const handleafternoontimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('afternoon_slots', temp.length > 0 ? temp : [])
    }
    const handleeveningtimeChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('evening_slots', temp.length > 0 ? temp : [])
    }

    const handleMassageChange = (selectedItems) => {
        const temp = selectedItems.map(item => item);
        setFieldValue('massage_type', temp.length > 0 ? temp : [])
    }

    const [service, setService] = useState([]);
    useEffect(() => {
        ServiceGet()
    }, [])

    const ServiceGet = () => {
        http.get('auth/services').then((resp)=>{
            setService(resp.data.data);
        });  
    }

    const soptions = service.map(d => ({
        "value" : d.id, 
        "label" : d.title
    }))


    const [allocation, setAllocation] = useState([]);
    useEffect(() => {
        AllocationGet()
        console.log(allocation)
    }, [])

    const AllocationGet = () => {
     setLoading(true);
     http.get('allocation').then((resp)=>{
        setAllocation(resp.data.data);
        setLoading(false);
     }).catch(err => {
        console.log('err',err);
     }); 
    }
    const { handleSubmit, errors, touched, getFieldProps,handleChange, setFieldValue } = useFormik({
        initialValues: {
            weekday: '',
            massage_type: [],
            morning_slots: [],
            afternoon_slots:[],
            evening_slots:[],
            limit: '',
        },
        validationSchema: Yup.object({
            weekday: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: (values) => {
            console.log(values)
            http.post('allocation',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully toasted!');
            });
        },
    });


    const columns = [
        {
            name: 'Days',
            selector: row => <div className="capitalize">{row.weekday}</div>,
        },
        {
            name: 'Morning Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.morning_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Afternoon Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.afternoon_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Evening Slot(s)',
            selector: row => 
            {
             return(
              <div class="times text-wrap">
              { JSON.parse(row.evening_slots).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },

        {
            name: 'Massage Type',
            sortable: true,
            selector: row =>
            { 
             return(
              <div class="times text-wrap">
                { JSON.parse(row.massage_type).map((row,index)=> (<>
                  <span> {row.label} </span>
                </>))}
              </div>
              )
            }
        },
        {
            name: 'Limit',
            selector: row => row.limit,
        },
        {
            name:"Action",
            cell: (row) => (
                <>
                    <Link to={"/admin/view-allocation/edit/" + row.id } className='btn btn-primary table-btn-link'>Update Day</Link>
                </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      
    ];


    return (
        <>
            <div className="page-header">

                <TopNav data={topNav} />

                <div className="white-box mb-2">
                    <div className="master-allocation-head">

                        <div className="flex">
                            <h2 className="mb-10">Update Allocations</h2>
                        </div>


                        
                        {allocation.length !=6 ? (
                        
                        <form onSubmit={handleSubmit}>
                            <div className="flex gap-5 custom-allocation-row">
                                <div className="form-select days-input w-4/12 relative">
                                    <label htmlFor="rol">Day(s)</label>
                                    <CustomSelect
                                        isSearchable={false}
                                        className='input'
                                        {...getFieldProps('weekday')}
                                        onChange={handledayChange}
                                        options={dayselect}
                                    />
                                </div>
                            
                             <div className="form-select w-4/12 time-slot relative">
                                <label>Morning Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('morning_slots')}
                                    className='input'
                                    onChange={handlemorningtimeChange}
                                    placeholder={"Choose..."}
                                    options={Morningoptions}
                                 />
                            </div>

                            <div className="form-select w-4/12 time-slot relative">
                                <label>Afternoon Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('afternoon_slots')}
                                    className='input'
                                    onChange={handleafternoontimeChange}
                                    placeholder={"Choose..."}
                                    options={AfternoonOptions}
                                 />
                            </div>

                            <div className="form-select w-4/12 time-slot relative">
                                <label>Evening Slot(s)</label>
                                <MultiSelect
                                    isSearchable={false}
                                    isMulti={true}
                                    {...getFieldProps('evening_slots')}
                                    className='input'
                                    onChange={handleeveningtimeChange}
                                    placeholder={"Choose..."}
                                    options={EveningOptions}
                                 />
                            </div>


                                <div className="form-select w-4/12 message-type relative">
                                    <label>Massage Type</label>
                                    <MultiSelect
                                        isSearchable={false}
                                        isMulti={true}
                                        {...getFieldProps('massage_type')}
                                        className='input'
                                        onChange={handleMassageChange}
                                        placeholder={"Select..."}
                                        options={soptions}
                                     />

                                </div>

                                <div className="w-4/12 limit-input">
                                    <div className="form-input">
                                        <Input
                                            autoComplete="limit"
                                            name="limit"
                                            variant="static"
                                            fullWidth
                                            type="number"
                                            id="limit"
                                            placeholder="1" 
                                            min="1" 
                                            max="100"
                                            label="Limit"
                                            className="border-black placeholder-shown:border-black"
                                            {...getFieldProps('limit')}
                                            onChange={handleChange}
                                        />
                                       {(touched.limit && errors.limit) && <span className="error">{errors.limit}</span>}

                                    </div>
                                </div>
                            </div>

                            <div className="bottom-button-group flex flex-wrap justify-end">
                                <div className="col">
                                    <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Add Day</button>
                                </div>
                            </div>
                        </form>

                        ) : (
                            ""
                        )}
                  </div>
                    <DataTable
                        columns={columns}
                        data={allocation}
                        className="table-title-style"
                        progressPending={loading}
                    />
                </div>
            </div>
        </>
    )
}
export default ViewAllocation;