import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem} from 'react-pro-sidebar';
import AuthUser from '../helpers/AuthUser';
import {getStudentUrl} from '../helpers/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear }
from '@fortawesome/free-solid-svg-icons';
import MultilevelMenu from '../Components/Sidebar/MultilevelMenu';
import LogoImg from '../images/logo.png';

const menuData = [
  {
    name: "Bookings",
    icon: <i class="fas fa-calendar"></i>,
    open: true,
    subopen: true,
    roles: ['student'],
    children: [
      {
        name: "Enter Availability",
        url: getStudentUrl('enter-availability'),
        roles: ['student'],
      },
      {
        name: "Upcoming Bookings",
        url: getStudentUrl('upcoming-bookings'),
        roles: ['student'],
      },
      {
        name: "Past Bookings",
        url: getStudentUrl('past-bookings'),
        roles: ['student'],
      }
    ]
  },
]

const BottomMenus = [
    { title: "Settings", src: <FontAwesomeIcon icon={faGear} />, linkto: "settings" },
];

function StudentSidebar() {
    const navigate = useNavigate();
    const {getToken, token, logout, user} = AuthUser();
    const userRole = 'student';
    const logoutUser=()=>{
      if(token !== undefined){
        logout();
      }
    }
    useEffect(() => {
      if(!getToken()){
        navigate('/');
      }
    });

    return (
      <Sidebar style={{ height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div className="head-area">
              <Menu>
                <MenuItem>
                  <Link className="logo" href="/">
                      <img src={LogoImg} />
                  </Link>
                </MenuItem>
              </Menu>
          </div>
          <MultilevelMenu data={menuData} userRole={userRole} />
          <Menu className="mt-auto mb-2">
              {user.user_type_secondary != null && user.user_type_secondary != 'none' &&
                <MenuItem icon={<i className="fas fa-user"></i>}>
                    <Link to="/make-booking">
                       <span>Switch to Client</span>
                   </Link>
                 </MenuItem>
              }
              <MenuItem icon={<i className="fas fa-user"></i>}>
                <Link to="/" onClick={logoutUser}>
                   <span>
                       Logout
                   </span>
               </Link>
             </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    );
}


export default StudentSidebar;
