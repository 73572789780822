import React from 'react';
import {
  Input
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../../helpers/AuthUser';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function AddNewSpecialNeeds() {

    const {http} = AuthUser();
    const navigate = useNavigate();

    const { handleSubmit, errors, touched, getFieldProps, handleChange } = useFormik({
        initialValues: {
          title: '',
        },
        validationSchema: Yup.object({
          title: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: values => {
            http.post('auth/needs',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully toasted!');
                navigate("/admin/services/special-needs");
            }).catch(err => {
              toast.error("an error occured!");
           });
        },
    });

	return(
        <div>
		   <div className="white-box">
          
              	<div className="heading mb-3">
                  	<h2>Add New Client Notes</h2>
              	</div>

                <form onSubmit={handleSubmit}>

                	<div className="row">
                      	<div className="flex mb-8 w-full">
                            <div className="form-input w-3/12 px-4">
        
                                  <Input
                                    autoComplete="title"
                                    name="title"
                                    variant="static"
                                    fullWidth
                                    id="firstName"
                                    label="Title"
                                    className="border-black placeholder-shown:border-black"
                                    {...getFieldProps('title')}
                                    onChange={handleChange}
                                />
                                   {(touched.title && errors.title) && <span className="error">{errors.title}</span>}

                            </div>
                    
                      	</div>
                 	</div>

                 	<div className="bottom-button-group flex flex-wrap justify-end">
                      <div className="col">

                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Submit</button>
                      </div>
                    </div>

                </form>

                <Toaster />

           	</div>
        </div>
	)
}

export default AddNewSpecialNeeds;