module.exports = {
    getRandomValue:(min=1,max=1000)=>{
        const rand = min + Math.random() * (max - min);
      return rand;
    },
    getAdminUrl:(routeName)=>{
        return '/admin/'+routeName;
    },
    getStudentUrl:(routeName)=>{
        return '/student/'+routeName;
    },
    getClientUrl:(routeName)=>{
        return '/client/'+routeName;
    },
  };