import React from "react";

import Topbar from '../Components/Topbar';

import DashboardTop from "../Components/Dashboard/DashboardTop";
import DashboardReport from "../Components/Dashboard/DashboardReport";

export default function Dashboard(){
    return (
        <>
            <DashboardTop />
            <hr style={{ borderColor: '#CCCCCC' }} />
            <DashboardReport />
        </>
    );
} 