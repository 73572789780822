import { Routes, Route, Navigate} from "react-router-dom";
import Home from '../../Pages/Home';
import LoginPage from '../../Pages/LoginPage';
import ClientRegisterPage from '../../Pages/ClientRegisterPage';
import ForgotPasswordPage from '../../Pages/ForgotPasswordPage';
import ResetPasswordPage from '../../Pages/ResetPasswordPage';
import StudentLoginPage from '../../Pages/StudentLoginPage';
import RelaxationProduct from '../../Pages/RelaxationProduct';
import RemedialProduct from '../../Pages/RemedialProduct';
import MyotherapyProduct from '../../Pages/MyotherapyProduct';
import NotFound from '../../Pages/NotFound';
import GTM from '../GTM';

export default function PublicRoutes(){
    return (
        <>
        <GTM />
        <Routes>
            <Route exact path={"/"} element={<Home />}/>
            <Route exact path={"/admin"} element={<LoginPage />}  />
            <Route exact path={"/client-register"} element={<ClientRegisterPage />}  />
            <Route exact path={"/forgot-password"} element={<ForgotPasswordPage />}  />
            <Route exact path={"/reset-password"} element={<ResetPasswordPage />}  />
            <Route exact path={"/student-login"} element={<StudentLoginPage />}  />
            <Route exact path={"/student-clinic-gift-card-relaxation-massage-1x-1hr-appointment"} element={<RelaxationProduct />}  />
            <Route exact path={"/student-clinic-gift-card-remedial-massage-1x-1hr-appointment"} element={<RemedialProduct />}  />
            <Route exact path={"/student-clinic-gift-card-myotherapy-treatment-1x-1hr-appointment"} element={<MyotherapyProduct />}  />
            
            {/* 404 route - Render NotFound component for any unmatched routes */}
            <Route path="*" element={<Navigate to="/404" />} />
            <Route path="/404" element={<NotFound />} />
        </Routes>
        </>
    );
}