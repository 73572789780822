import { useState } from 'react';
import '../styles/AdminStyle.css';
import AdminRoutes from '../Routes/Admin/AdminRoutes';
import AdminSidebar from './AdminSidebar';
import Topbar from '../Admin/Components/Topbar';
import { useProSidebar } from 'react-pro-sidebar';
function AdminLayout(){
    const [openSidebar, setOpenSidebar] = useState(false);
    const { collapseSidebar, collapsed } = useProSidebar(true);
    return (
        <div className="wrapper">
            <div style={{ display: 'flex', height: '100%' }}>
                <div className="sidebar" style={{ position: "fixed" }}>
                    <AdminSidebar />
                </div>
                <main className="block w-full">
                     <div className={` ${
                          collapsed ? "pl-20" : "pl-64"
                        } content-area duration-300 pb-8`}>
                        <div className="page-header px-6 pt-8 relative">
                            <Topbar />
                            <AdminRoutes/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
export default AdminLayout;