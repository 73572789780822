import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';
import Loading from '../../../Components/Loading';

function UserReports(){

        const {id} = useParams();
        const {http} = AuthUser();
        const [AppointmentData, setAppointmentData] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

        const [dataRelaxation, SetRelaxation] = useState([]);
        const [dataRemedial, SetRemedial] = useState([]);
        const [dataMyotherapy, SetMyotherapy] = useState([]);

        const [statusRelaxation, SetStatusRelaxation] = useState([]);
        const [statusRemedial, SetStatusRemedial] = useState([]);
        const [statusMyotherapy, SetStatusMyotherapy] = useState([]);

        const [clientReports, SetClientReports] = useState([]);
        const [statusClient, SetStatusClient] = useState([]);

        const [data, setdata] = useState([]);
         const [User, setUsers] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
          try {
            const response = await http.get(`/user/${id}/reports`);
            console.log('data',data);
            if(response.data.dataRole === 'both'){
                    setdata(response.data.CombinedData.user);
                    SetClientReports(response.data.CombinedData.data)
                    SetStatusClient(response.data.CombinedData.clientbookingstatus);
                    SetRelaxation(response.data.CombinedData.appointmentsData['1']);
                    SetRemedial(response.data.CombinedData.appointmentsData['2']);
                    SetMyotherapy(response.data.CombinedData.appointmentsData['3']);
                    SetStatusRelaxation(response.data.CombinedData.studentbookingstatus['1']);
                    SetStatusRemedial(response.data.CombinedData.studentbookingstatus['2']);
                    SetStatusMyotherapy(response.data.CombinedData.studentbookingstatus['3']);
            }else if(response.data.Role === 'client'){
                setdata(response.data.user);
                SetClientReports(response.data.data)
                SetStatusClient(response.data.clientbookingstatus);
            }else{
                setdata(response.data.user);
                SetRelaxation(response.data.appointmentsData['1']);
                SetRemedial(response.data.appointmentsData['2']);
                SetMyotherapy(response.data.appointmentsData['3']);
                SetStatusRelaxation(response.data.studentbookingstatus['1']);
                SetStatusRemedial(response.data.studentbookingstatus['2']);
                SetStatusMyotherapy(response.data.studentbookingstatus['3']);
            }
            setIsLoading(false)
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    useEffect(() => {
        async function fetchData1() {
          try {
                setIsLoading(true);
                const response =  await http.get(`auth/appointment/user/${id}`);
                const data = await response.data;
                setAppointmentData(data);
          } catch (error) {
              toast.error(error.response);
          }
        } 
        setIsLoading(false);
        fetchData1();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


       useEffect(() => {
        const fetchData = async (userId) => {
          try {
            setIsLoading(true);
            const userResponse = await http.get(`auth/user/${userId}`);
            const data = userResponse.data.data;
            setUsers(data); 
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        };
        fetchData(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);
       
    const topNav = [
        {
            name: "User Profile",
            url: getAdminUrl(`user/edit/${id}`)
        },
        {
            name: "User Reports",
            url: getAdminUrl(`user/reports/${id}`)
        },
        {
            name: "History/Log",
            url: getAdminUrl(`user/sms-log/${id}`)
        }
    ]

  const Studentcolumns = [
            {
                name: 'Date',
                selector: row => row.appointment_date,
            },
            {
                name: 'Time',
                selector: row => row.time,
            },
            {
                name: 'Client',
                selector: row =>  <> 
                    <Link className="underline font-semibold" target="_blank" to={"/admin/user/client/edit/" + row.clientId }>{row.client}</Link>
                </>,
            },
            {
                name: 'Client Booked',
                selector: row => row.Booked
            },
            {
                name: 'Status',
                selector: row => row.status,
            },
            {   
                name:"Action",
                cell: (row) => (
                    <>
                        <Link to={"/admin/edit-appointment/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
                    </>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ];
    
     const Clientcolumns = [
            {
                name: 'Date',
                selector: row => row.appointment_date,
            },
            {
                name: 'Time',
                selector: row => row.time,
            },
            {
                name: 'Massage Type',
                selector: row => row.massage_type,
            },
            {
                name: 'Status',
                selector: row => row.status,
            },
            {
                name: 'Student',
                selector: row =>  <> 
                    <Link className="underline font-semibold" target="_blank" to={"/admin/user/student/edit/" + row.studentId }>{row.student}</Link>
                </>,
            },
            {   
                name:"Action",
                cell: (row) => (
                    <>
                        <Link to={"/admin/edit-appointment/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
                    </>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ];
 

    return(
        <>
        {isLoading && <Loading /> }
            <div className="user-single-head">
                <div className="flex gap-4 mb-8 w-full">
                    <div className="w-6/12 client-info">
                        <h4 className="capitalize">{data.user_type_primary}</h4>
                        <h2 className="text-white">{data.first_name + ' ' + data.last_name}</h2>
                        <div className="status-bar">
                            <span>{data.user_status}</span>
                            <span>{data.phone_number}</span>
                        </div> 
                    </div>
                    <div className="w-6/12">
                        <div className="flex gap-4">

                        {data.user_type_primary === 'student' ?(
                            <div className="white-box card-box w-4/12">
                               <h4>Target Number of Clinics</h4>
                               {/*<h2>{User.selected_services_count}</h2>*/}
                               <h2>{(() => {
                                                const serviceId = User.massage_type; 
                                                let counts = User.selected_services_count; 
                                                console.log('counts', counts);
                                                if (typeof counts === 'string') {
                                                    try {
                                                        counts = JSON.parse(counts);
                                                    } catch (error) {
                                                        console.error('Error parsing JSON:', error);
                                                        return 0; 
                                                    }
                                                }
                                                if (typeof counts === 'object' && counts !== null) {
                                                    console.log('counts if', counts);
                                                    return counts[serviceId] !== undefined ? counts[serviceId] : 0; 
                                                } else if (typeof counts === 'number') {
                                                    console.log('counts else', counts);
                                                    return counts;
                                                } else {
                                                    console.log('counts else', counts);
                                                    return 0; 
                                                }
                                            })()}</h2>
                            </div>
                            ):(
                            <div className="white-box card-box w-4/12">
                               <h4>Total Scheduled</h4>
                               <h2>{AppointmentData.scheduledAppointments}</h2>
                            </div>
                            )}
                            <div className="white-box card-box w-4/12">
                                <h4>Total Completed</h4>
                                <h2>{AppointmentData.completedAppointments}</h2>
                            </div>
                            <div className="white-box card-box w-4/12">
                                <h4>Total Cancelled</h4>
                                <h2>{AppointmentData.cancelledAppointments}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>

                <TopNav data={topNav} />

                {data.user_type_primary === 'student' &&
                    <div className="white-box p-0 mb-4">
                        <div className="flex flex-wrap py-8 px-4">
                            {data.user_type_primary === 'student' && dataRelaxation.length > 0 &&
                                <div className="reports-table w-6/12 px-4 mb-6">
                                    <h2 className="mb-6">{dataRelaxation[0].massage_type}</h2>
                                    <div className="grid grid-flow-col auto-cols-max gap-8 mb-6">
                                        <div>
                                            <h4>Active: {statusRelaxation.active}</h4>
                                        </div>
                                        <div>
                                            <h4>Completed: {statusRelaxation.completed}</h4>
                                        </div>
                                        <div>
                                            <h4>Cancelled: {statusRelaxation.Cancelled?statusRelaxation.Cancelled:0}</h4>
                                        </div>
                                    </div>
                                    <div className="table-section">
                                        <DataTable
                                            columns={Studentcolumns}
                                            className="table-title-style"
                                            data={dataRelaxation}
                                            progressPending={isLoading}
                                        />
                                        <div className="total-row py-4 px-4 bg-greycolor9">
                                            {dataRelaxation.length !==0 ? 
                                                <>Total No: {Object.keys(dataRelaxation).length}</>
                                                : <>Total No: 0</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {data.user_type_primary === 'student' && dataRemedial.length > 0 &&
                                <div className="reports-table w-6/12 px-4 mb-6">
                                    <h2 className="mb-6">{dataRemedial[0].massage_type}</h2>
                                    <div className="grid grid-flow-col auto-cols-max gap-8 mb-6">
                                        <div>
                                            <h4>Active: {statusRemedial.active}</h4>
                                        </div>
                                        <div>
                                            <h4>Completed: {statusRemedial.completed}</h4>
                                        </div>
                                        <div>
                                            <h4>Cancelled: {statusRemedial.Cancelled?statusRemedial.Cancelled:0}</h4>
                                        </div>
                                    </div>
                                    <div className="table-section">
                                        <DataTable
                                            columns={Studentcolumns}
                                            className="table-title-style"
                                            data={dataRemedial}
                                            progressPending={isLoading}
                                        />
                                        <div className="total-row py-4 px-4 bg-greycolor9">
                                            {dataRemedial.length !==0 ? 
                                                <>Total No: {Object.keys(dataRemedial).length}</>
                                                : <>Total No: 0</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {data.user_type_primary === 'student' && dataMyotherapy.length > 0 &&
                                <div className="reports-table w-6/12 px-4">
                                    <h2 className="mb-6">{dataMyotherapy[0].massage_type}</h2>
                                    <div className="grid grid-flow-col auto-cols-max gap-8 mb-6">
                                        <div>
                                            <h4>Active: {statusMyotherapy.active}</h4>
                                        </div>
                                        <div>
                                            <h4>Completed: {statusMyotherapy.completed}</h4>
                                        </div>
                                        <div>
                                            <h4>Cancelled: {statusMyotherapy.Cancelled?statusMyotherapy.Cancelled:0}</h4>
                                        </div>
                                    </div>
                                    <div className="table-section">
                                        <DataTable
                                            columns={Studentcolumns}
                                            className="table-title-style"
                                            data={dataMyotherapy}
                                            progressPending={isLoading}
                                        />
                                        <div className="total-row py-4 px-4 bg-greycolor9">
                                            {dataMyotherapy.length !== 0 ? 
                                                <>Total No: {Object.keys(dataMyotherapy).length}</>
                                                : <>Total No: 0</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {dataRelaxation.length === 0 && dataRemedial.length === 0 && dataMyotherapy.length === 0 &&
                                <div className="w-full"><h4 className="text-center text-black">There are no records to display</h4></div>
                            }
                        </div>
                    </div>
                }

                {clientReports.length > 0 &&
                    <div className="white-box p-0">
                        <div className="flex flex-wrap p-0">
                            <div className="heading flex w-full mb-6 pt-8 px-8">
                                <h2>User Reports: Client</h2>
                                <div className="grid grid-flow-col auto-cols-max gap-8 ml-auto">
                                    <div>
                                        <h4 className="text-darkgrey1 text-sm ">Active: {statusClient.active}</h4>
                                    </div>
                                    <div>
                                        <h4 className="text-darkgrey1 text-sm ">Completed: {statusClient.completed}</h4>
                                    </div>
                                    <div>
                                        <h4 className="text-darkgrey1 text-sm">Cancelled: {statusClient.Cancelled?statusClient.Cancelled:0}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full pb-4">
                                <div className="table-section">
                                    <DataTable
                                        columns={Clientcolumns}
                                        className="table-title-style"
                                        data={clientReports}
                                        progressPending={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
export default UserReports;