import React, { useState, useEffect } from 'react';
import {
  Input
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../../helpers/AuthUser';
import * as Yup from 'yup';

import {useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function EditSpecialNeeds() {
  const {http} = AuthUser();
    const navigate = useNavigate();
    const {id} = useParams();
    const [data, setData] = useState([]);
    const { handleSubmit,errors, handleBlur,touched,setFieldValue } = useFormik({
        initialValues: {
          title: '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Required').min(2).max(50),
        }),
      onSubmit : async (values) => {
        const data = {...values,id:id}
        await http.put(`auth/needs/${id}`,JSON.stringify(data, null, 2)).then((result)=>{
          if (result.data['status'] === true) {
              toast.success("Client notes updated successfully!");
              navigate("/admin/services/special-needs");
             }
          }).catch(err => {
            toast.error('Something wrong!');
         });
       }
    });

      useEffect(() => {
          async function fetchData() {
          try {
              const response =  await http.get(`auth/needs/${id}`);
              const data = await response.data.data;
              setData(data)
              const fields = ['id','title'];
              fields.forEach(field => setFieldValue(field, data[field], false));
          } catch (error) {
            toast.error('An error occur!');
          }
        }
      fetchData();
    }, []);


    const DeleteBtn = (id) => {
        http.delete(`auth/needs/${id}`).then((id) => {
            toast.success("Delete successful");
            navigate("/admin/services/special-needs");
        }).catch(err => {
          toast.error('Something wrong!');
       });
    }
 
 return(
      <div>
		   <div className="white-box">
              	<div className="heading mb-3">
                  	<h2>Edit Client Notes</h2>
              	</div>
                <form onSubmit={handleSubmit}>
                	<div className="row">
                      	<div className="flex mb-8 w-full">
                            <div className="form-input w-3/12 px-4">
                                  <Input
                                    autoComplete="title"
                                    name="title"
                                    variant="static"
                                    fullwidth={"true"}
                                    id="title"
                                    label="Name"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.title} 
                                    onChange={e => {
                                      setData({});
                                      setFieldValue(e.target.name,e.target.value);
                                     }}
                                    onBlur={handleBlur}
                                />
                                {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                            </div>
                      	</div>
                 	</div>
                 	<div className="bottom-button-group flex gap-4 flex-wrap justify-end">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Update Client Notes</button>
                        <button onClick={(event) => { DeleteBtn(id)} } className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Delete</button>
                    </div>
                </form>
                <Toaster />
           	</div>
        </div>
	)
}

export default EditSpecialNeeds;