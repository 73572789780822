import { Routes, Route} from "react-router-dom";
import Home from '../../Pages/Home';
import MakeBooking from '../../Client/MakeBooking';
import BookingConfirmation from '../../Client/BookingConfirmation';
import ClientUpcomingBookings from '../../Client/ClientUpcomingBookings';
import ClientPastBookings from '../../Client/ClientPastBookings';
import ClientGiftCards from '../../Client/ClientGiftCards';
import GTM from '../GTM';

export default function ClinicRoutes(){
    return (
        <>
            <GTM />
            <Routes>
                <Route exact path={"/"} element={<Home />}/>
                <Route exact path={"/make-booking"} element={<MakeBooking />} />
                <Route exact path={"/booking-confirmation"} element={<BookingConfirmation />} />
                <Route exact path={"/upcoming-bookings"} element={<ClientUpcomingBookings />} />
                <Route exact path={"/past-bookings"} element={<ClientPastBookings />} />
                <Route exact path={"/gift-cards"} element={<ClientGiftCards />} />
            </Routes>
        </>
    );
}