import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Typography} from "@material-tailwind/react";
import AuthUser from '../helpers/AuthUser';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import 'react-calendar/dist/Calendar.css'
import TopNav from '../Components/TopNav/TopNav';
import useBookingData from '../helpers/api/useBookingData';

function Icon({ id, open }) {
  return (
    <span className={`${ id === open ? "opacity-0" : "" } transition-transform`}>Read more…</span>
  );
}
const stripePromise = loadStripe("pk_live_51IpNMjBQYf8AEnjL2CLLLw77I9mLKvT5XrT44wOnI3K6ubQp3YnEgzun3d692U5svXwtcXWe9pv1GiYv9WqMJUiJ00JdV7aP3S");
// const stripePromise = loadStripe("pk_test_51MzJYXGam68L0P2s2kg1ZGurbEEZxO2Uc2GxvMi9WzVKIX2cDX9JiOPT8JWsF9uXMCBwRc3yFNChxNJdmI67XNPe00F2HF0tOK");

function MakeBooking(props){
    const {user}=AuthUser();
    const [open, setOpen] = useState(0);
    const data = useBookingData(); 
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const topNav = [
        {
            name: "Make a Booking",
            url: '/make-booking'
        },
        {
            name: "Upcoming Bookings",
            url: '/upcoming-bookings'
        },
        {
            name: "Past Bookings",
            url: '/past-bookings'
        },
        {
            name: "Gift Cards",
            url: '/gift-cards'
        },
        ...(user.user_type_secondary != null
        ? [
        {
            name: "Switch to Student",
            url: '/student/enter-availability'
        }
        ]
        : [])
    ]

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            {data &&
            <div className="max-w-screen-lg mx-auto">

                <Accordion className="mb-7 important-note" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(1)} className={ open ? "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" : "active-accordion font-normal text-base text-left border-none rounded-xl bg-greycolor7 p-6 block md:flex" }>
                    {data.acfb_sttitle}
                    </AccordionHeader>
                    <AccordionBody className={ open ? "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" : "border-radius-top-none font-normal text-base border-none rounded-xl bg-greycolor7 px-6 pb-5 pt-0 md:p-6" }>
                      {data.acfb_statement};
                    </AccordionBody>
                </Accordion>
                <Typography variant="h2" className="text-center mb-6 font-normal">{data.acfb_welcometitle}</Typography>

                <div dangerouslySetInnerHTML={{ __html: data.acfb_intro }}></div>
                
                <div className="client-nav">
                    <TopNav data={topNav} />
                </div>
            
                {stripePromise && (
                    <Elements stripe={stripePromise}>
                      <CheckoutForm acfbterms={data}/>
                    </Elements>
                )}
            </div>
        }
        </main>
    );
}
export default MakeBooking;