import { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
import { Link } from 'react-router-dom';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';

const topNav = [
    {
        name: "View All",
        url: getAdminUrl('services')
    },
    {
        name: "Client Notes",
        url: getAdminUrl('services/special-needs')
    },
    {
        name: "Add New Booking Type",
        url: getAdminUrl('services/add-new-massage-type'),
        button: [
            {
                name: "Add New Booking Type"
            }
        ]
    }
]


export default function Services(){
    const {http}=AuthUser();
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState(false);
    const [services, setServices] = useState([]);

    useEffect(() => {
        GetServices()
    }, [])

    const GetServices = async() => {
     setLoading(true);
     await http.get('auth/services').then((resp)=>{
        setServices(resp.data.data);
        setLoading(false);
     }).catch(error => {
        setError(true);
      });   
    }

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Price',
            selector: row => '$' + row.price,
        },
        {
            name: 'Promo Price',
            selector: row => row.promo_price,
        },
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/service/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    return (
        <>
            <TopNav data={topNav} />

            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>All</h2>
                            <div className="input-middle">
                            </div>
                            <div className="status-bar">
                            </div>
                        </div>
                    </div>

                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={services}
                            className="table-title-style"
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}