import { useEffect } from 'react';

const GTM = () => {
  useEffect(() => {
    // Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-59X9374';
    document.head.appendChild(gtmScript);

    // Google Tag Manager noscript
    const gtmNoscript = document.createElement('noscript');
    gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-59X9374"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(gtmNoscript);

    return () => {
      // Cleanup scripts on component unmount
      document.head.removeChild(gtmScript);
      document.body.removeChild(gtmNoscript);
    };
  }, []);

  return null; // No need to render anything
};

export default GTM;
