import React, {useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPencil
} 
from '@fortawesome/free-solid-svg-icons'; 
import AuthUser from '../../helpers/AuthUser';
import Datepicker from "react-tailwindcss-datepicker";
import ClinicRemedial from '../Components/ClinicView/ClinicRemedial';
import ClinicMyotherapy from '../Components/ClinicView/ClinicMyotherapy';
import ClinicRelaxation from '../Components/ClinicView/ClinicRelaxation';
import Loading from '../../Components/Loading';
export default function NextAvailableDay(){

    const {http} = AuthUser();
    const [selected_date, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    const [Data, setData] = useState([]);
    const [nextavailable, setNextAvailable] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleValueChange = (selecteddate) => {
        setValue(selecteddate);
        let parms = {
            selected_date: selecteddate.startDate
          };
         GetRelaxation(parms)
    }
  
    const GetRelaxation = async (params) => {
        setLoading(true);
        if(params){
            await http.post('search/clinic-day',params).then((resp)=>{
                setData(resp.data);
                setLoading(false);
            }).catch(error => {
                console.log(error.response.data.message)
                setData([]);
            });
        }else{
            setLoading(true);
            await http.post('search/clinic-day').then((resp)=>{
                setData(resp.data);
                setLoading(false);
            }).catch(error => {
                console.log(error.response.data.message)
                setData([]);
            });
        }
    }
    
    useEffect(() => {
        GetRelaxation();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
         fetchData();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.location.search]);
      
      const hasParameters = window.location.search !== '';
      const fetchData = async () => {
        if (hasParameters === true) {
            setLoading(true);
          http.get('search/next-avilable-appointments')
          .then(response => {
             setData(response.data);
             setNextAvailable(true)
             setLoading(false);
          })
          .catch(error => {
             console.error(error);
          });
        }else{
            setData();
            setNextAvailable(false)
        }
    };

    return (
        
        <div className="white-box relative">
            { isLoading && <Loading /> }
            <div className="flex mb-14">
                <h2>Clinic Day View</h2>
                  <div className="w-4/12 ml-auto flex justify-end gap-6">
                     <h2>Next Available</h2> 
                     </div>
                    <div className="w-5/12 ml-auto flex justify-end gap-6">
                     
                        <div className="form-input w-6/12">
                            <Datepicker 
                                placeholder={"Date"} 
                                useRange={false} 
                                displayFormat={"DD/MM/YYYY"}
                                asSingle={true} 
                                value={selected_date} 
                                onChange={handleValueChange} 
                            />
                        </div>
                    </div>

            </div>
            <div className="grid grid-cols-3 gap-8 mb-16">
                <div className="relaxation-table">
                    <h3 className="mb-6">Relaxation/Basic Treatment</h3>
                      <ClinicRelaxation ClinicRelaxation={Data} Nextavailable={nextavailable} />
                  </div>
                <div className="remedial-table">
                    <h3 className="mb-6">Remedial/Deep Tissue Treatment</h3>
                        <ClinicRemedial ClinicRemedial={Data} Nextavailable={nextavailable} />
                 </div>
                <div className="myotherapy-table">
                    <h3 className="mb-6">Myotherapy/Advanced Treatment</h3>
                     <ClinicMyotherapy ClinicMyotherapy={Data} Nextavailable={nextavailable}/>
                 </div>
            </div>
            <div className="flex flex-auto gap-8 mb-10">

                <div className="flex">
                    M = Male
                </div>
                <div className="flex">
                    F = Female
                </div>
                <div className="flex">
                    I = Others
                </div>
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faPencil} className="mr-1" /> = Edit
                </div>
                <div className="flex text-red-600">
                    DP = Deposit Paid
                </div>
                <div className="flex text-green text-green-600">
                    FP = Full Payment
                </div>
                <div className="flex text-red-600">
                    PR = Payment Required
                </div>
            </div>
        </div>
    );
} 
