import { Routes, Route } from "react-router-dom";
import { getAdminUrl } from "../../helpers/Utils";
import AuthUser from '../../helpers/AuthUser';

import Dashboard from "../../Admin/Pages/Dashboard";
import ClinicDay from "../../Admin/Pages/ClinicDay";
import NextAvailableDay from "../../Admin/Pages/NextAvailableDay";

import AddUser from "../../Admin/Pages/Users/AddUser";
import EditUser from "../../Admin/Pages/Users/EditUser";
import UserReports from "../../Admin/Pages/Users/UserReports";
import UserSMSReports from "../../Admin/Pages/Users/UserSMSReports";
import Appointments from "../../Admin/Pages/Appointments";
import EditAppointment from '../../Admin/Components/Appointments/EditAppointment';
import AddAppointment from '../../Admin/Components/Appointments/AddAppointment';
import BookStudent from '../../Admin/Components/Appointments/BookStudent';

import Relaxation from '../../Admin/Components/Appointments/Relaxation';
import Remedial from '../../Admin/Components/Appointments/Remedial';
import Myotherapy from '../../Admin/Components/Appointments/Myotherapy';

import ViewAllocation from '../../Admin/Pages/Allocations/ViewAllocation';
import ViewAllocationReport from '../../Admin/Pages/Allocations/ViewAllocationReport';
import EditViewAllocation from '../../Admin/Pages/Allocations/EditViewAllocation';
import CustomAllocation from '../../Admin/Pages/Allocations/CustomAllocation';
import EditCustomAllocation from '../../Admin/Pages/Allocations/EditCustomAllocation';

import Sms from "../../Admin/Pages/Sms";
import SendSMS from "../../Admin/Components/Sms/SendSMS";
import Reminders from "../../Admin/Components/Sms/Reminders";

import GiftCards from "../../Admin/Pages/Gift";
import CreateNewGift from "../../Admin/Components/Gift/CreateNewGift";
import EditGift from "../../Admin/Components/Gift/EditGift";

import GiftServices from "../../Admin/Components/Gift/Services";
import AddNewService from "../../Admin/Components/Gift/Services/AddNew";
import EditGiftService from "../../Admin/Components/Gift/Services/EditGift";

import Users from "../../Admin/Pages/Users/Users";
import Clients from "../../Admin/Pages/Users/Clients/Clients";
import Students from "../../Admin/Pages/Users/Students/Students";
import Admin from "../../Admin/Pages/Users/Admin";

import Services from "../../Admin/Pages/Services/Services";
import AddNewMassageType from "../../Admin/Pages/Services/AddNewMassageType";
import EditMassageType from "../../Admin/Pages/Services/EditMassageType";

import SpecialNeeds from "../../Admin/Pages/Services/SpecialNeeds";
import AddNewSpecialNeeds from "../../Admin/Pages/Services/SpecialNeeds/AddNew";
import EditSpecialNeeds from "../../Admin/Pages/Services/SpecialNeeds/Edit";

import Availabilities from "../../Admin/Pages/Availability/Availabilities";
import AddNewAvailability from "../../Admin/Pages/Availability/AddNewAvailability";
import EditAvailability from "../../Admin/Pages/Availability/EditAvailability";

import HomeEdit from "../../Admin/Pages/Frontend/Home";
import MakeBookingEdit from "../../Admin/Pages/Frontend/MakeBooking";

export default function AdminRoutes() {
    const { user } = AuthUser();
    const isAdmin = user.user_type_primary === 'admin';
    const isEditor = user.user_type_primary === 'editor';
    
    const authorizedRoutes = [
        { path: getAdminUrl('dashboard'), element: isAdmin && <Dashboard /> },
        { path: getAdminUrl('clinic-day'), element: (isAdmin || isEditor) && <ClinicDay /> },
        { path: getAdminUrl('clinic-day/next-available'), element: (isAdmin || isEditor) && <NextAvailableDay /> },
        { path: getAdminUrl('appointments'), element: (isAdmin) && <Appointments /> },
        { path: getAdminUrl('add-appointment'), element: isAdmin && <AddAppointment /> },
        { path: getAdminUrl('book-student'), element: isAdmin && <BookStudent /> },
        { path: getAdminUrl('edit-appointment/edit/:id'), element: (isAdmin || isEditor ) && <EditAppointment /> },
        { path: getAdminUrl('appointments/relaxation'), element: (isAdmin) && <Relaxation />  },
        { path: getAdminUrl('appointments/remedial'), element: (isAdmin) && <Remedial /> },
        { path: getAdminUrl('appointments/myotherapy'), element: (isAdmin) && <Myotherapy /> },
        { path: getAdminUrl('view-allocation-report'), element: isAdmin && <ViewAllocationReport /> },
        { path: getAdminUrl('view-allocation'), element: isAdmin && <ViewAllocation /> },
        { path: getAdminUrl('view-allocation/edit/:id'), element: isAdmin && <EditViewAllocation /> },
        { path: getAdminUrl('view-custom-allocation'), element: isAdmin && <CustomAllocation /> },
        { path: getAdminUrl('view-custom-allocation/edit/:id'), element: isAdmin && <EditCustomAllocation /> },
        { path: getAdminUrl('sms'), element: isAdmin && <Sms /> },
        { path: getAdminUrl('sms/new-sms'), element: isAdmin && <SendSMS /> },
        { path: getAdminUrl('sms/reminders'), element: isAdmin && <Reminders /> },
        { path: getAdminUrl('gift'), element: isAdmin && <GiftCards /> },
        { path: getAdminUrl('gift/create-new'), element: isAdmin && <CreateNewGift /> },
        { path: getAdminUrl('gift/edit/:id'), element: isAdmin && <EditGift /> },
        { path: getAdminUrl('gift/services'), element: isAdmin && <GiftServices /> },
        { path: getAdminUrl('gift/services/add-new'), element: isAdmin && <AddNewService /> },
        { path: getAdminUrl('gift/service/edit/:id'), element: isAdmin && <EditGiftService /> },
        { path: getAdminUrl('users'), element: (isAdmin) && <Users /> },
        { path: getAdminUrl('users/clients'), element: (isAdmin) && <Clients /> },
        { path: getAdminUrl('users/students'), element: (isAdmin) && <Students /> },
        { path: getAdminUrl('users/admin'), element: (isAdmin) && <Admin /> },
        { path: getAdminUrl('add-user'), element: isAdmin && <AddUser /> },
        { path: getAdminUrl('user/edit/:id'), element: (isAdmin) && <EditUser /> },
        { path: getAdminUrl('user/reports/:id'), element: (isAdmin ) && <UserReports /> },
        { path: getAdminUrl('user/sms-log/:id'), element: (isAdmin ) && <UserSMSReports /> },
        { path: getAdminUrl('services'), element: isAdmin && <Services /> },
        { path: getAdminUrl('services/add-new-massage-type'), element: isAdmin && <AddNewMassageType /> },
        { path: getAdminUrl('service/edit/:id'), element: isAdmin && <EditMassageType /> },
        { path: getAdminUrl('services/special-needs'), element: isAdmin && <SpecialNeeds />},
        { path: getAdminUrl('services/special-needs/add-new'), element: isAdmin && <AddNewSpecialNeeds /> },
        { path: getAdminUrl('service/special-needs/edit/:id'), element: isAdmin && <EditSpecialNeeds /> },
        { path: getAdminUrl('availability'), element: isAdmin && <Availabilities /> },
        { path: getAdminUrl('availability/add-new-availability'), element: isAdmin && <AddNewAvailability /> },
        { path: getAdminUrl('availability/edit/:id'), element: isAdmin && <EditAvailability /> },
        { path: getAdminUrl('edit/home'), element: isAdmin && <HomeEdit /> },
        { path: getAdminUrl('edit/make-booking'), element: isAdmin && <MakeBookingEdit /> },
    ];
    
    return (
        <Routes>
          {authorizedRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
    );
}
