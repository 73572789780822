import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import AuthUser from '../../../helpers/AuthUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPencil
} 
from '@fortawesome/free-solid-svg-icons'; 

const getPriceBackgroundColor = (price) => {
    if (price.includes("PR")) {
        return '#F6E4FF'; // Tailwind CSS class for red background
    } 
};

const getPriceClassName = (price,status) => {
  if (price.includes("AW") || price.includes("DP") || price.includes("PR")) {
    return "text-red-600";
  }else if(status === 'Completed' || status == 'completed') {
    return "text-purple-600";
  }else{
    return "text-green-600";
  }
};

const conditionalRowStyles = [
    {
        when: row => row.client === 'OPEN',
        style: {
          backgroundColor: '#f4f9f1',
          color: 'green',
        },
    },
    {
        when: row => row.status === 'Cancelled by ACFB',
        style: {
          opacity: 0.5,
        },
    },
    {
        when: row => row.status === 'Cancelled by Student',
        style: {
          opacity: 0.5,
        },
    },
    // {
    //     when: (row) => true,
    //     style: (row) => ({
    //         backgroundColor: getPriceBackgroundColor(row.price),
    //     }),
    // }
    {
        when: row => row.status === 'Completed',
        style: {
          backgroundColor: '#F6E4FF',
        },
    }
]

const customStyles = {
  headRow: {
      style: {
          border: 'none',
      },
  },
  headCells: {  
      style: {
          color: '#202124',
          fontSize: '14px',
      },
  },
  rows: {
      style: {
        border: 'none',
      },
      highlightOnHoverStyle: {
          backgroundColor: 'rgba(255, 226, 213, 0.3)',
          borderBottomColor: 'rgba(0,0,0,.12)',
          borderRadius: '0px',
          outline: '0 solid #FFFFFF',
      },
  },
  pagination: {
      style: {
          border: 'none',
      },
  },
};

const ClinicMyotherapy = (props) => {
    const {user}=AuthUser();
    const {ClinicMyotherapy, Nextavailable} = props;
    const data = ClinicMyotherapy && ClinicMyotherapy.data;
    const TotalCost = ClinicMyotherapy && ClinicMyotherapy.total_Price;
    const MyotherapyValue = data && data['3'];
    const TotalCostValue = TotalCost && TotalCost['3'];

    const columns = [
        {
            name: 'Time',
            selector: row =>  <div className="flex flex-col"> { Nextavailable && <div>{ moment(row.appointment_date).format('DD/MM/YYYY')}</div> } <div>{row.time}</div></div>,
        },
        {
            name: 'Student',
            selector: row => 
                <div className="flex flex-row items-center">
                {row.empty_student ?(
                    <span className="capitalize">{row.empty_student}</span>
                ) : (<>
                    { row.gender ? (<>
                            <div style={{ minWidth: '12px' }}>
                                <b>{row.gender}</b>
                            </div>
                        </>) : (
                            <span className="mr-1 w-4"></span>
                        )
                    } 
                    { user.user_type_primary === 'editor' ? ( 
                            <span className="capitalize pl-1">{row.student}</span> 
                        ) : ( 
                            <span className="capitalize pl-1"><Link target="_blank" className="break-all" to={"/admin/user/edit/" + row.student_id }>{row.student}</Link></span>
                    )}
                </>)}
                </div>,
        },
        {
            name: 'Client',
            selector: row => <>
                {row.client === 'CLOSED' ?(
                    "No Client"
                ) : (<>
                    {user.user_type_primary === 'editor' ? (
                         <>{row.AstricFirst && <span className="mr-1">{row.AstricFirst}</span>}{row.client}</>
                    ) : (
                        <Link target="_blank" className="break-all flex" to={"/admin/user/edit/" + row.client_id }>{row.AstricFirst && <span className="mr-1">{row.AstricFirst}</span>}{row.client}</Link>
                    )}
                </>)
                }</>,
        },
        {
            name: 'Client Notes',
            selector: row => row.special_needs,
        },
        {
            name: 'Notes',
            selector: row => row.notes,
        },
        {
            name: 'Amount',
            selector: row => <span className={getPriceClassName(row.price,row.status)}>{row.price}</span>
        },
        {
            name:"",
            cell: (row) => (
              <>
                <Link to={"/admin/edit-appointment/edit/" + row.id } className='btn btn-primary table-btn-link'><FontAwesomeIcon icon={faPencil} /></Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];


    return ( 
        <>                      
            <DataTable
                columns={columns}
                data={MyotherapyValue}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                selectableRows={false}
                conditionalRowStyles={conditionalRowStyles}
            />
            <div className="bottom-table-row flex p-3 xl:px-6 xl:py-4 justify-between">
              {MyotherapyValue ? (
                <span>Total No: {MyotherapyValue.filter(item => item.client_id).length}</span>
                ) : (
                <span>Total No: 0</span>
                )}
                <span>Total Amount: ${TotalCostValue}</span>
            </div>
        </>
    )
}
export default ClinicMyotherapy;
