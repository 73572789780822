import React, { useState, useEffect } from 'react';
import {
  Input,
  Checkbox,
  Radio,
  Typography,
  Textarea
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../helpers/AuthUser';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import CustomSelect from '../../../helpers/CustomSelect';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';

import showPwdImg from '../../../images/show-password.svg';
import hidePwdImg from '../../../images/hide-password.svg';
import {usertype, usertype2, uservaccinated, genderpreference, userstatus, usergenderuser} from '../../../helpers/appointmentOptions';
const topNav = [
    {
        name: "User Profile",
        url: getAdminUrl('add-user')
    }
]

function AddUser() {
    const navigate = useNavigate();
    const {getSpecialNeedOptions, http}=AuthUser();

    const [selectOptions, setNeeds] = useState([]);
    const [users, setUsers] = useState([]);
    const [password, setPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [noBlockrestriction, setBlockrestriction] = useState(false);
    const defaultValue = usertype[0].value;
    const defaultGenderValue = genderpreference[0].value;
    const [selectedPrimaryType, setSelectedPrimaryType] = useState(defaultValue);
    const [selectedGenderType, setSelectedGenderType] = useState(defaultGenderValue);


    const handleAcceptTermsChange = (e) => {
        setAcceptTerms(e.target.checked);
        setFieldValue('acceptTerms', e.target.checked);
    };

    useEffect(() => {
        getSpecialNeedOptions().then((resp)=>{
            setNeeds(resp.data.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[]);


    const specialoptions = selectOptions.map(d => ({
        "value" : d.id,
        "label" : d.title
    }))

    const handlePrimaryTypeChange = (value) => {
        setFieldValue('user_type_primary', value.value);
        setSelectedPrimaryType(value.value);
    };

    const handleGenderPref = (value) => {
        setFieldValue('gender_preference', value.value);
        setSelectedGenderType(value.value);
    };

    useEffect(() => {
        UsersGet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const UsersGet = () => {
     http.get('auth/services').then((resp)=>{
        setUsers(resp.data.data);
     });  
    }

    const generatePassword = () => {
        const length = 10;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        let generatedPassword = '';

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          generatedPassword += charset.charAt(randomIndex);
        }
        return generatedPassword;
    };
    const handleGeneratePassword = () => {
        const generatedPassword = generatePassword();
        setPassword(generatedPassword);
        setFieldValue('password',generatedPassword);
    };

    const handleChangePwd =(e) =>{
        setPassword(e.target.value);
        setFieldValue('password',e.target.value);
    }
    const togglePassword = () => {
        setIsRevealPwd(prevState => !prevState)
    };

    const handleRestrictionBlockChange = (e) => {
        setBlockrestriction(e.target.checked);
        setFieldValue('noBlockrestriction', e.target.checked);
    };

   

    const { handleSubmit, errors, touched, getFieldProps ,setFieldValue } = useFormik({
        initialValues: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          phone_number: '',
          user_status: '',
          gender: '',
          user_type_primary: selectedPrimaryType,
          user_type_secondary: '',
          vaccinated: '',
          gender_preference: selectedGenderType,
          special_id: '',
          massage_type: '',
          acceptTerms: '',
          noBlocknotes:'',
        },
        validationSchema: Yup.object({
          first_name: Yup.string().min(3, 'Min. 3 characters').required('Required'),
          last_name: Yup.string().min(3, 'Min. 3 characters').required('Required'),
          email: Yup.string().email('It should be a valid email').required('Required'),
          password: Yup.string().min(6, 'Min. 6 characters').required('Required'),
          gender: Yup.string().required('Required'),
          user_status: Yup.string().required('Required'),
          user_type_primary: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            if (!acceptTerms && selectedPrimaryType === 'client') {
                toast.error('Please accept Terms and Conditions');
                return;
            } else {
                if(selectedPrimaryType === 'student'){
                    if(values.selected_services_count === null){
                        values = {...values, selected_services_count:0}
                    }else{
                        values = {...values, selected_services_count:values.selected_services_count[values.massage_type]}
                    }
                 }else{
                  values = {...values}
                }
                http.post('auth/register',JSON.stringify(values, null, 2)).then((res)=>{
                    if (res.status === 200)
                      toast.success('Successfully Added!');
                      navigate("/admin/users");
                    })
                    .catch(error => {
                     toast.error('Email is already registered.');
                });
            }
        }
    });

  
  return(
    <>
    <div className="user-single-head">
    <div className="flex gap-4 mb-8 w-full">

        <div className="w-6/12 client-info">
            <h4 className="capitalize">{selectedPrimaryType}</h4>
            <h2 className="text-white">{getFieldProps('first_name').value + ' ' + getFieldProps('last_name').value}</h2>
            <div className="status-bar">
                <span>{getFieldProps('phone_number').value}</span>
            </div>
        </div>

        <div className="w-6/12">
            <div className="flex gap-4">
                <div className="white-box card-box w-4/12">
                    <h4>Total Scheduled</h4>
                    <h2>0</h2>
                </div>
                <div className="white-box card-box w-4/12">
                    <h4>Total Completed</h4>
                    <h2>0</h2>
                </div>
                <div className="white-box card-box w-4/12">
                    <h4>Total Cancelled</h4>
                    <h2>0</h2>
                </div>
            </div>
        </div>

    </div>
</div>

<div>

    <TopNav data={topNav} />


    <div className="white-box p-0">
        <div className="heading mb-3 pt-8 px-8">
            <h2>Add New User Profile</h2>
        </div>


        <form noValidate onSubmit={handleSubmit}>
            <div className="px-8">
                <div className="flex gap-6 mb-16 w-full">
                    
                    <div className="form-input w-3/12">
                        <Input
                        type="text"
                        variant="static"
                        fullWidth
                        placeholder="First Name"
                        id="firstName"
                        label="First Name"
                        {...getFieldProps('first_name')}
                        className="border-black placeholder-shown:border-black"
                        />
                        {(touched.first_name && errors.first_name) && <span className="error">{errors.first_name}</span>}
                    </div>

                    <div className="form-input w-3/12">
                        <Input
                        type="text"
                        variant="static"
                        fullWidth
                        placeholder="Last Name"
                        id="lastName"
                        label="Last Name"
                        {...getFieldProps('last_name')}
                        className="border-black placeholder-shown:border-black"
                        />
                        {(touched.last_name && errors.last_name) && <span className="error">{errors.last_name}</span>}
                    </div>

                    <div className="form-input w-3/12">
                        <Input
                        type="email"
                        variant="static"
                        fullWidth
                        placeholder="E-mail"id="email"
                        label="Email"
                        {...getFieldProps('email')}
                        className="border-black placeholder-shown:border-black"
                        />
                        {(touched.email && errors.email) && <span className="error">{errors.email}</span>}
                    </div>


                    <div className="form-input w-3/12">
                        <Input
                        type="number"
                        variant="static"
                        autoComplete="off"
                        fullWidth
                        id="phone_number"
                        label="Mobile Number *"
                        {...getFieldProps('phone_number')}
                        className="border-black placeholder-shown:border-black"
                        />
                        {(touched.phone_number && errors.phone_number) && <span className="error">{errors.phone_number}</span>}
                    </div>


                </div>
            </div>


            <div className="px-8">
                <div className="flex gap-6 mb-16 w-full">

                    <div className="form-select w-3/12 relative">
                        <label htmlFor="rol">Gender *</label>
                        <CustomSelect
                            isSearchable={false}
                            className='input'
                            onChange={value=>setFieldValue('gender',value.value)}
                            value={getFieldProps('gender').value}
                            options={usergenderuser}
                        />
                     {(touched.gender && errors.gender) && <span className="error">{errors.gender}</span>}
                    </div>

                    <div className="form-select w-3/12 relative">
                        <label htmlFor="rol">Primary Role *</label>
                        <CustomSelect
                            isSearchable={false}
                            className='input'
                            name='user_type_primary'
                            onChange={handlePrimaryTypeChange}
                            value={selectedPrimaryType}
                            options={usertype}
                        />
                        {(touched.user_type_primary && errors.user_type_primary) && <span className="error">{errors.user_type_primary}</span>}
                    </div> 

                    {selectedPrimaryType === 'student' &&
                        <div className="form-select w-3/12 relative">
                            <label htmlFor="rol">Secondary Role</label>
                            <CustomSelect
                                isSearchable={false}
                                className='input'
                                onChange={value=>setFieldValue('user_type_secondary',value.value)}
                                value={getFieldProps('user_type_secondary').value}
                                options={usertype2}
                            />
                        </div>
                    }

                    <div className="form-select w-3/12 relative">
                        <label htmlFor="rol">User Status *</label>
                        <CustomSelect
                            isSearchable={false}
                            className='input'
                            onChange={value=>setFieldValue('user_status',value.value)}
                            value={getFieldProps('user_status').value}
                            options={userstatus}
                        />
                      {(touched.user_status && errors.user_status) && <span className="error">{errors.user_status}</span>}
                    </div>

                    {selectedPrimaryType === 'client' ? (
                            <div className="form-select w-3/12 relative">
                                <label htmlFor="rol">Client Notes</label>
                                <CustomSelect
                                  isSearchable={false}
                                  isClearable={true}
                                  className='input'
                                  onChange={value => setFieldValue('special_id', value?.value || null)}
                                  value={getFieldProps('special_id')?.value || null}
                                  options={specialoptions}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }

                    {selectedPrimaryType === 'student' && <>
                        <div className="form-select w-2/12 relative">
                            <label htmlFor="rol">Vaccinated</label>
                            <CustomSelect
                                isSearchable={false}
                                className='input'
                                onChange={value=>setFieldValue('vaccinated',value.value)}
                                options={uservaccinated}
                            />
                        </div>
                    </>}

                    {selectedPrimaryType !== 'student' &&
                        <div className="form-input w-3/12 password">
                            <Input
                                type={isRevealPwd ? "text" : "password"}
                                autoComplete="new-password"
                                name="password"
                                variant="static"
                                fullWidth
                                id="password"
                                label="Password *"
                                className="border-black placeholder-shown:border-black"
                                value={password}
                                onChange={handleChangePwd}
                            />
                            <img
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                className = {isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={togglePassword}
                                alt={isRevealPwd ? "Hide password" : "Show password"}
                            />
                            {(touched.password && errors.password) && <span className="error">{errors.password}</span>}
                            <button type="button" onClick={handleGeneratePassword}>Generate Password</button>
                        </div>
                    }
                </div>
            </div>

            {selectedPrimaryType === 'student' &&
                <div className="px-8">
                    <div className="flex gap-6 mb-8 w-full">
                        <div className="form-select w-4/12 relative">
                            <label htmlFor="rol">Gender Preference Of Client</label>
                            <CustomSelect
                                isSearchable={false}
                                className='input'
                                onChange={handleGenderPref}
                                options={genderpreference}
                                value={selectedGenderType}
                            />
                        </div>

                        <div className="form-input w-3/12 password">
                            <Input
                                type={isRevealPwd ? "text" : "password"}
                                autoComplete="new-password"
                                name="password"
                                variant="static"
                                fullWidth
                                id="password"
                                label="Password *"
                                className="border-black placeholder-shown:border-black"
                                value={password}
                                onChange={handleChangePwd}
                            />
                            <img
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                className = {isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={togglePassword}
                                alt={isRevealPwd ? "Hide password" : "Show password"}
                            />
                            {(touched.password && errors.password) && <span className="error">{errors.password}</span>}
                            <button type="button" onClick={handleGeneratePassword}>Generate Password</button>
                        </div>
                    </div>
                </div>
            }

          <div className="px-8">

               <div className="flex flex-wrap mt-8 mb-8 w-full">
                        <div className="w-full form-textarea with-label">
                            <Textarea
                                name="noBlocknotes"
                                variant="static" 
                                className="border-black placeholder-shown:border-black"
                                label="Admin Only Notes" 
                                placeholder="Message"
                                onChange={e => {
                                  setFieldValue('noBlocknotes',e.target.value);
                                }}
                              />
                            {(touched.noBlocknotes && errors.noBlocknotes) && <span className="error">{errors.noBlocknotes}</span>}
                        </div>
                 </div>

            <div className="flex flex-wrap mt-8 mb-8 w-full">

                {selectedPrimaryType === 'student' &&
                    <div className="w-full mb-4">
                        <label className="flex">
                            <Checkbox 
                                id="noBlock-restriction" 
                                checked={noBlockrestriction} 
                                containerProps={{
                                    className: "p-0 mr-2"
                                }} 
                                name='noBlockrestriction' 
                                onChange={handleRestrictionBlockChange} 
                            />
                             No time block restriction
                        </label>
                    </div>
                }

                <div className="form-select w-full">
                    <p>* Required fields</p>
                </div>
            </div>
          </div>

          {selectedPrimaryType === 'student' &&<>
                <div className="px-8">
                    <hr className="mt-6 mb-14" style={{ borderColor: '#CCCCCC' }} />
                </div>

                <div className="message-type-area">
                    <Typography variant="h4" className="px-8 font-normal mb-8">ACFB Clinic Tally</Typography>
                    <div className="flex bg-greycolor9 py-6 px-8">
                        <div className="w-4/12">
                            Massage Type
                        </div>
                        <div className="w-3/12">
                            Target Number of Clinics
                        </div>
                    </div>
                    <div className="py-4 px-8">

                        {users.map((item, index) => (
                          <div key={index} className="flex">
                            <div className="w-4/12">
                              <Radio
                                id={`massage_type_${index}`}
                                value={item.id}
                                label={item.title}
                                onChange={() => setFieldValue('massage_type', item.id)}
                                checked={getFieldProps('massage_type').value === item.id}
                              />
                            </div>
                            {getFieldProps('massage_type').value === item.id && (
                              <div className="w-3/12">
                                <Input
                                    type="number"
                                    variant="static"
                                    id={`selected_services_count_${item.id}`}
                                    labelProps={{
                                      className: "hidden" 
                                    }}
                                    placeholder="0"
                                    className="w-52 border-0 p-0 focus:border-gray-400 shadow-none"
                                    name={`selected_services_count[${item.id}]`}
                                    onChange={(e) => setFieldValue(`selected_services_count[${item.id}]`,e.target.value)}
                                />
                              </div>
                            )}
                          </div>
                        ))}

                    </div>
                </div>
            </>}


            <div className="bottom-button-group flex flex-wrap justify-end items-center pb-8 px-8">
                {selectedPrimaryType === 'client' &&
                    <div className="col">
                        <div className="w-full">
                            <label className="flex">
                                <Checkbox 
                                    id="accept-terms" 
                                    checked={acceptTerms} 
                                    containerProps={{
                                        className: "p-0 mr-2"
                                    }} 
                                    name='acceptTerms' 
                                    onChange={handleAcceptTermsChange} 
                                />
                                User agrees to terms & conditions
                            </label>
                        </div>
                    </div>
                }
                <div className="col">
                    <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Create New User</button>
                </div>
            </div>
            
        </form>
        <Toaster />
    </div>
</div>
    </>
    )
}
export default AddUser;