import { useState,useEffect } from 'react';
import { useFormik } from 'formik';
import FromHelper from '../helpers/FromHelper';
import * as Yup from 'yup';
import AuthUser from '../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { 
    Typography, 
    Checkbox, 
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    IconButton,
} 
from "@material-tailwind/react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faXmark
}
from '@fortawesome/free-solid-svg-icons';
import { Fragment } from "react";
import AccordionList from '../helpers/AccordionList';
import LoadingFixed from '../Components/Loading/LoadingFixed';

const LoginInputs=[
    {inputType:'email',inputId:'email',inputPlaceHolder:'Email Address', classes: 'w-full rounded-md', parentclasses: 'mb-4'},
    {inputType:'password',inputId:'password',inputPlaceHolder:'Password', classes: 'w-full rounded-md', parentclasses: 'password mb-4'}
];

function Home(){
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [UserRole, setUserRole] = useState(null);
   
    const {http,httpwithoutAuth,setToken,token,logout} = AuthUser();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState();
    const handleOpen = () => setOpen(!open);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().min(4).required('Required')
        }),
        onSubmit: values => {
            httpwithoutAuth.post('auth/login',JSON.stringify(values, null, 2)).then((res)=>{
                if (res.status === 200)
                    if (res.data.user.user_type_primary === 'client'){
                      setToken(res.data.user,res.data.access_token);
                      toast.success("Success!");
                      navigate('/make-booking');
                    } else if (res.data.user.user_type_primary === 'student'){
                        setToken(res.data.user,res.data.access_token);
                        toast.success("Success!");
                        navigate('/student/upcoming-bookings');
                    } else {
                        toast.error("Error: Invalid Login Details.");
                    }
                }).catch(error => {
                 toast.error('User does not exist, please register a new account below.');
            });
        },
    });

    const [services, setServices] = useState([]);
    useEffect(() => {
        GetServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
    const logoutUser=()=>{
        if(token !== undefined){
          logout();
        }
      }
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const role = JSON.parse(localStorage.getItem('user'));
        if(storedToken){
            let isRolePrimary = role.user_type_primary;
            setUserRole(isRolePrimary);
            setIsLoggedIn(true);
        }
      setLoading(true);
      async function fetchData() {
        try {
          const response = await httpwithoutAuth.get('home/acfbcontent/5');
          const data = response.data?.data;
          if (data && data.acfb_booking_data) {
            setData(data);
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let termsData1 = [];
    let termsData2 = [];

    if (data && data.acfb_booking_data) {
      termsData1 = data.acfb_booking_data.filter((_, index) => index % 2 === 0);
      termsData2 = data.acfb_booking_data.filter((_, index) => index % 2 !== 0);
    }

    const GetServices = () => {
     httpwithoutAuth.get('auth/services').then((resp)=>{
        setServices(resp.data.data);
     });  
    }

    if (!data) {
        return <></>;
    }
    
    return (
        <div>
            {isLoading && <LoadingFixed /> }
            <div className="pt-10 pb-6 md:pt-24 px-4 md:pb-10 bg-greycolor6">
                <div className="max-w-screen-lg mx-auto">

                    <div className="lg:flex items-center gap-x-20 mb-20">
                        <div className="md:w-full lg:w-6/12 text-center lg:text-left mb-8 lg:mb-0">
                            <Typography variant="h5">ACFB</Typography>
                            <Typography variant="h2" className="mb-5">{data.title}</Typography>
                            <Typography variant="paragraph" className="font-normal">
                                {data.description}
                            </Typography>
                        </div>
                        <div className="md:w-full lg:w-6/12">
                        {!isLoggedIn ? (
                            <div className="bg-greycolor5 px-4 py-6 md:px-10 md:py-12 rounded-md">
                                <div className="text-center mb-8">
                                    <Typography variant="h4" className="mb-2">Existing client or student?</Typography>
                                    <Typography variant="h5" className="text-base font-normal">Welcome back! Please login to continue.</Typography>
                                </div>
                               
                                <form onSubmit={formik.handleSubmit}>
                                    <div>
                                        {LoginInputs.map((inputdata,index)=>{
                                           return <FromHelper frominput={inputdata} formikData={formik} key={index}/>
                                        })}
                                        <div className="checkbox mb-4">
                                            <Checkbox className="p-0" label="Remember Me" />
                                        </div>
                                        <button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3 w-full" type="submit">Login</button>
                                    </div>
                                    <Typography variant="paragraph" className="text-center mt-6">If this is your first time visit; <br/>please <Link to="/client-register" className="font-bold text-blue-900 underline underline-offset-1">register as a client here.</Link></Typography>
                                    <Typography variant="paragraph" className="text-center mt-2"><Link to="/forgot-password" className="font-bold text-blue-900 underline underline-offset-1">Forgot password.</Link></Typography>
                                </form>
                                
                                <Toaster/>
                            </div>
                             ):(
                                <div className="bg-greycolor5 px-4 py-6 md:px-10 md:py-12 rounded-md">
                                <Link to="/" onClick={logoutUser}>
                                <div className="text-center mb-8">
                                  <Typography variant="h5" className="text-base font-normal">Welcome to ACFB’s Industry Leading Student Massage Clinic!</Typography>
                                    <Typography variant="h4" className="mb-2">Logout</Typography> 
                                </div>
                                </Link>
                            </div>
                         )}
                        </div>
                    </div>

                    <div className="pb-10">
                        <div className="w-full mb-10">
                            <Typography variant="paragraph" className="text-center font-normal text-lg">There are three types of treatments available at <br />ACFB and all session go for one (1) hour:</Typography>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 flex-wrap lg:flex lg:flex-nowrap gap-y-6 gap-x-6">

                            {services.map((item, index) => (<>
                                <div key={index} className="w-full lg:w-4/12 drop-shadow-md bg-white rounded-lg overflow-hidden">
                                    <img src={item.image} className="w-full" alt={item.title} />
                                    <div className="p-6">
                                        <Typography variant="h5" className="text-base font-normal mb-14">{item.title}</Typography>
                                        <div className="flex mb-4 items-baseline">
                                            {item.promo_price.length > 2 &&
                                              <Typography variant="h4" className="text-red-500 mr-2">{item.promo_price}</Typography>
                                            }
                                            <Typography variant="h4" className={item.promo_price.length > 2 ? 'line-through font-normal opacity-40':'' }>${item.price}</Typography>
                                            <Typography variant="paragraph" className="text-base ml-2">| 1 Hour</Typography>
                                        </div>
                                        {console.log('UserRole',UserRole)}
                                        {isLoggedIn && UserRole === 'client' ? (
                                            <Link to="/make-booking"><Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black normal-case w-full shadow-none hover:shadow-none px-4 py-4 text-sm font-normal mb-2">Book Appointment Now</Button></Link>
                                        ):(
                                         <Button onClick={handleOpen} className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black normal-case w-full shadow-none hover:shadow-none px-4 py-4 text-sm font-normal mb-2">Book Appointment Now</Button>
                                        )}
                                        {index === 0 &&
                                            <Link to="/student-clinic-gift-card-relaxation-massage-1x-1hr-appointment"><Button className="bg-white hover:bg-black active:bg-black focus:bg-black normal-case w-full shadow-none hover:shadow-none px-4 py-4 text-sm font-normal border text-greycolor1 hover:text-white">Buy Gift Card</Button></Link>
                                        }
                                        {index === 1 &&
                                            <Link to="/student-clinic-gift-card-remedial-massage-1x-1hr-appointment"><Button className="bg-white hover:bg-black active:bg-black focus:bg-black normal-case w-full shadow-none hover:shadow-none px-4 py-4 text-sm font-normal border text-greycolor1 hover:text-white">Buy Gift Card</Button></Link>
                                        }
                                        {index === 2 &&
                                            <Link to="/student-clinic-gift-card-myotherapy-treatment-1x-1hr-appointment"><Button className="bg-white hover:bg-black active:bg-black focus:bg-black normal-case w-full shadow-none hover:shadow-none px-4 py-4 text-sm font-normal border text-greycolor1 hover:text-white">Buy Gift Card</Button></Link>
                                        }
                                    </div>
                                </div>
                            </>))}

                            <Dialog open={open} className="max-w-screen-sm w-full min-w-max" handler={handleOpen}>
                                <DialogHeader className="justify-between p-0">
                                   <IconButton
                                        color="blue-gray"
                                        size="sm"
                                        className="ml-auto"
                                        variant="text"
                                        onClick={handleOpen}
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </IconButton>
                                </DialogHeader>
                                <DialogBody className="px-8 pb-6 pt-0">
                                
                                     {isLoggedIn && UserRole !== 'client' ? (
                                            <Typography variant="h5" className="font-bold text-black text-center mb-6 text-xl">Currently, you are logged in with a {UserRole} role. Please switch to the client role.</Typography>
                                        ) : (<>
                                             <Typography variant="h3" className="font-bold text-black text-center mb-6 text-2xl">Login to book appointment now</Typography>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div>
                                                    {LoginInputs.map((inputdata, index) => {
                                                        return <FromHelper frominput={inputdata} formikData={formik} key={index} />;
                                                    })}
                                                    <div className="checkbox mb-4">
                                                        {/*<input type="checkbox" value="remember-me" /> Remember me*/}
                                                        <Checkbox className="p-0" label="Remember Me" />
                                                    </div>
                                                    <button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3 w-full" type="submit">Login</button>
                                                </div>
                                                <Typography variant="paragraph" className="text-center text-black mt-6">If this is your first time visit; <br/>please <Link to="/client-register" className="font-bold text-blue-900 underline underline-offset-1">register as a client here.</Link></Typography>
                                                <Typography variant="paragraph" className="text-center mt-2"><Link to="/forgot-password" className="font-bold text-blue-900 underline underline-offset-1">Forgot password.</Link></Typography>
                                            </form>
                                            </>
                                        )}
                                    <Toaster/>
                                </DialogBody>
                            </Dialog>

                        </div>
                    </div>

                    <hr className="mt-6 mb-8" style={{ borderColor: '#707070' }} />

                    <div className="h-96 overflow-auto terms-content">
                        <div dangerouslySetInnerHTML={{ __html: data.acfb_terms }}></div>
                    </div>

                    <Fragment>
                        <div className="lg:flex gap-20 pt-8 pb-8">
                            <div className="w-full lg:w-6/12">
                                <AccordionList data={termsData1} />
                            </div>
                            <div className="w-full lg:w-6/12">
                                <AccordionList data={termsData2} />
                            </div>
                        </div>
                    </Fragment>

                </div>
            </div>
            <div className="bg-greycolor5 py-24 hidden">
                <div className="max-w-screen-lg mx-auto text-center">
                    <Typography variant="h2" className="font-normal mb-10">Final Call to Action Text here</Typography>
                    <Typography variant="paragraph" className="text-xl text-darkgrey1 font-normal">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
                    </Typography>

                    <div className="flex justify-center gap-5 mt-10">
                        <Button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit"><Link to="/admin">Login</Link></Button>
                        <Button className="border border-black bg-transparent hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none hidden lg:inline-block text-black hover:text-white capitalize text-sm px-8 py-3" type="submit"><Link to="/client-register">Register</Link></Button>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Home;