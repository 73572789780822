import { Routes, Route, Navigate} from "react-router-dom";
import Home from '../../Pages/Home';
import EnterAvailability from '../../Student/Pages/EnterAvailability';
import UpcomingBookings from '../../Student/Pages/UpcomingBookings';
import PastBookings from '../../Student/Pages/PastBookings';
import {getStudentUrl} from "../../helpers/Utils";

export default function StudentRoutes(){
    return (
        <Routes>
            <Route exact path={"/"} element={<Home />}/>
            <Route exact path={getStudentUrl('enter-availability')} element={<EnterAvailability />}  />
            <Route exact path={getStudentUrl('upcoming-bookings')} element={<UpcomingBookings />}  />
            <Route exact path={getStudentUrl('past-bookings')} element={<PastBookings />}  />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}