import { Fragment, useState,useEffect } from "react";
import React from "react";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
import { Switch } from "@material-tailwind/react";
import Moment from 'moment';

function ViewAll(props) {
    
    const {http}=AuthUser();
 
    const [loading, setLoading] = useState(false);
    const [vouchers, setVoucher] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setCurrentPage] = useState(1);
    const [status, setStatus] = useState([]);

    const columns = [
        {
            name: 'Code',
            selector: row => row.code,
            sortable: false,
            reorder: true
        },
        {
            name: 'Client',
            selector: row => row.recipient_name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Balance',
            selector: row => (row.is_used === true && row.appointment_id  ) ? ( 'Used' ):(<>
               {'$' + row.current_balance} 
          </>)
        },
        {
            name: 'Used In Orders',
            selector: row => {
              if (row.order_number) {
                return (
                  <Link to={"/admin/edit-appointment/edit/" + row.appointment_id} target="_blank" className='btn btn-primary bg-greycolor4 rounded px-2 py-2 inline-block'>
                    {row.order_number}
                  </Link>
                );
              } else {
                return <span>No Order Available</span>; // or you can return null or any other message
              }
            },
          },
        {
            name: 'Expiration Date',
            selector: row => Moment(row.expires_at).format('DD/MM/YYYY'),
        },
        {
            name: 'Recipient',
            selector: row => row.recipient_email,
        },

        {
            name: 'Notes',
            selector: row => row.notes,
        },  
        {
            name: 'Enable',
            cell: (row) =>  (
                row.status === 'active')?( 
                     <div className="enable-disable active" data={row.status}><Switch checked={row.status } data-id={row.id} data-checked={row.status} onChange={handleStatusChange} id={'switch' + row.id}/></div>
                     ) : row.is_used === true && row.appointment_id ? (''
            ):(  <div className="enable-disable disable" data={row.id}><Switch data-id={row.id} data-checked={row.status} onChange={handleStatusChange} id={'switch' + row.id} /></div> ), 
        },
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/gift/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

     const GetVoucherList = async() => {
        setLoading(true);
        await http.get('voucher',{
            params: {
                page: page,
                per_page: rowsPerPage,
              },
         }).then((resp)=>{
            setVoucher(resp.data.data);
            setTotalRows(resp.data.total);     
            setLoading(false);
        }).catch(err => {
            setVoucher({});
        });  
    }
    useEffect(() => {
        GetVoucherList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };
    
    function handleStatusChange(event) {
        const id        = event.target.getAttribute('data-id');
        setStatus(event.target.checked);
         const data = event.target.checked ? 'active' : 'disabled';
        console.log(data);
        http.put(`/voucher/${id}`, { status: data })
        .then(response => {
          console.log(response.data.message);
        })
        .catch(error => {
          console.log(error.response.data.message);
          setStatus(status);
        });
    }

    return (
        <>
            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>Gift Cards</h2>
                        </div>
                    </div>
                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={vouchers}
                            className="table-title-style"
                            selectableRows={false}
                            pagination
                            paginationServer
                            paginationDefaultPage={page}
                            paginationPerPage={rowsPerPage}
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10,20, 25, 50,100]}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                              }}
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewAll;
