import { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
//import Pagination from '../../helpers/Pagination';
import { Link } from 'react-router-dom';

import {getAdminUrl} from "../../../helpers/Utils";

import {
  Button
} from "@material-tailwind/react";

export default function Availabilities(props){
    const {http,getStudents}=AuthUser();
    const loading=false;
    const [availability, setAvailability] = useState([]);
    useEffect(() => {
        AvailabilityGet()
    }, [])

    const AvailabilityGet = () => {
     http.get('auth/availability').then((resp)=>{
        setAvailability(resp.data.data);
     });  
    }
  
  
   const [users, setUsers] = useState([]);
    useEffect(() => {
        UsersGet()
    }, [])

    const UsersGet = () => {
     getStudents().then((resp)=>{
        setUsers(resp.data.data);
     });
    }
    {console.log('addss',users)}
    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Author',
            selector: row => row.user_id,
        },
        {
            name: 'Date',
            selector: row => 'Published '+row.created_at,
        },
        {
            cell: (row) => (
                <> { console.log('avilall',row.user_id)}
                  
              </>
              ),
           
        },
      
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/availability/edit/" + row.id } className='btn btn-primary'>View/Edit</Link>
            </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      
    ];

    return (
        <>
            <div className="navtab">
              <ul className="flex tabs-header">
                <li><Link to={getAdminUrl('services')}>View All</Link></li>
                <li className="ml-auto"><Button className="m-0"><Link to={getAdminUrl('availability/add-new-availability')}>Add New Availability</Link></Button></li>
              </ul>
            </div>

            <div className="white-box">
                <div className="appointments-view">
                    <div className="top-head">
                        <div className="row">
                            <h2>All</h2>
                            <div className="input-middle">
                            </div>
                            <div className="status-bar">
                            </div>
                        </div>
                    </div>

                    <div className="view-all-appointment">
                        <DataTable
                            columns={columns}
                            data={availability}
                            selectableRows
                            progressPending={loading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}