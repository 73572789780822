import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppointmentForm from '../Payment/AppointmentForm';

// const stripePromise = loadStripe('pk_test_51MzJYXGam68L0P2s2kg1ZGurbEEZxO2Uc2GxvMi9WzVKIX2cDX9JiOPT8JWsF9uXMCBwRc3yFNChxNJdmI67XNPe00F2HF0tOK');
const stripePromise = loadStripe('pk_live_51IpNMjBQYf8AEnjL2CLLLw77I9mLKvT5XrT44wOnI3K6ubQp3YnEgzun3d692U5svXwtcXWe9pv1GiYv9WqMJUiJ00JdV7aP3S');

function AddAppointment() {
  return (
    <Elements stripe={stripePromise}>
      <AppointmentForm />
    </Elements>
  );
}
export default AddAppointment;