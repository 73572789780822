import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Input,
  Button,
  Textarea,
  Dialog,
  DialogFooter
} from "@material-tailwind/react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
 
import { Typography} from "@material-tailwind/react";
import AuthUser from '../helpers/AuthUser';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function GiftCardSection() {
  const {http} = AuthUser();
  const [product, setProduct] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const [ pageView, setPageView ] = useState(pathname)
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [done,setDone] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // const [PaymentCompleted, setPaymentCompleted] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleOpen = () => setOpen(!open);

  const anotherBooking = () => {
      setOpen(!open);
      window.location.reload();
  }

  const { handleSubmit, handleChange, errors, touched, getFieldProps } = useFormik({
      initialValues: {
        recipient_email: '',
        recipient:'',
        amount:'',
        sender: ''
      },
      validationSchema: Yup.object({
        recipient: Yup.string().required('Recipient is required'),
        recipient_email: Yup.string().email('Please enter a valid email address').required('Email is required'),
        sender:Yup.string().required('Sender is required')
      }),
      onSubmit: async (values) => {
        setLoading(true);
        if (!stripe || !elements) {
           setLoading(false);
           setIsLoading(false)
          return;
        }

     const paymentMethodObj = {
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
            email
          },
        };
        const result = await stripe.createToken(paymentMethodObj.card);
        if (result.error) {
          setLoading(false);
          setIsLoading(false)
          setErrorMsg(result.error.message);
        }

      const allFormData = {
          ...values,
          amount:product.price,
          product_id:product.id,
          cardholder:paymentMethodObj.billing_details,
          stripeToken:result.token.id,
      }
      setIsLoading(true);
      await makePaymentRequest(allFormData)
      setIsLoading(false)
      setDone(true)
      setOpen(true)

     },
  });
 
  const makePaymentRequest = async (allformData)=>{
    await http.post('gift-payment',JSON.stringify(allformData, null, 2)).then((response)=>{
        // setPaymentCompleted(response.success ? true : false);
        setDone(true)
        setLoading(false);
        setIsLoading(false)
       return response;
    }).catch(error => {
       setErrorMsg(error.response.message);
    });
  }    

 const [services, setServices] = useState([]);
  useEffect(() => {
      GetServices()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GetServices = () => {
   http.get('/gift-products').then((resp)=>{
      setServices(resp.data.data);
      if(pageView === '/student-clinic-gift-card-relaxation-massage-1x-1hr-appointment'){
        setProduct(resp.data.data[0]);
      }
     else if(pageView === '/student-clinic-gift-card-remedial-massage-1x-1hr-appointment'){
        setProduct(resp.data.data[2]);
      }
      else if(pageView === '/student-clinic-gift-card-myotherapy-treatment-1x-1hr-appointment'){
        setProduct(resp.data.data[1]);
      }else{
        setProduct(null);
      }
   });  
  }

  if(isLoading)  return  <div className="text-center"> <h1 className="text-success mt-5">Wait, Processing Your Payment</h1> </div>


  return (<>
    { !done && !isLoading &&
      <form onSubmit={handleSubmit}>
        <div className="bg-white px-6 pt-8 pb-10 mb-4 rounded-lg drop-shadow-md">
            <Typography variant="h3" className="text-2xl mb-4 font-normal">Purchase Gift Cards</Typography>

            {pageView === '/student-clinic-gift-card-relaxation-massage-1x-1hr-appointment' &&
                <Typography variant="paragraph" className="text-darkgrey1 font-normal mb-4">Student Clinic Gift Card – Relaxation Basic Treatment 1 x 1hr appointment</Typography>
            }
            {pageView === '/student-clinic-gift-card-remedial-massage-1x-1hr-appointment' &&
                <Typography variant="paragraph" className="text-darkgrey1 font-normal mb-4">Student Clinic Gift Card – Remedial Deep Tissue Treatment 1 x 1hr appointment</Typography>
            }
            {pageView === '/student-clinic-gift-card-myotherapy-treatment-1x-1hr-appointment' &&
                <Typography variant="paragraph" className="text-darkgrey1 font-normal mb-4">Student Clinic Gift Card – Myotherapy Advanced Treatment 1 x 1hr appointment</Typography>
            }

            <div className="grid grid-cols-1 mt-6 md:flex md:mt-10">
                <div className="w-full md:w-4/12">
                    <div className="image h-full">
                        {services.map((item, index) => (<> 
                          {pageView === '/student-clinic-gift-card-relaxation-massage-1x-1hr-appointment' &&<>
                            {index === 0 &&
                              <>
                              <img key={index} className="rounded-lg h-full object-cover w-full" src={item.image} alt="Relaxation Basic Treatment 1 x 1hr appointment" />
                              {/* <input key={index} className="rounded-lg h-full object-cover w-full" price={item.price} value={setPrice(item.price)} /> */}
                              </>
                            }</>
                          }
                          {pageView === '/student-clinic-gift-card-remedial-massage-1x-1hr-appointment' &&<>
                            {index === 1 &&
                             <> <img key={index} className="rounded-lg h-full object-cover w-full" src={item.image} alt="Remedial Deep Tissue Treatment 1 x 1hr appointment" />
                             {/* <input key={index} className="rounded-lg h-full object-cover w-full" price={item.price} value={setPrice(item.price)} /> */}
                             </>
                            }</>
                          }
                          {pageView === '/student-clinic-gift-card-myotherapy-treatment-1x-1hr-appointment' &&<>
                            {index === 2 &&
                               <> <img key={index} className="rounded-lg h-full object-cover w-full" src={item.image} alt="Myotherapy Advanced Treatment 1 x 1hr appointment" />
                              {/* <input key={index} className="rounded-lg h-full object-cover w-full" price={item.price} value={setPrice(item.price)} /> */}
                              </>
                            }</>
                          }
                        </>))}
                    </div>
                </div>
                <div className="w-full md:w-8/12 pt-14 md:pl-6 md:pt-6">
                    <div className="flex gap-6 mb-12 w-full">
                      <div className="form-input w-6/12">
                        <Input
                            name="recipient"
                            variant="static"
                            fullWidth
                            id="recipient"
                            label="Recipient’s info"
                            placeholder="Name"
                            className="border-black placeholder-shown:border-black"
                            {...getFieldProps('recipient')}
                            onChange={handleChange}
                        />
                        {(touched.recipient && errors.recipient) && <span className="error">{errors.recipient}</span>}
                      </div>
                      <div className="form-input w-6/12">
                        <Input
                            name="recipient_email"
                            variant="static"
                            fullWidth
                            id="recipient_email"
                            placeholder="Email Address"
                            className="border-black placeholder-shown:border-black"
                            {...getFieldProps('recipient_email')}
                            onChange={handleChange}
                        />
                        {(touched.recipient_email && errors.recipient_email) && <span className="error">{errors.recipient_email}</span>}
                      </div>
                    </div>
                    <div className="form-input w-full mb-5">
                      <Input
                          name="sender"
                          variant="static"
                          fullWidth
                          id="info"
                          label="Your info"
                          placeholder="Name"
                          className="border-black placeholder-shown:border-black"
                          {...getFieldProps('sender')}
                          onChange={handleChange}
                      />
                      {(touched.sender && errors.sender) && <span className="error">{errors.sender}</span>}
                    </div>
                    <div className="form-input w-full">
                      <Textarea
                          name="message"
                          variant="static"
                          fullWidth
                          id="message"
                          placeholder="Message"
                          className="border-black placeholder-shown:border-black"
                          {...getFieldProps('message')}
                          onChange={handleChange}
                      />
                      {(touched.message && errors.message) && <span className="error">{errors.message}</span>}
                    </div>
                </div>
            </div>
        </div>

      <div className="bg-white px-6 pt-8 pb-10 rounded-lg drop-shadow-md">

        <Typography variant="h3" className="text-2xl mb-4 font-normal">Payment</Typography>
        <Typography variant="paragraph" className="text-darkgrey1 font-normal mb-4">All transactions are secure and encrypted.</Typography>
        <Typography variant="paragraph" className="text-darkgrey1 font-normal mb-8">Pay with your credit card via Stripe.</Typography>

    
        <div className="flex gap-6 mb-8 w-full">
            <div className="form-input w-6/12">
              <Input
                id="cc-name"
                variant="static"
                fullWidth
                className="border-black placeholder-shown:border-black"
                value={name}
                label="Name on card"
                onChange={e => setName(e.target.value)}
              />
            </div>
          <div className="form-input w-6/12">
            <Input
              id="cc-email"
              variant="static"
              fullWidth
              className="border-black placeholder-shown:border-black"
              value={email}
              label="Email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-6 mb-5 w-full">
          <div className="w-full md:w-6/12">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="w-full md:w-3/12">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>

          <div className="w-full md:w-3/12">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>

        </div>
        <button className="btn btn-black text-white rounded-lg w-100" type="submit" disabled={loading}>
          {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `Buy Now`}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}

      </div>
    </form>
  }

  <Dialog open={open} handler={handleOpen}>
      { errorMsg &&
         <div className="text-center pt-16 pb-6"> <h1 className="text-danger mt-5">Your booking failed</h1></div>
      }
      { done &&
          <div className="text-center pt-16 pb-6">
              <Typography variant="h2" className="text-2xl text-blue-900 font-bold mb-0">Your have successfully purchased gift card</Typography>
          </div>
      }

      <DialogFooter className="block text-center pb-10">
        <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3" onClick={anotherBooking}>Make Another Gift Card Booking</Button>
      </DialogFooter>
  </Dialog>

    
  </>);
};
export default GiftCardSection;