import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthUser from '../helpers/AuthUser';
import CheckoutForm from './CardSection';
import TopNav from '../Components/TopNav/TopNav';
import AccordionList from '../helpers/AccordionList';
import useBookingData from '../helpers/api/useBookingData';
const stripePromise = loadStripe("pk_live_51IpNMjBQYf8AEnjL2CLLLw77I9mLKvT5XrT44wOnI3K6ubQp3YnEgzun3d692U5svXwtcXWe9pv1GiYv9WqMJUiJ00JdV7aP3S");

export default function ClientGiftCards(props){
    const {user}=AuthUser();
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const data = useBookingData();
    const topNav = [
        {
            name: "Make a Booking",
            url: '/make-booking'
        },
        {
            name: "Upcoming Bookings",
            url: '/upcoming-bookings'
        },
        {
            name: "Past Bookings",
            url: '/past-bookings'
        },
        {
            name: "Gift Cards",
            url: '/gift-cards'
        },
        ...(user.user_type_secondary != null
        ? [
        {
            name: "Switch to Student",
            url: '/student/enter-availability'
        }
        ]
        : [])
    ]

    return (
        <main className="form-signin pt-12 pb-10 md:pb-20 px-4">
            {data &&
                <div className="max-w-screen-lg mx-auto">
                    <AccordionList data={data} />
                    <div className="client-nav">
                        <TopNav data={topNav} />
                    </div>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm setPaymentCompleted={setPaymentCompleted} />
                    </Elements>
                </div>
            }
        </main>
    );
} 
