import React, {useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPencil
} 
from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom';
import AuthUser from '../../helpers/AuthUser';
import Datepicker from "react-tailwindcss-datepicker";
import ClinicRemedial from '../Components/ClinicView/ClinicRemedial';
import ClinicMyotherapy from '../Components/ClinicView/ClinicMyotherapy';
import ClinicRelaxation from '../Components/ClinicView/ClinicRelaxation';
import Loading from '../../Components/Loading';
export default function ClinicDay(){

    const {http} = AuthUser();
    const [selected_date, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    const [Data, setData] = useState([]);
    const [nextavailable, setNextAvailable] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleValueChange = (selecteddate) => {
        setValue(selecteddate);
        let parms = {
            selected_date: selecteddate.startDate
          };
         GetRelaxation(parms)
    }
  
    const GetRelaxation = async (params) => {
        setLoading(true);
        if(params){
            await http.post('search/clinic-day',params).then((resp)=>{
                setData(resp.data);
                setLoading(false);
            }).catch(error => {
                console.log(error.response.data.message)
                setData([]);
            });
        }else{
            setLoading(true);
            await http.post('search/clinic-day').then((resp)=>{
                setData(resp.data);
                setLoading(false);
            }).catch(error => {
                console.log(error.response.data.message)
                setData([]);
            });
        }
    }

    useEffect(() => {
        GetRelaxation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
         fetchData();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.location.search]);
      
      const hasParameters = window.location.search !== '';
      const fetchData = async () => {
        if (hasParameters === true) {
            setLoading(true);
          http.get('search/next-avilable-appointments')
          .then(response => {
             setData(response.data);
             setNextAvailable(true)
             setLoading(false);
          })
          .catch(error => {
             console.error(error);
          });
        }else{
            setData();
            setNextAvailable(false)
        }
    };

    return (
        
        <div className="white-box relative clinic-day-main">
            { isLoading && <Loading /> }
            <div className="flex mb-14">
                <h2 className="text-xl xl:text-3xl">Clinic Day View</h2>
                <div className="ml-auto flex justify-end">
                    <div className="mt-0 mr-4">
                        <Link to="/admin/clinic-day/next-available?next_available=next_available" className="bg-black rounded-md shadow-none inline-block text-white capitalize text-sm px-4 py-2 xl:px-8 xl:py-3">Next Available</Link>
                    </div>
                    <div className="form-input w-6/12">
                        <Datepicker 
                            placeholder={"Date"} 
                            useRange={false} 
                            displayFormat={"DD/MM/YYYY"}
                            asSingle={true} 
                            value={selected_date} 
                            onChange={handleValueChange} 
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4 xl:gap-8 mb-16">
                <div className="relaxation-table clinic-detail-box">
                    <h3 className="mb-4 xl:mb-6 text-base xl:text-3xl">Relaxation/Basic Treatment</h3>
                    <ClinicRelaxation ClinicRelaxation={Data} Nextavailable={nextavailable} />
                  </div>
                <div className="remedial-table clinic-detail-box">
                    <h3 className="mb-4 xl:mb-6 text-base xl:text-3xl">Remedial/Deep Tissue Treatment</h3>
                    <ClinicRemedial ClinicRemedial={Data} Nextavailable={nextavailable} />
                 </div>
                <div className="myotherapy-table clinic-detail-box">
                    <h3 className="mb-4 xl:mb-6 text-base xl:text-3xl">Myotherapy/Advanced Treatment</h3>
                    <ClinicMyotherapy ClinicMyotherapy={Data} Nextavailable={nextavailable}/>
                 </div>
            </div>
              <div className="flex flex-auto flex-wrap text-sm xl:text-base gap-4 xl:gap-8 mb-10">
                <div className="flex">
                    M = Male
                </div>
                <div className="flex">
                    F = Female
                </div>
                <div className="flex">
                    I = Others
                </div>
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faPencil} className="mr-1" /> = Edit
                </div>
             
                <div className="flex text-green text-green-600">
                    FP = Full Payment
                </div>
                 <div className="flex text-green text-green-600">
                    OC = Onsite Cash
                </div>
                <div className="flex text-green text-green-600">
                    OE = Onsite EFT/Credit Card
                </div>
                <div className="flex text-green text-green-600">
                    ST = Staff
                </div>
                <div className="flex text-green text-green-600">
                    FR = Free
                </div>
                <div className="flex text-green text-green-600">
                    AC = Applied Credit
                </div>
                <div className="flex text-red-600">
                    PR = Payment Required
                </div>
            {/*     <div className="flex text-red-600">
                    DP = Deposit Paid
                </div>*/}
                <div className="flex text-red-600">
                    AW = Awaiting Payment
                </div>
            </div>
         {/*   <div className="flex flex-auto flex-wrap text-sm xl:text-base gap-4 xl:gap-8 mb-10">
                <div className="flex">
                    M = Male
                </div>
                <div className="flex">
                    F = Female
                </div>
                <div className="flex">
                    I = Others
                </div>
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faPencil} className="mr-1" /> = Edit
                </div>
                <div className="flex text-red-600">
                    DP = Deposit Paid
                </div>
                <div className="flex text-green text-green-600">
                    FP = Full Payment
                </div>
                <div className="flex text-red-600">
                    PR = Payment Required
                </div>
            </div>*/}
        </div>
    );
} 
