import React from 'react';
import {
  Input
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../../helpers/AuthUser';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function AddNewService() {

  const {http} = AuthUser();
    const navigate = useNavigate();
    const { handleSubmit, setFieldValue, errors, touched, getFieldProps} = useFormik({
        initialValues: {
            name: '',
            price: '',
            promo_price:'',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required').min(2).max(50),
            price: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: values => {
            http.post('products',JSON.stringify(values, null, 2)).then((res)=>{
                toast.success('Successfully toasted!');
                navigate("/admin/gift/services");
            }).catch((err) => {
              console.log(err);
            });
        },
    });
	return(
        <div>
		   <div className="white-box">
              	<div className="heading mb-3">
                  	<h2>Add New User Profile</h2>
              	</div>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                	<div className="row">
                      	<div className="flex mb-8 w-full">
                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="title"
                                    name="name"
                                    variant="static"
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    {...getFieldProps('name')}
                                    className="border-black placeholder-shown:border-black"
                                  />
                              {(touched.name && errors.name) && <span className="error">{errors.name}</span>}
                            </div>

                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="price"
                                    name="price"
                                    variant="static"
                                    fullWidth
                                    id="price"
                                    label="Price"
                                    {...getFieldProps('price')}
                                    className="border-black placeholder-shown:border-black"
                                  />
                             {(touched.price && errors.price) && <span className="error">{errors.price}</span>}
                            </div>

                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="promo_price"
                                    name="promo_price"
                                    variant="static"
                                    fullWidth
                                    id="price"
                                    label="Price"
                                    {...getFieldProps('promo_price')}
                                    className="border-black placeholder-shown:border-black"
                                  />
                             {(touched.promo_price && errors.promo_price) && <span className="error">{errors.promo_price}</span>}
                            </div>

                            <div className="form-input w-3/12 px-4 mb-0">
                                <label className="">Image</label>
                                <input
                                    type="file"
                                    className="w-full px-4 py-2"
                                    label="Image"
                                    name="image"
                                    onChange={(event) => {
                                      const formData = new FormData();
                                      const reader = new FileReader();
                                      if (event.target.files[0]) {
                                           reader.readAsDataURL(event.target.files[0]);
                                         }
                                      reader.onload = (readerEvent) => {
                                      formData.append("image", readerEvent.target.result);
                                      console.log('formData',formData);
                                      setFieldValue('image', readerEvent.target.result);
                                     };

                                     }}
                                />
                              {(touched.image && errors.image) && <span className="error">{errors.image}</span>}
                            </div>
                    	</div>
                 	</div>
                 	<div className="bottom-button-group flex flex-wrap justify-end">
                      <div className="col">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Create New Service</button>
                      </div>
                    </div>
                </form>
                <Toaster />
           	</div>
        </div>
	)
}

export default AddNewService;