import { useFormik } from 'formik';
import FromHelper from '../helpers/FromHelper';
import * as Yup from 'yup';
import AuthUser from '../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { Typography, Checkbox } from "@material-tailwind/react";

import { Link, useNavigate } from 'react-router-dom';
import LogoImg from '../images/logo.png';

const LoginInputs=[
    {inputType:'email',inputId:'email',inputPlaceHolder:'Email Address', classes: 'w-full rounded-md', parentclasses: 'mb-4'},
    {inputType:'password',inputId:'password',inputPlaceHolder:'Password', classes: 'w-full rounded-md', parentclasses: 'password mb-4'}
];

function LoginForm(){
    
    const {http,setToken} = AuthUser();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().min(4).required('Required')
          }),
        onSubmit: values => {
            http.post('auth/login',JSON.stringify(values, null, 2)).then((res)=>{
                if (res.status === 200)
                    if (res.data.user.user_type_primary === 'admin'){
                      setToken(res.data.user,res.data.access_token);
                      toast.success("Success!");
                      navigate('/admin/dashboard');
                    } else if (res.data.user.user_type_primary === 'editor') {
                        setToken(res.data.user,res.data.access_token);
                        toast.success("Success!");
                        navigate('/admin/clinic-day');
                    } else {
                        toast.error("Error: Invalid Login Details.");
                    }
                }).catch(error => {
                if (error.response && error.response.data.message) {
                   toast.error(error.response.data.message);
                } else {
                  // Handle other types of errors, like network errors, etc.
                 toast.error('An error occurred.');
              }
          });
        },
      });

    return (
        <div className="flex flex-column align-center justify-center pt-20 pb-20 px-5">
            <div className="container">
                <main className="form-signin w-full md:w-5/12 m-auto bg-white px-8 py-6 md:p-14 rounded-md">
                    <form className="logo acfb-logo" onSubmit={formik.handleSubmit}>
                        <div className="w-12/12 flex justify-center mb-8">
                            <Link className="logo acfb-logo" hred="/">
                                <img src={LogoImg} />
                            </Link>
                        </div>

                        {LoginInputs.map((inputdata,index)=>{
                           return <FromHelper frominput={inputdata} formikData={formik} key={index}/>
                           
                        })}
                        <div className="checkbox mb-4">
                            <Checkbox className="p-0" label="Remember Me" />
                        </div>
                        <button className="bg-blue-900 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none text-white capitalize text-sm px-8 py-3" type="submit">Sign in</button>
                    </form>

                    <Toaster/>

                </main>
            </div>
        </div>
    );
}

export default LoginForm;