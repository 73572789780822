import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import FromHelper from '../helpers/FromHelper';
import * as Yup from 'yup';
import AuthUser from '../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { Typography, Checkbox, Input, Button } from "@material-tailwind/react";

import { useNavigate } from 'react-router-dom';

const LoginInputs=[
    {inputType:'email',inputId:'email',inputPlaceHolder:'Email address', classes: 'w-full rounded-md', parentclasses: 'mb-4'},
    {inputType:'password',inputId:'password',inputPlaceHolder:'Password', classes: 'w-full rounded-md', parentclasses: 'mb-4'}
];


function StudentLogin(){
    
    const {http,setToken} = AuthUser();
    const navigate = useNavigate();

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().min(4).required('Required')
          }),
        onSubmit: values => {
            http.post('auth/login',JSON.stringify(values, null, 2)).then((res)=>{
                if (res.status === 200)
                    if (res.data.user.user_type_primary === 'student'){
                      setToken(res.data.user,res.data.access_token);
                      toast.success("Success!");
                      navigate('/student/upcoming-bookings');
                  } else {
                    toast.error("Error: Invalid Login details.");
                  }
                })
                .catch(err => {
                 toast.error(err.message);
            });
        },
      });

    return (
        <div className="flex flex-column align-center justify-center pt-20 pb-20 px-4">
            <div className="container">
                <Typography variant="h2" className="text-center mb-10 font-normal">ACFB Student Login</Typography>
                <div className="grid grid-cols-1 md:flex gap-10">
                    <div className="w-full md:w-6/12">
                        <div className="form-signin bg-greycolor3 p-8 lg:p-14 rounded-md">
                            <form onSubmit={handleSubmit}>
                                
                                <Typography variant="h3" className="text-center mb-2 font-normal">Student Massage Clinic</Typography>
                                <Typography variant="paragraph" className="text-center mb-8 font-normal">Enter your availability and check your clinics.</Typography>

                                <div className="form-input mb-4">
                                    <Input
                                        type="email"
                                        variant="static"
                                        
                                        placeholder="Email Address"
                                        id="email"
                                        {...getFieldProps('email')}
                                        className="border-black placeholder-shown:border-black"
                                    />
                                    {(touched.email && errors.email) && <span className="error">{errors.email}</span>}
                                </div>

                                <div className="form-input mb-4">
                                    <Input
                                        type="password"
                                        variant="static"
                                        
                                        placeholder="Password"
                                        id="password"
                                        {...getFieldProps('password')}
                                        className="border-black placeholder-shown:border-black"
                                    />
                                    {(touched.password && errors.password) && <span className="error">{errors.password}</span>}
                                </div>

                                <div className="checkbox mb-4">
                                    {/*<input type="checkbox" value="remember-me" /> Remember me*/}
                                    <Checkbox className="p-0" label="Remember Me" />
                                </div>
                                <button className="bg-black hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3 w-full" type="submit">Login</button>
                            </form>

                            <Toaster/>

                        </div>  
                    </div>
                    <div className="w-full md:w-6/12">
                            
                        <div className="form-signin bg-greycolor3 p-8 lg:p-14 rounded-md">

                            <Typography variant="h3" className="text-center mb-2 font-normal">eLearning</Typography>
                            <Typography variant="paragraph" className="text-center mb-8 font-normal">Access your eLearning page.</Typography>

                            <img src="https://via.placeholder.com/355x160" style={{ height: '133px' }} className="w-full rounded-md mb-8" />

                            <Button className="bg-black hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3 w-full">Login here</Button>

                        </div>  

                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentLogin;