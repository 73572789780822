import React from 'react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Button
} from "@material-tailwind/react";

import ViewAll from '../Components/Gift/ViewAll';
import { Link } from 'react-router-dom';

import {getAdminUrl} from "../../helpers/Utils";
import TopNav from '../../Components/TopNav/TopNav';

const topNav = [
    {
        name: "View All",
        url: getAdminUrl('gift')
    },
    {
        name: "New Gift Card",
        url: getAdminUrl('gift/create-new'),
        button: [
            {
                name: "New Gift Card"
            }
        ]
    }
]

function GiftCards() {

    return (
      <>
            <TopNav data={topNav} />

            <ViewAll />
      </>
      
    )
}
export default GiftCards;
