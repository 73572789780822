import React from 'react';
import { Typography, Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
function NotFound() {
  return (
    <div className="pt-10 pb-6 md:pt-24 px-4 md:pb-10">
        <div className="max-w-screen-lg mx-auto text-center">
            <Typography variant="h2" className="mb-5">404 Not Found</Typography>
            <p className="mb-6">Sorry, the page you are looking for does not exist.</p>
            <Link to="/"><Button className="bg-white hover:bg-black active:bg-black focus:bg-black normal-case shadow-none hover:shadow-none px-4 py-4 text-sm font-normal border text-greycolor1 hover:text-white">Back to Home</Button></Link>
        </div>
    </div>
  );
}

export default NotFound;
