import React, {useState, useEffect} from "react";
import {
  Button,
  Input,
  Textarea,
  Typography
} from "@material-tailwind/react";
import { useFormik,FieldArray, Field } from 'formik';
import { useNavigate, useParams, Link } from 'react-router-dom';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast, { Toaster } from 'react-hot-toast';
import { Editor } from "@tinymce/tinymce-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircleXmark
} 
from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../Components/Loading';

function HomeEdit() {
    const {id} = useParams();
    const {http}=AuthUser();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const {handleSubmit,handleBlur, errors, touched, values,getFieldProps, setFieldValue,handleChange } = useFormik({
      initialValues: {
        title: '',
        description: '',
        acfb_terms: '',
        acfb_booking_data: [{ title: '', content: '' }],
      },
      onSubmit: (values) => {
        http.post('home/acfbcontent',JSON.stringify(values, null, 2)).then((res)=>{
          toast.success(res.data.message);
          window.location.reload();
        }).catch(err => {
          console.log('err',err);
       });
      },
    });
  
    const removeTextBlock = (index) => {
      const updatedTextBlocks = [...values.acfb_booking_data];
      updatedTextBlocks.splice(index, 1);
      setFieldValue('acfb_booking_data', updatedTextBlocks);
    };

    const handleEditorChange = (state) => {
      setData({});
      setFieldValue('acfb_terms',state);
    };
 
  useEffect(() => {
    async function fetchData() {
      try {
          setLoading(true);
          const response =  await http.get(`home/acfbcontent/5`);
          const data = await response.data.data;
          setData(data);
          const fields = ['id','acfb_booking_data','acfb_terms','description','title'];
          fields.forEach(field => setFieldValue(field, data[field], false));
          setLoading(false);
      } catch (error) {
        console.error(error);
      }
  }
  fetchData();
  }, []);

    return ( 
      <div className="white-box">
      { isLoading && <Loading /> }

        <div className="heading mb-3">
            <h2>Home</h2>
        </div>

        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap w-full">
                <div className="form-input mb-12 w-full">
                  <Input
                    autoComplete="title"
                    name="title"
                    variant="static"
                    fullWidth
                    id="title"
                    label="Title"
                    placeholder="Title"
                    className="border-black placeholder-shown:border-black"
                    value={data.title} 
                    onChange={e => {
                      setData({});
                      setFieldValue(e.target.name,e.target.value);
                    }}
                  />
                  </div>
                  <div className="form-textarea mb-12 w-full">
                    <Textarea
                      name={`description`}
                      variant="static" 
                      className="border-black placeholder-shown:border-black"
                      label="Content" 
                      value={data.description} 
                      onChange={e => {
                        setData({});
                        setFieldValue(e.target.name,e.target.value);
                      }}
                      placeholder="Content"
                    />
                  </div>

                   <hr className="mt-6 mb-8" style={{ borderColor: '#707070' }} />

                  <Typography variant="h4" className="mb-4">ACFB Terms</Typography>

                  <div className="form-textarea mb-12 w-full">
                    <Editor
                          apiKey="f3isu9gcf0py01o0f1syofk0dsznwftqecxu14ukvrfsanf1"
                          value={data.acfb_terms}
                          disabled={false}
                          initialValue={data.acfb_terms}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                            toolbar: 'undo redo | blocks fontfamily fontsize | code| bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                          }}
                          inline={false} 
                          onEditorChange={handleEditorChange}
                          outputFormat='html'
                      />
                  </div>

                   <hr className="block mt-6 mb-8" style={{ borderColor: '#707070' }} />

                 <Typography variant="h4" className="mb-4">ACFB Faq's</Typography>
                 <div className="repeater-area grid grid-cols-2 gap-4 w-full bg-greycolor4 px-3 py-5 pt-5">
                   {values.acfb_booking_data.map((textBlock, index) => (
                   <div className="basis-2/4 bg-white px-5 pt-12 pb-5 relative" key={index}>
                     <div className="form-input w-full mb-6">
                        <Input
                        variant="static"
                        fullwidth={"true"}
                        label="Title"
                        className="border-black placeholder-shown:border-black"
                        name={`acfb_booking_data.${index}.title`}
                        value={textBlock.title}
                        onChange={(e) => handleChange(e, index)}
                        placeholder="Title"
                        />
                         {(touched.title && errors.title) && <span className="error">{errors.title}</span>}
                      </div>
                     <div className="w-full form-textarea with-label">
                        <Textarea
                            name={`acfb_booking_data.${index}.content`}
                            variant="static" 
                            className="border-black placeholder-shown:border-black"
                            label="Content" 
                            value={textBlock.content}
                            onChange={(e) => handleChange(e, index)}
                            placeholder="Content"
                          />
                        {(touched.content && errors.content) && <span className="error">{errors.content}</span>}
                    </div>
                     {values.acfb_booking_data.length > 1 && (
                      <span className="absolute text-xl cursor-pointer text-darkgrey1 hover:text-black" style={{ top: '-6px', right: '-6px' }} onClick={() => removeTextBlock(index)}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </span>
                     )}
                  </div>
                  ))}
                   <div className="w-full"><Button type="button" className="btn-black" onClick={() => setFieldValue('acfb_booking_data', [...values.acfb_booking_data, ''])}> 
                     Add New
                  </Button></div>
                </div>
            </div>
            <Button type="submit" className="btn-black mt-4">Save</Button>
        </form>
    </div>
    )
}
export default HomeEdit;
