import React, { useState, useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthUser from '../../helpers/AuthUser';
import { 
    faBell,
} 
from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
function Topbar(){
    const role = JSON.parse(localStorage.getItem('user'));
    const {http} = AuthUser();
    const [isLoading, setloading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (searchTerm !== '') {
            setloading(true);
            http.get(`/users/search?searchTerm=${searchTerm}`)
                .then(response => {
                    setUsers(response.data);
                    setloading(false);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setUsers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []);
    
      useEffect(() => {
        updateBoxHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchTerm]);

      const updateBoxHeight = () => {
        const boxElement = document.getElementById('search-results-box');
        if (boxElement) {
          const numResults = searchTerm.length;
          console.log('numResults',numResults);
          const height = numResults > 0 ? `${numResults * 30}px` : '0';
          boxElement.style.height = height;
        }
      };

      const handleOutsideClick = event => {
         setSearchTerm('');
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setSelectedUser(null); // Clear the selected user
        }
      };
    
      const handleUserClick = user => {
          setSelectedUser(user);
          console.log(user, 'user')
      };
    return(
        <div className="flex">
            <div className={`w-6/12 dropdown relative ${selectedUser ? 'open' : 'close'}`} ref={dropdownRef}>
                <div className="search mb-4 search-bar-topheader form-input">
                    <Input icon={<i className="fas fa-magnifying-glass" />} 
                      variant="static" 
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      onFocus={() => setSelectedUser(null)}
                      placeholder="Search Users" />
                </div>
                {isLoading ? (
                <div className="w-6/12 dropdown absolute bg-white top-full p-4 rounded-lg shadow-lg h-56 overflow-x-hidden overflow-y-auto z-10">Loading...</div>
                ):( users.length > 0 &&
                <ul id="search-results-box" className="w-6/12 dropdown absolute bg-white top-full p-4 rounded-lg shadow-lg h-56 overflow-x-hidden overflow-y-auto z-10 search-results-box">
                    {users.map(user => (
                        <li className="py-1" key={user.id}>
                             <Link to={`admin/user/edit/${user.id}`} onClick={() => handleUserClick(user)}>{user.first_name} {user.last_name} ({user.email})</Link> 
                       </li>
                    ))}
                </ul>
            )}
            </div>
            {!role === 'student' &&
                <div className="w-6/12 top-right-menu flex justify-end">
                    <Popover placement="bottom-end">
              <PopoverHandler>
                 <div className="notification-icon"><FontAwesomeIcon icon={faBell} /><span></span></div>
              </PopoverHandler>
              <PopoverContent className="notification-content">
                <div className="flex flex-wrap">
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                    <div className="notification-link w-full">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - <Link to="" className="text-blue-900 underline">View Appointment</Link></div>
                </div>
              </PopoverContent>
            </Popover>
                    {/*<ul>
                        <li>
                            <Link><FontAwesomeIcon icon={faBell} /><span></span></Link>
                            <ul>
                                <li><Link to="">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - View Booking</Link></li>
                                <li><Link to="">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - View Booking</Link></li>
                                <li><Link to="">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - View Booking</Link></li>
                                <li><Link to="">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - View Booking</Link></li>
                                <li><Link to="">Myotheraphy Booking made 12/04/23 10:00AM - By Jane Doe - View Booking</Link></li>
                            </ul>
                        </li>
                    </ul>*/}
                </div>
            }
        </div>
    );
}
export default Topbar;