import { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../helpers/AuthUser';
const columns = [
    {
        name: 'Date Sent',
        selector: row => row.date,
    },
    {
        name: 'Time Sent',
        selector: row => row.time,
    },
    {
        name: 'Contact',
        selector: row => row.phone,
    },
    {
        name: 'Mobile',
        selector: row => row.mobile,
    },
    {
        name: 'User Type',
        selector: row =>  <div className="capitalize">{row.user_type}</div>,
    },
    {
        name: 'Status',
        selector: row => row.apistatus,
    },
    {
        name: 'Sms Type',
        selector: row => <div className="capitalize">{row.status}</div>,
    },
    {
        name: 'Message',
        selector: row => row.message,
    },
];

function ViewAll() {
    const {http}=AuthUser();
    const loading=false;
    const [sms, setSms] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 8;
    const getSmsList = () => {
     http.get(`/sms?page=${page}&per_page=${countPerPage}&delay=1`).then((resp)=>{
        setSms(resp.data.data);
     });  
    }
    useEffect(() => {
        getSmsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    console.log(sms, 'sms')

    return (
        <>
        <div className="white-box">
            <div className="appointments-view">
                <div className="top-head">
                    <div className="row">
                        <h2>SMS</h2>
                        <div className="status-bar">
                            <span>Showing 1-4 of 20</span>
                        </div>
                    </div>
                </div>
                <div className="view-all-appointment">
                    <DataTable
                            columns={columns}
                            data={sms.data}
                            className="table-title-style"
                            pagination
                            paginationServer
                            paginationPerPage={countPerPage}
                            paginationTotalRows={sms.total}
                            paginationRowsPerPageOptions={[2, 4, 25, 50]}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                              }}
                            onChangePage={page => setPage(page)}
                            progressPending={loading}
                        />
                </div>
            </div>
        </div>
        </>
    )
}
export default ViewAll;
