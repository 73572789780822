import { useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import AuthUser from '../helpers/AuthUser';
import {getAdminUrl} from '../helpers/Utils';
import MultilevelMenu from '../Components/Sidebar/MultilevelMenu';

import LogoImg from '../images/logo.png';
import LogoIcon from '../images/logo-icon.png';

const menuData = [
  {
    id: 1,
    name: "Dashboard",
    icon: <i class="fas fa-house"></i>,
    url: getAdminUrl('dashboard'),
    roles: ['admin'],
  },
  {
    id: 2,
    name: "Clinic Day View",
    icon: <i class="fas fa-clock"></i>,
    children: [
      {
        id: 2,
        name: "Clinic Day View",
        url: getAdminUrl('clinic-day'),
        roles: ['admin', 'editor']
      },
      {
        id: 2,
        name: "Next Available Clinic Day View",
        url: getAdminUrl('clinic-day/next-available?next_available=next_available'),
        roles: ['admin', 'editor']
      }
    ],
    roles: ['admin', 'editor']
  },
  {
    id: 3,
    name: "Appointments",
    icon: <i class="fas fa-pencil"></i>,
    children: [
      {
        id: 3,
        name: "View All",
        url: getAdminUrl('appointments'),
        roles: ['admin'],
      },
      {
        id: 3,
        name: "Book For Student",
        url: getAdminUrl('book-student'),
        roles: ['admin'],
      },
      {
        id: 3,
        name: "Relaxation/Basic Treatment",
        url: getAdminUrl('appointments/relaxation'),
        roles: ['admin'],
      },
      {
        id: 3,
        name: "Remedial/Deep Tissue Treatment",
        url: getAdminUrl('appointments/remedial'),
        roles: ['admin'],
      },
      {
        id: 3,
        name: "Myotherapy/Advanced Treatment",
        url: getAdminUrl('appointments/myotherapy'),
        roles: ['admin'],
      }
    ],
    roles: ['admin'],
  },
  {
    id: 4,
    name: "Allocations",
    icon: <i class="fas fa-calendar"></i>,
    children: [
      // {
      //   id: 4,
      //   name: "View Allocation Report",
      //   url: getAdminUrl('view-allocation-report')
      // },
      {
        id: 4,
        name: "View Allocations",
        url: getAdminUrl('view-allocation'),
        roles: ['admin'],
      },
      {
        id: 4,
        name: "View Custom Allocations",
        url: getAdminUrl('view-custom-allocation'),
        roles: ['admin'],
      }
    ],
    roles: ['admin'],
  },
  {
    id: 5,
    name: "SMS",
    icon: <i class="fas fa-paper-plane"></i>,
    children: [
      {
        id: 5,
        name: "View All SMS",
        url: getAdminUrl('sms'),
        roles: ['admin'],
      },
      {
        id: 5,
        name: "Send New SMS",
        url: getAdminUrl('sms/new-sms'),
        roles: ['admin'],
      },
      {
        id: 5,
        name: "Reminders",
        url: getAdminUrl('sms/reminders'),
        roles: ['admin'],
      }
    ],
    roles: ['admin'],
  },
  {
    id: 6,
    name: "Users",
    icon: <i class="fas fa-users"></i>,
    children: [
      {
          id: 6,
          name: "View All",
          url: getAdminUrl('users'),
          roles: ['admin'],
        },
        {
          id: 6,
          name: "Clients",
          url: getAdminUrl('users/clients'),
          roles: ['admin'],
        },
        {
          id: 6,
          name: "Students",
          url: getAdminUrl('users/students'),
          roles: ['admin'],
        },
        {
          id: 6,
          name: "Admin",
          url: getAdminUrl('users/admin'),
          roles: ['admin'],
      },
    ],
    roles: ['admin'],
  },
  {
    id: 7,
    name: "Booking Types",
    icon: <i class="fas fa-gears"></i>,
    children: [
      {
        id: 7,
        name: "View All",
        url: getAdminUrl('services'),
        roles: ['admin'],
      },
      {
        id: 7,
        name: "Add New",
        url: getAdminUrl('services/add-new-massage-type'),
        roles: ['admin'],
      },
      {
        id: 7,
        name: "Client Notes",
        url: getAdminUrl('services/special-needs'),
        roles: ['admin'],
      },
    ],
    roles: ['admin'],
  },
  // {
  //   name: "Reports",
  //   icon: <i class="fas fa-clipboard-list"></i>,
  //   children: [
  //     {
  //       name: "View All",
  //       url: ""
  //     },
  //     {
  //       name: "Clinic End of Day Settlement Report",
  //       url: ""
  //     },
  //     {
  //       name: "Payment Summary Report",
  //       url: ""
  //     },
  //     {
  //       name: "Appointment Error Report",
  //       url: ""
  //     },
  //     {
  //       name: "Admin User Audit Report",
  //       url: ""
  //     },
  //     {
  //       name: "Users Report",
  //       url: ""
  //     },
  //     {
  //       name: "SMS Failed Report",
  //       url: ""
  //     }
  //   ]
  // },
  {
    id: 8,
    name: "Gift Cards",
    icon: <i class="fas fa-credit-card"></i>,
    children: [
      {
        id: 8,
        name: "View All",
        url: getAdminUrl('gift'),
        roles: ['admin'],
      },
      {
        id: 8,
        name: "Create New Gift Card",
        url: getAdminUrl('gift/create-new'),
        roles: ['admin'],
      },
      {
        id: 8,
        name: "Options",
        url: getAdminUrl('gift/services'),
        roles: ['admin'],
      }
    ],
    roles: ['admin'],
  },
  {
    id: 9,
    name: "Front-End",
    icon: <i class="fas fa-credit-card"></i>,
    children: [
      {
        id: 9,
        name: "Home",
        url: getAdminUrl('edit/home'),
        roles: ['admin'],
      },
      {
        id: 9,
        name: "Make A Booking",
        url: getAdminUrl('edit/make-booking'),
        roles: ['admin'],
      }
    ],
    roles: ['admin'],
  }
]

function AdminSidebar() {
    const role = JSON.parse(localStorage.getItem('user'));
    const userRole = role.user_type_primary;
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const {getToken,token,logout} = AuthUser();
    const logoutUser=()=>{
      if(token !== undefined){
        logout();
      }
    }
    useEffect(() => {
          if(!getToken()){
            navigate('/');
          }
    });
    return (
        <Sidebar className="clinic-day" defaultCollapsed={pathname === '/admin/clinic-day' ? true : false} style={{ height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div className="head-area">
                  <Menu>
                    <MenuItem>
                      <Link className="logo" href="/">
                          <img src={LogoImg} className="logo-main" alt="Logo" />
                          <img src={LogoIcon} className="logo-icon w-10" alt="Logo Icon" />
                      </Link>
                    </MenuItem>
                  </Menu>
              </div>
            
              <MultilevelMenu data={menuData} userRole={userRole} />

              <Menu className="mt-auto mb-2">
              {pathname === '/admin/clinic-day' ? (
                  <SubMenu className="logout-outer" icon={<i className="fas fa-user"></i>}>
                    <MenuItem>
                      <Link to="/" onClick={logoutUser}>
                         <span>
                             Logout
                         </span>
                     </Link>
                   </MenuItem>
                  </SubMenu>
              ) : (
                <MenuItem icon={<i className="fas fa-user"></i>}>
                    <Link to="/" onClick={logoutUser}>
                       <span>
                           Logout
                       </span>
                   </Link>
                 </MenuItem>
              )}
              </Menu>
          </div>
        </Sidebar>
    );
}


export default AdminSidebar;
