import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AuthUser from '../../../../helpers/AuthUser';
import { Link } from 'react-router-dom';
import {getAdminUrl} from "../../../../helpers/Utils";
import TopNav from '../../../../Components/TopNav/TopNav';
import {
  Button,
  Input
} from "@material-tailwind/react";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="flex">
        <Input
            id="search"
            icon={<i className="left-0 fas fa-magnifying-glass" />}
            placeholder="Search users"
            variant="static"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <Button className="clear-search text-darkgrey1 bg-transparent shadow-none p-0" type="button" onClick={onClear}>x</Button>
    </div>
);

export default function Clients(){
    const {http,user}=AuthUser();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);
    var filteredItems;
    if (users.data && users.data.length > 0) {
        filteredItems = users.data.filter(item =>
            (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
        );
    }
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
              const response = await http.get('auth/clients');
              setUsers(response.data);
              setLoading(false);
            } catch (error) {
                fetchUsers({});
            }
        }
        fetchUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const topNav = [
        {
            name: "View All",
            url: getAdminUrl('users')
        },
        {
            name: "Clients",
            url: getAdminUrl('users/clients')
        },
        {
            name: "Students",
            url: getAdminUrl('users/students')
        },
        {
            name: "Admin",
            url: getAdminUrl('users/admin')
        },
        ...(user.user_type_primary === 'admin'
        ? [
        {
            name: "Add New User",
            url: getAdminUrl('add-user'),
            button: [
                {
                    name: "Add New User"
                }
            ]
        }
        ]
        : []),
    ]


    const columns = [
        {
            name: 'Full Name',
            selector: row => row.first_name + ' ' + row.last_name,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Mobile Number',
            selector: row => row.phone_number,
        },
        {
            name: 'Gender',
            selector: row => <div className="capitalize">{row.gender}</div>,
        },
        {
            name: 'Special Needs',
            selector: row => row.special_id,
        },
        {
            name: 'User Type',
            selector: row => <div className="capitalize">{row.user_type_primary}</div>,
        },
        {
            name: 'User Status',
            selector: row => row.user_status,
        },
        ...(user.user_type_primary === 'admin'
        ? [
        {
            name:"Action",
            cell: (row) => (
              <>
                <Link to={"/admin/user/edit/" + row.id } className='btn btn-primary table-btn-link'>View/Edit</Link>
              </>
            ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        ]
        : []),
    ];

    function convertArrayOfObjectsToCSV(array) {
        if (!Array.isArray(array) || array.length === 0) {
            console.error('Input array is either not an array or is empty');
            return '';
        }
        const fields = [
            'first_name',
            'last_name',
            'email',
            'phone_number',
            'user_type_primary',
            'user_type_secondary',
            'user_status',
            'special_id'
        ];
        const customHeadings = [
            'First Name',
            'Last Name',
            'Email Address',
            'Phone Number',
            'Primary Role',
            'Secondary Role',
            'User Status',
            'Client Notes'
        ];
        const columnDelimiter = ',';
        const lineDelimiter = '\n';  
        let result = '';
        result += customHeadings.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let rowData = [];
            fields.forEach(field => {
                rowData.push(item[field] || '');
            });
            result += rowData.join(columnDelimiter);
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv === '') return;
        const filename = 'Export ACFB Students Users.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    const Export = ({ onExport }) => <Button className="bg-black capitalize" onClick={onExport}>Export Report to CSV</Button>;

    return (
        <>
            <TopNav data={topNav} />

            <div className="white-box">
                <div className="appointments-view">
                    <div className="view-all-appointment">
                        <div className="top-head px-8">
                            <div className="row">
                                <h2>All Clients</h2>
                                <div className="status-bar">
                                    {/*<span>Showing 1-20 of 100</span>*/}
                                    <Export onExport={() => downloadCSV(filteredItems)} />
                                </div>
                            </div>
                        </div>

                        <DataTable
                            columns={columns}
                            className="table-title-style"
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} 
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRows
                            persistTableHead
                            paginationDefaultPage={1}
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50, 100, 150, 200, 250, 300, 350, 400, 450, 500]}
                            progressPending={loading}
                        />

                    </div>
                </div>
            </div>
        </>
    );
}