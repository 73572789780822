import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminStyle.css';
import StudentRoutes from '../Routes/Student/StudentRoutes';
import StudentSidebar from './StudentSidebar';
import MobileHeaderStudent from './MobileHeaderStudent';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faBars
}
from '@fortawesome/free-solid-svg-icons';
import { useProSidebar } from 'react-pro-sidebar';
import AuthUser from '../helpers/AuthUser';
import StudentTopNav from '../Components/StudentTopNav';
export default function StudentLayout(){
    const {Services, StudentRecords} = AuthUser();
    const [services, setServices] = useState([]);
    const [records, SetStudentRecords] = useState([]);
    const [openSidebar, setOpenSidebar] = useState(true);
    const { collapseSidebar, collapsed } = useProSidebar(true);
    useEffect(() => {
        GetServices()
        GetStudentRecords()
    }, [])
    const GetServices = () => {
        Services().then((resp)=>{
            setServices(resp.data.data);
        });  
    }
    const GetStudentRecords = () => {
        StudentRecords().then((resp)=>{
            SetStudentRecords(resp.data);
        });  
    }
    return (
        <div className="wrapper">
            <div className="lg:flex lg:h-full">
                <div className="hidden lg:inline-flex">
                    <div className="sidebar" style={{ position: "fixed" }}>
                        <StudentSidebar />
                    </div>
                </div>
                <div className="lg:hidden">
                    <MobileHeaderStudent />
                </div>
                <main className="block w-full">
                    <div className="lg:pl-64 content-area duration-300 pb-8">
                        <div className="page-header px-6 pt-8 relative">
                            <StudentTopNav services={services} records={records} />
                            <StudentRoutes/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}