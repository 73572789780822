import React, { useState, useEffect } from 'react';
import {
  Input
} from "@material-tailwind/react";

import { useFormik } from 'formik';
import AuthUser from '../../../../helpers/AuthUser';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function EditGiftService(props) {
  const {http} = AuthUser();
    const navigate = useNavigate();
    const {id} = useParams();
    const url = props.env;

    const [data, setData] = useState([]);
    const [ImageData, setImageData] = useState([]);
    useEffect(() => {
      async function fetchData() {
        try {
            const response =  await http.get(`products/${id}`);
            const data = await response.data.data;
            setData(data);
            setImageData(data);
            const fields = ['id','name','price','promo_price','image'];
            fields.forEach(field => setFieldValue(field, data[field], false));
        } catch (error) {
          console.error(error);
        }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const { handleSubmit, errors, touched, setFieldValue, handleBlur} = useFormik({
        initialValues: {
            name: '',
            price: '',
            promo_price:'',
        },
        validationSchema: Yup.object({
           // title: Yup.string().required('Required').min(2).max(50),
           // price: Yup.string().required('Required').min(2).max(50),
        }),
        onSubmit: async (values)=> {
          await http.put(`products/${id}`,JSON.stringify(values, null, 2)).then((result)=>{
            if (result.data['status'] === true) {
                toast.success("Gift Options Updated successfully!");
                navigate("/admin/gift/services");
               }
            }).catch(err => {
              toast.success("Something is wrong please try again!");
              console.log('err',err.response);
           });
        },
    });

    const DeleteBtn = (id) => {
        fetch(`${url}products/${id}`, {
            method: "DELETE"
        })
        .then((id) => {
            toast.success("Delete successful");
        });
    }

  return(
        <div>
       <div className="white-box">
          
                <div className="heading mb-3">
                    <h2>Edit Gift Card Option</h2>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                        <div className="flex mb-8 w-full">
                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="title"
                                    name="name"
                                    variant="static"
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.name}
                                    onChange={e => {
                                      setData({});
                                      setFieldValue(e.target.name,e.target.value);
                                     }}
                                    onBlur={handleBlur}
                                  />
                              {(touched.name && errors.name) && <span className="error">{errors.name}</span>}
                            </div>

                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="price"
                                    name="price"
                                    variant="static"
                                    fullWidth
                                    id="price"
                                    label="Price"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.price}
                                    onChange={e => {
                                      setData({});
                                      setFieldValue(e.target.name,e.target.value);
                                     }}
                                    onBlur={handleBlur}
                                  />
                                {(touched.price && errors.price) && <span className="error">{errors.price}</span>}
                            </div>
                            <div className="form-input w-3/12 px-4">
                                <Input
                                    autoComplete="price"
                                    name="promo_price"
                                    variant="static"
                                    fullWidth
                                    id="promo_price"
                                    label="Promo Price"
                                    className="border-black placeholder-shown:border-black"
                                    value={data.promo_price}
                                    onChange={e => {
                                      setData({});
                                      setFieldValue(e.target.name,e.target.value);
                                     }}
                                    onBlur={handleBlur}
                                  />
                                {(touched.promo_price && errors.promo_price) && <span className="error">{errors.promo_price}</span>}
                            </div>
                        </div>
                        <div className="flex mb-8 w-full">
                            <div className="form-input w-3/12 px-4">
                                 <img src={ImageData.image} className="w-full rounded-xl" alt="Promo" />
                            </div>
                            <div className="form-input w-3/12 px-4">
                                  <input
                                      type="file"
                                      className="w-full px-4 py-2"
                                      label="Image"
                                      name="image"
                                      onChange={(event) => {
                                        const formData = new FormData();
                                        const reader = new FileReader();
                                        if (event.target.files[0]) {
                                             reader.readAsDataURL(event.target.files[0]);
                                           }
                                        reader.onload = (readerEvent) => {
                                        formData.append("image", readerEvent.target.result);
                                        console.log('formData',formData);
                                        setFieldValue('image', readerEvent.target.result);
                                       };

                                       }}
                                  />
                            </div>
                        </div>
                  </div>

                  <div className="bottom-button-group flex gap-4 flex-wrap justify-end">
                        <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Update Booking Type</button>
                        <button onClick={(event) => { DeleteBtn(id)} } className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Delete</button>
                    </div>
                </form>
                <Toaster />
            </div>
        </div>
  )
}

export default EditGiftService;
