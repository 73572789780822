import ClinicRoutes from "../Routes/Clinic/ClinicRoutes";
import HeaderBlank from '../Components/HeaderBlank/HeaderBlank';
import FooterBlank from '../Components/FooterBlank/FooterBlank';

export default function ClinicLayout(){
    return (
        <div className="wrapper flex flex-col min-h-screen">
            <HeaderBlank/>
                <ClinicRoutes/>
            <FooterBlank/>
        </div>
    );
}